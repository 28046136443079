import React from "react";
import templateH from "../../images/templateH.png"
import templateV from "../../images/templateV.png"

import { iniziativaData } from "./nuova-iniziativa";
const NuovaIniziativa3= () => {
  const handleChange = (e) => {
    iniziativaData.template = e.target.value;
  }


   return (<>
      <div className="templates "> 
        <p className="subtab">Seleziona il template da associare all'iniziativa che stai creando.</p>
        <label className="template">
          <input onChange={handleChange} type="radio" name="test" value="0" checked />
          <img src= {templateH}  alt="templateH"/>
        </label>
      <label className="template">
        <input onChange={handleChange} type="radio" name="test" value="1" />
        <img src= {templateV}  alt="templateV"/>
      </label>
      
    </div>
</>

   );
};

export default NuovaIniziativa3;