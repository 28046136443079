import { React, useEffect, useState, useRef } from "react";
import swal from "sweetalert2";
import axios from "axios";
import SectionSubtitle from "../components/Subtitles";

const DatiAziendali = () => {
  const [aziende, setAziende] = useState([]);
  const [nazioni, setNazioni] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [itemsReferenti, setItemsReferenti] = useState([]);
  const referentiRef = useRef();

  const getData = () => {
    try {
      if (aziende.length === 0) {
        fetch(process.env.REACT_APP_BASE_URL + "aziende/1")
          .then((res) => res.json())
          .then((res) => {
            setAziende(res);

            fetch(process.env.REACT_APP_BASE_URL + "clienti")
              .then((clients) => clients.json())
              .then((clients) => {
                setItemsReferenti(clients);
                referentiRef.current.value = clients.find(
                  (i) => i.name === res[0].name
                ).id;
              });
          });
      }

      if (nazioni.length === 0) {
        fetch(process.env.REACT_APP_BASE_URL + "nazioni")
          .then((res) => res.json())
          .then((res) => {
            setNazioni(res);
          });
      }

      if (nazioni.length > 0 && aziende.length > 0) {
        setIsLoading(false);
      }
    } catch (error) {}
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.target;
    const data = {};
    for (let i = 0; i < form.elements.length; i++) {
      const elem = form.elements[i];
      if (elem.name === "referente_commerciale") {
        data[elem.name] = referentiRef.current.value;
      } else {
        data[elem.name] = elem.value;
      }
    }
    axios
      .post(process.env.REACT_APP_BASE_URL + "modifica-azienda", data)
      .then(function (response) {
        if (response.status === 200) {
          swal.fire("", "Operazione eseguita correttamente", "success");
        } else {
          swal.fire("", "Impossibile Salvare", "error");
        }
      });
  };
  useEffect(() => {
    getData();
  });

  if (isLoading) {
    return (
      <div className="App">
        <strong>Attendere...</strong>
      </div>
    );
  }
  return (
    <>

      <form onSubmit={handleSubmit} encType="multipart/form-data">
        {aziende.map((azienda) => (
          <div class="table-dati d_az">
            <input type="hidden" name="id" defaultValue={azienda.id} />
           
            <div>
              <div class="dat2">
                <label>Ragione sociale:</label>
                <span>
                  <input
                    name="ragione_sociale"
                    type="text"
                    defaultValue={azienda.ragione_sociale}
                    required
                  />
                </span>
              </div>
              <div class="dat3">
                <label>Partita IVA:</label>
                <span>
                  <input
                    name="partita_iva"
                    type="text"
                    defaultValue={azienda.partita_iva}
                    required
                  />
                </span>
              </div>
            </div>
            <div>
              <div>
                <label>Indirizzo:</label>
                <span>
                  <input
                    type="text"
                    name="indirizzo"
                    defaultValue={azienda.indirizzo}
                    required
                  />
                </span>
              </div>
              <div>
                <label>Citt&agrave;:</label>
                <span>
                  <input
                    type="text"
                    name="citta"
                    defaultValue={azienda.citta}
                    required
                  />
                </span>
              </div>
              <div>
                <label>Codice fiscale:</label>
                <span>
                  <input
                    type="text"
                    name="codice_fiscale"
                    defaultValue={azienda.codice_fiscale}
                    required
                  />
                </span>
              </div>
            </div>
            <div>
              <div>
                <label>Provincia:</label>
                <span>
                  <input
                    type="text"
                    name="provincia"
                    defaultValue={azienda.provincia}
                    required
                  />
                </span>
              </div>
              <div>
                <label>Nazione:</label>
                <span>
                  <select name="id_nazione" defaultValue={azienda.id_nazione}>
                    {nazioni.map((nazione) => (
                      <option value={nazione.id}>
                        {nazione.nome_convenzionale}
                      </option>
                    ))}
                  </select>
                </span>
              </div>
              <div>
                <label>Codice postale:</label>
                <span>
                  <input
                    type="text"
                    name="cap"
                    defaultValue={azienda.cap}
                    required
                  />
                </span>
              </div>
            </div>
            <div>
              <div>
                <label>E-mail:</label>
                <span>
                  <input
                    type="text"
                    name="email"
                    defaultValue={azienda.email}
                    required
                  />
                </span>
              </div>
              <div>
                <label>Telefono:</label>
                <span>
                  <input
                    type="text"
                    name="telefono"
                    defaultValue={azienda.telefono}
                  />
                </span>
              </div>
              <div>
                <label>Sito web:</label>
                <span>
                  <input
                    type="text"
                    name="sito_web"
                    defaultValue={azienda.sito_web}
                  />
                </span>
              </div>
            </div>
            <div>
              <div>
                <label>Referente commerciale:</label>
                <span>
                  <select
                    ref={referentiRef}
                    name="referente_commerciale"
                    defaultValue={0}
                  >
                    {itemsReferenti
                      .filter((r) => r.tipo === 2)
                      .map((referente) => (
                        <option value={referente.id}>
                          {referente.nome + " " + referente.cognome}
                        </option>
                      ))}
                  </select>
                </span>
              </div>
             
              {/* <div>
                <label>Numero licenze assegnate:</label>
                <span>
                  <input
                    type="text"
                    name="numero_licenze_assegnate"
                    defaultValue={!!azienda.licenze ? azienda.licenze.length : 0}
                    disabled
                  />
                </span>
              </div> */}
              <div style={{ backgroundColor: "white" }}></div>
            </div>
            <br />
            <div style={{ textAlign: "right", alignItems: 'right', margin: '10px' }}>
            
              <input
                type="submit"
                name="submit"
                value="Salva"
                className="btn btn-primary rounded-3 py-2"
              />
      
            </div>
          </div>
        ))}
      </form>
    </>
  );
}
export default DatiAziendali;