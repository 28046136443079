import React, {useEffect, useState} from "react";
//import { Button} from "react-bootstrap";
import swal from "sweetalert";
import {Card, CardContent, Typography, Button, TextField} from '@mui/material'
import { v4 as uuidv4 } from 'uuid';
export function   NuovaCartella(props) {

const [folderName, setFolderName] = useState()

useEffect(()=>{
  if (props.folder){
    setFolderName(props.folder.nome)
  }
  
},[props])

  const onHandleClose = () => {props.handleClose();}
  
  const handleSubmit = () =>{
    const data = {
      idcartella : !props.folder ? uuidv4() : props.folder.id,
      idbrand : props.id_brand,
      nome : folderName
    }

    fetch(process.env.REACT_APP_BASE_URL + 'insmod-cartella/', { method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(data)
    }).then(function(response) {
        if (response.status === 200) {
            swal({title : "", text : "Cartella creata", icon : "success"})
            props.refreshDatas()
            onHandleClose();
        }
        else {throw new Error(response.status);}
        }).catch(function(error) {
    });
  }

  return(

    <Card sx={{ minWidth: 275 }}>
      <CardContent>
      <Typography gutterBottom variant="h5" component="div">
          {props.folder ? 'Modifica cartella' : 'Nuova cartella'}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          
        </Typography>
        <TextField
          id="standard-required"
          label="Nome"
          placeholder="Nome"
          value={folderName}
          onChange={(event)=> { 
            setFolderName(event.target.value)
          }
          }
        />
        <div style={{marginTop: 10, display:'flex', justifyContent:'flex-end', gap:10}}>
        <Button variant="contained" style={{marginTop: 10}} onClick={()=>{handleSubmit()} }>{props.folder ? 'Modifica' : 'Crea'}</Button>
        <Button variant="contained" color='error' style={{marginTop: 10}} onClick={()=> onHandleClose() }>Annulla</Button>
        </div>
        

      </CardContent>
    </Card>
    
    
    )}

export default NuovaCartella

