import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import Swal from 'sweetalert2'
import {
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TextField,
    Paper,
    TablePagination,
    Checkbox,
    Typography,
    Tooltip,
    IconButton,
} from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'

const PositionCell = (props) => {
    const [value, setValue] = useState(props.value)

    useEffect(() => {
        setValue(props.value)
    }, [props.value])

    return (
        <TableCell component="th" scope="row">
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <TextField
                    id="outlined-basic"
                    label="Posizione"
                    variant="outlined"
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                />
                <Tooltip title="Conferma">
                    <IconButton
                        onClick={() => {
                            props.onConfirmChange(value)
                        }}
                    >
                        <CheckIcon />
                    </IconButton>
                </Tooltip>
            </div>
        </TableCell>
    )
}

function array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1
        while (k--) {
            arr.push(undefined)
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0])
    return arr // for testing
}

const SlotPalinsestoModal = (props) => {
    const eventData = props.eventClick.event

    const [contents, setContents] = useState([])
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(5)
    const [selectedItems, setSelectedItems] = useState([])

    useEffect(() => {
        fetchData()
    }, [])

    function onModalClose() {
        props.onCloseModal()
    }

    const fetchData = () => {
        fetch(`${process.env.REACT_APP_BASE_URL}brands/${eventData.extendedProps.idItem}/contenuti`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }).then(async (resAssoc) => {
            if (resAssoc.status == 200) {
                const fetchedContents = await resAssoc.json()

                const orderedList = JSON.parse(eventData.extendedProps.orderedList)

                if (orderedList.length === 0) {
                    setContents(
                        fetchedContents.map((item, index) => {
                            return {
                                ...item,
                                position: index + 1,
                            }
                        })
                    )
                } else {
                    const newList = fetchedContents.map((item) => {
                        const foundItem = orderedList.find((oi) => oi.id === item.id)
                        return {
                            ...item,
                            position: foundItem !== undefined ? foundItem.position : null,
                        }
                    })

                    let listWithoutPosition = newList.filter((oi) => oi.position === null)
                    let listWithPosition = newList
                        .filter((oi) => oi.position !== null)
                        .sort((a, b) => a.position - b.position)

                    listWithoutPosition = listWithoutPosition.map((oi, ni) => {
                        return {
                            ...oi,
                            position: ni + 1 + listWithPosition.length,
                        }
                    })

                    listWithPosition = listWithPosition.concat(listWithoutPosition)
                    listWithPosition = listWithPosition.sort((a, b) => a.position - b.position)

                    setContents(
                        listWithPosition.map((item, index) => {
                            return {
                                ...item,
                                position: index + 1,
                            }
                        })
                    )
                }

                setSelectedItems(
                    fetchedContents.filter(
                        (content) => !JSON.parse(eventData.extendedProps.elementsToHide).includes(content.id)
                    )
                )
            } else {
                Swal.fire({
                    title: '',
                    text: 'Errore nella configurazione del player. Verificare i dati inseriri e riprovare!',
                    icon: 'error',
                })
            }
        })
    }

    // handle pagination controllers
    const handleChangePage = (_, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const deleteEvent = async (e) => {
        e.preventDefault()
        onModalClose()

        eventData.remove()
    }

    const updateEvent = async (e) => {
        e.preventDefault()
        onModalClose()

        eventData.setExtendedProp(
            'elementsToHide',
            JSON.stringify(
                contents
                    .filter((content) => selectedItems.find((item) => item.id === content.id) === undefined)
                    .map((content) => content.id)
            )
        )
        eventData.setExtendedProp(
            'orderedList',
            JSON.stringify(
                contents.map((content) => {
                    return {
                        id: content.id,
                        position: content.position,
                    }
                })
            )
        )
    }

    const toggleItem = (item, status) => {
        if (status) {
            setSelectedItems([...selectedItems, item])
        } else {
            setSelectedItems(selectedItems.filter((i) => i.id !== item.id))
        }
    }

    const isItemSelected = (item) => selectedItems.find((i) => i.id === item.id) !== undefined

    let startTime = eventData.start // || Date.parse(eventClick.event.startString);
    let endTime = eventData.end
    let catalogo = []; 

    if (!eventData.end) {
        endTime = new Date(eventData.start)
        endTime.setHours(eventData.start.getHours() + 1)
    }

    let duration = (endTime.getHours() - startTime.getHours()) * 60 + (endTime.getMinutes() - startTime.getMinutes())

    const setPositionAndReorder = (id, position) => {
        let arrCopy = JSON.parse(JSON.stringify(contents))
        const findIndex = contents.findIndex((content) => content.id === id)
        arrCopy = array_move(arrCopy, findIndex, position - 1)

        setContents(
            arrCopy.map((item, index) => {
                return {
                    ...item,
                    position: index + 1,
                }
            })
        )
    }

    const renderContents = () => {
        const filteredContents = contents.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

        return filteredContents.length > 0 ? (
            <>
                <TableContainer component={Paper}>
                    <Table aria-label="table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Visibile</TableCell>
                                <TableCell>Posizione</TableCell>
                                <TableCell>Anteprima</TableCell>
                                <TableCell>Nome</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredContents.map((row) => {
                                var mediaIcon = <div />
                                switch (row.tipologia) {
                                    case 'Video':
                                        mediaIcon = (
                                            <video
                                                style={{ height: '120px' }}
                                                src={process.env.REACT_APP_BASE_URL_CONTENTS + row.file}
                                                controls
                                            />
                                        )
                                        break
                                    case 'Immagine':
                                        mediaIcon = (
                                            <img
                                                style={{ height: '120px' }}
                                                src={process.env.REACT_APP_BASE_URL_CONTENTS + row.file}
                                            />
                                        )
                                        break
                                    case 'Audio':
                                        mediaIcon = (
                                            <video
                                                style={{ height: '120px' }}
                                                src={process.env.REACT_APP_BASE_URL_CONTENTS + row.file}
                                                controls
                                            />
                                        )
                                        break
                                    default:
                                        mediaIcon = <img style={{ height: '120px' }} src={catalogo} />
                                        break
                                }
                                const labelId = `enhanced-table-checkbox-${row.id}`

                                return (
                                    <TableRow key={row.id} style={{ alignItems: 'center' }}>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                checked={isItemSelected(row)}
                                                inputProps={{
                                                    'aria-labelledby': labelId,
                                                }}
                                                onChange={(event) => toggleItem(row, event.target.checked)}
                                            />
                                        </TableCell>
                                        <PositionCell
                                            value={row.position}
                                            onConfirmChange={(newValue) => setPositionAndReorder(row.id, newValue)}
                                        />
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            style={{
                                                textAlign: 'center',
                                            }}
                                            width={'100'}
                                        >
                                            {mediaIcon}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <span>{row.nome}</span>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={contents.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </>
        ) : (
            <Typography variant="caption" display="block" gutterBottom>
                Nessun contenuto presente al momento
            </Typography>
        )
    }

    return (
        <div className="associa-player">
            <div>
                <Typography variant="h5" gutterBottom component="div">
                    Modifica lo slot di riproduzione
                </Typography>
                <div className="table-responsive">
                    <table className="table">
                        <tbody>
                            <tr>
                                <td>Brand</td>
                                <td>
                                    <strong>{eventData.title}</strong>
                                </td>
                            </tr>
                            <tr>
                                <td>Ora Avvio: </td>
                                <td>
                                    <strong>
                                        {startTime
                                            .getHours()
                                            .toLocaleString('it-IT', { minimumIntegerDigits: 2, useGrouping: false })}
                                        :
                                        {startTime.getMinutes().toLocaleString('it-IT', {
                                            minimumIntegerDigits: 2,
                                            useGrouping: false,
                                        })}
                                    </strong>
                                </td>
                            </tr>
                            <tr>
                                <td>Durata:</td>
                                <td>
                                    <strong>{duration} Minuti</strong>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <span>
                    Seleziona i contenuti da visualizzare ({selectedItems.length}/{contents.length} selezionati)
                </span>
                {renderContents()}
                <div style={{ width: '100%', textAlign: 'right' }}>
                    <Button
                        style={{
                            backgroundColor: 'rgba(0,0,0,0.4)',
                            border: '1px solid rgba(0,0,0,0.2)',
                        }}
                        className="btn btn-outline-primary rounded-1 btn-sm buttonmodal"
                        type="button"
                        onClick={onModalClose}
                    >
                        <Typography variant="button" display="block" gutterBottom style={{ marginBottom: 0 }}>
                            Annulla
                        </Typography>
                    </Button>
                    <Button
                        className="btn btn-outline-primary rounded-1 btn-sm buttonmodal"
                        type="button"
                        onClick={deleteEvent}
                    >
                        <Typography variant="button" display="block" gutterBottom style={{ marginBottom: 0 }}>
                            Elimina
                        </Typography>
                    </Button>
                    <Button
                        style={{
                            backgroundColor: 'rgba(0,0,0,0.4)',
                            border: '1px solid rgba(0,0,0,0.2)',
                        }}
                        className="btn btn-outline-primary rounded-1 btn-sm buttonmodal"
                        type="button"
                        onClick={updateEvent}
                    >
                        <Typography variant="button" display="block" gutterBottom style={{ marginBottom: 0 }}>
                            Conferma
                        </Typography>
                    </Button>
                </div>
            </div>
        </div>
    )
}
export default SlotPalinsestoModal
