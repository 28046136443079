import React, { useState } from 'react';
import { Button} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import vuoto from "../../images/vuoto.jpg";
import axios from 'axios';
function NuovoBrand() {
    const [file, setFile] = useState(''); // storing the uploaded file
    //const [progress, setProgess] = useState(0); // progess bar  
    const history = useHistory();
    setTimeout(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
      }, 100);
    const handleChange = (e) => {
        //setProgess(0);
        const file = e.target.files[0]; 
        setFile(file); 
        var imgLogo = document.getElementById('logoImg');
        imgLogo.src =  URL.createObjectURL(file);
    }
    const uploadFile = () => {
    const formData = new FormData();
    formData.append('file', file); // appending file
    axios.post(process.env.REACT_APP_BASE_URL+'upload-file', formData, {
        onUploadProgress: (ProgressEvent) => {
           /**let progressVal = Math.round(
            ProgressEvent.loaded / ProgressEvent.total * 100) + '%';
            setProgess(progress);**/
        }
    }).then(res => {
        if (res.status === 200)
        {
            history.push('impostazioni');
        }
        else
        {
            console.log(res);
        }
    }).catch(err => console.log(err))}

    const handleSubmit = event => {
    event.preventDefault();
    const form = event.target;
    const file = event.target.brandlogo.files[0];
    const data = {};
    for (let i=0; i < form.elements.length; i++) {
        const elem = form.elements[i];
        data[elem.name] = elem.value;
    }
    data['logo'] = file.name;
    axios.post(process.env.REACT_APP_BASE_URL+'nuovo-brand', data)
    .then(function(response) {
        if (response.status === 200) {
            //let responseText = JSON.stringify(response.text());
        uploadFile();
        }
        else {
            console.log(response);
            throw new Error(response.status);}
        }).catch(function(error)
        {

        });
    }
    return (
     <>
        <div className="card">
                   
                    <p className="pform">Nuovo brand</p>
                    
            <div className="card-bodyx">
                <form controlId="formBrand" onSubmit={handleSubmit} encType='multipart/form-data'>
                        <div className="form-row">
                            <label  className="form-label">NOME</label>
                            <input className="form-input" type="text" name="brandnome" />
                        </div>
                        <div className="form-row">
                            <label  className="form-label">LOGO</label>
                            <input className="form-input" type="file" name="brandlogo" onChange={handleChange}/>
                        </div>   
                        <div className="form-row">
                            <div className="form-image">
                            <img id="logoImg" className="brand-image" src={vuoto} alt ="" />
                            </div>
                            <div className="form-buttons">
                           <Button className="btn btn-outline-primary rounded-1 btn-sm" type="submit">Invia</Button>
                           <Button className="btn btn-outline-primary rounded-1 btn-sm" type="button" onClick={() => {history.push('impostazioni');}}>Annulla </Button>
                            </div>
                        </div>
                </form>
            </div>
        </div>
     </>
        )
    }
    export default NuovoBrand;
