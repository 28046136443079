export function HelpMessages(props) {
return (
    <>
        <div style={{textAlign:"center", width: "100%"}}>
            <h2>
                Guida ai modelli dei messaggi
            </h2>
        </div>
        <div>
        <p>
            I modelli dei messaggi hanno due funzioni. La prima è l'impostazione di un testo standard per l'invio delle email automatiche per ciascun utente
            amministratore e operatore, la seconda è poter poter automatizzare l'invio di email massive personalizzate per ciascun destinatario.
        </p>
        <p>
            Per personalizzare le email massive per ciascun destinatario, occorre usare una particolare sintassi nel testo del modello. I comandi sono pochi 
            e facilmente memorizzabili. La sintassi di base è ((%parola chiave%)). Questa sintassi può all'occorrenza anche essere utilizzata nel testo scritto
            nel messaggio. Sarà poi il server a processare i comandi, semplificando l'operazione.
        </p>
        <p>
            <h3>I comandi disponibili:</h3>
        </p>
        <ul>
            <li>
                <b>● ((%email%))</b> verrà inserito l'indirizzo email del destinatario nel testo del messaggio.
            </li>
            <li>
                <b>● ((%password%))</b> verrà generata una nuova password per l'utente a cui è destinata l'email ma solo se presente anche ((%email%))
            </li>
            <li>
                <b>● ((%token%))</b> genera un token temporaneo per impostare la password dell'utente destinatario dell'email, richiede anche ((%email%)). 
            </li>
            <li>
                <b>● ((%nominativo%))</b> inserisce il nome e cognome del destinatario nel testo dell'email.
            </li>
                <br />
                <p><h2>Due esempi:</h2><br /> 
                <b>Primo esempio</b><br />
                    Buongiorno, ((%nominativo%)) la invitiamo a provare la nostra piattaforma. Per accedere dovrà usare le credenziali di seguito indicate:<br />
                    utente: ((%email%))<br />
                    password: ((%email%))
                </p>
                <p>
                <b>Secondo esempio</b><br />
                    Buongiorno, ((%nominativo%)) la invitiamo a provare la nostra piattaforma.:<br />
                    All'indirizzo ((%token%)) potrà generare la sua password usando l'email ((%email%))<br />
                    <br/>
                    <br/>
                    <b>Nota bene:</b> Il token temporaneo è lo stesso che serve per resettare una password smarrita, con la differenza che mentre con quello di 
                    richiesta password della schermata di login la sua durata è di 2 ore, quello generato tramite ((%token%)) dura 24 ore.
                </p>
            <br />
            <li>
                <h2>Comando per la condivisione dei contenuti (un contenuto per ciascuna email)</h2><br/>
                <b>((%contenuto%))</b> serve per inserire il collegamento diretto a uno specifico contenuto condiviso.<br />
                Infatti se si vuole presentare un video o un catalogo o un qualsiasi altro elemento della sezione contenuti. Ovviamente, qualora l'utente Non
                abbia eseguito l'accesso, prima della visualizzazione del contenuto, verrà richiesto l'accesso con nome utente e password.<br/><br/>
                <b>Non è possibile visualizzare contenuti se non si è prima eseguito l'accesso. Quando si clicca sul link generato da ((%contenuto%)) e viene 
                   richiestto l'accesso, una volta eseguito si verrà reindirizzati sul contenuto indicato nell'email. 
                </b>
            </li>            
        </ul>
        </div>
    </>
);
}
export default HelpMessages;