import React from 'react'
import { useEffect, useState, useRef } from 'react'
import { Button } from 'react-bootstrap'

const InserimentoContenuti1 = (props) => {
    const [categories, setCategories] = useState([])
    const [selectedCategories, setSelectedCategories] = useState(props.selectedCategories)
    const chkRef = useRef()

    useEffect(() => props.changeSelectedCategories(selectedCategories), [selectedCategories])

    useEffect(() => {
        fetchCategorie()
    }, [])

    const onScrollTop = () => {
        props.onScrollTop()
    }

    const fetchCategorie = () => {
        fetch(process.env.REACT_APP_BASE_URL + 'categorie')
            .then((res) => res.json())
            .then((res) => {
                if (res.length === 0) {
                    setCategories([{ id: -1, nome: 'Nessuna categoria presente!' }])
                    return
                }
                setCategories(res)
            })
    }
    const getChecked = (itemId) => {
        return selectedCategories.find((c) => c.id === itemId) !== undefined
    }
    const toggleItem = (item, e) => {
        if (e.checked) {
            setSelectedCategories([...selectedCategories, item])
        } else {
            setSelectedCategories(selectedCategories.filter((category) => category.id !== item.id))
        }
    }

	console.log({selectedCategories})

    return (
        <>
            <div className="inserimento-contenuti">
                <b>Cartella:</b>
                <label style={{ marginLeft: 10 }}>{props.folder ? props.folder.nome : 'Cartella principale'}</label>
                &nbsp;&nbsp;
                <b style={{ marginLeft: 10 }}>Brand:</b>
                <label style={{ marginLeft: 10 }}>{props.brand ? props.brand.nome : ''}</label>
                <div className="nuovo-palinsesto">
                    <ul>
                        {categories.map((item) => (
                            <li>
                                <input
                                    type="checkbox"
                                    hidden={true}
                                    id={'categoria' + item.id}
                                    ref={chkRef}
                                    defaultChecked={getChecked(item.id)}
                                    onChange={(e) => {
                                        toggleItem(item, e.currentTarget)
                                    }}
                                />
                                <label for={'categoria' + item.id}>
                                    {item.nome}
                                    <br />
                                    <img className="" src={process.env.REACT_APP_BASE_URL_CONTENTS + item.immagine} alt="" />
                                    <div
                                    style={{
                                        backgroundColor: item.colore,
                                        borderRadius: '20px 20px 0 0',
                                        width: '100%',
                                        height: '16px',
                                        marginLeft: 'auto',
                                        marginRight: 'auto',
                                        paddingTop: '0',
                                        paddingBottom: '0',
                                        marginBottom: '-6px'
                                    }}
                                    ></div>
                                </label>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div style={{ textAlign: 'right' }}>
                <Button
                    className="btn btn-success btn-floating btn-lg"
                    style={{ padding: '20px 30px' }}
                    onClick={() => {
                        onScrollTop()
                    }}
                >
                    <i class="fas fa-arrow-up"></i>
                </Button>
            </div>
        </>
    )
}
export default InserimentoContenuti1
