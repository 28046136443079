import React, { Component } from "react";
import { Col, Row, Card } from "react-bootstrap";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";

import timeGridPlugin from "@fullcalendar/timegrid";
import momentPlugin from '@fullcalendar/moment';
import itLocale from '@fullcalendar/core/locales/it';
import { v4 as uuidv4 } from 'uuid';
//&import { DateTime } from "luxon";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import Alert from "sweetalert2";
import { iniziativaData } from "./nuova-iniziativa";
//const StartTest = moment ({ hours: "06", minutes: "00"});
//const EndTest = moment ({ hours: "09", minutes: "00"});

class NuovaIniziativa2 extends Component {
  
   /**state = {
      calendarEvents: [
         {
            title: "Atlanta Monster",
            startTime: "06:00",
            duration: "03:00",
            daysOfWeek: [ 1, 2, 3 ], 
            allDay: false,
            id: 1,
         },
         {
            title: "My Favorite Murder",
            startTime: "08:00",
            duration: '02:00',
            daysOfWeek: [ 0, 4 ], 
            allDay: false,
            id: 1,
         },
         { 
            id: 3, 
            title: 'Storm in the city', 
            startTime: '07:00', 
            duration: '02:00',
            daysOfWeek: [5,6],
            allDay: false,
         },
      ],
   // events: itemsAssociati.map((items) => items.title = items.nome),
   };
   /**
    * adding dragable properties to external events through javascript
    */

 
    componentDidMount() {
      let draggableEl = document.getElementById("external-events");
      new Draggable(draggableEl, {
         itemSelector: ".fc-event",
         eventData: function (eventEl) {
            let title = eventEl.getAttribute("title");
            let id = eventEl.getAttribute("data");
            return {
               title: title,
               id: uuidv4(),
               duration: "60",
               durationEditable: true,
               startEditable: true,
               extendedProps: {
                  Iniziativa: iniziativaData.uuid,
                  idItem : id,
                  idContent : 0,
                  isNew : true,
                  startTime : "00:00",
                  endTime : "00:00",
                  duration : "60",
                  dayOfWeek : "0",
                  elementsToHide: '[]',
                  orderedList: '[]'
               }
            };
         },
      });
   }
   eventReceive = (e) => {
      this.updateEvent(e);
   }
   eventAdd = (e) => {
      this.updateEvent(e);
   }
   addEvent = (e) => {
      iniziativaData.eventi.push(e.event);
   }
   updateEvent = (e) => {
      let duration = ((e.event.end.getHours() * 60) + e.event.end.getMinutes()) - ((e.event.start.getHours() * 60) + e.event.start.getMinutes());
      if (duration < 0)
      {
         duration = 1440 + duration;
      }
      let changedEvent = {
            title: e.event.title,
            id: e.event.id,
            durationEditable: true,
            startEditable: true,
            start: e.event.start,
            end: e.event.end,
            extendedProps: {
               iniziativa: iniziativaData.uuid,
               idItem : e.event.extendedProps.idItem,
               idContent : 0,
               isNew : e.event.extendedProps.isNew,
               startTime : (e.event.start.getHours() < 10 ? "0" + e.event.start.getHours() : e.event.start.getHours()) + ":" + (e.event.start.getMinutes() < 10 ? "0" + e.event.start.getMinutes() : e.event.start.getMinutes()),
               endTime : (e.event.end.getHours() < 10 ? "0" + e.event.end.getHours() : e.event.end.getHours()) + ":" + (e.event.end.getMinutes() < 10 ? "0" + e.event.end.getMinutes() : e.event.end.getMinutes()),
               duration : duration,
               dayOfWeek : e.event.start.getDay(),
               elementsToHide: e.event.extendedProps.elementsToHide,
               orderedList: e.event.extendedProps.orderedList
            }
         };
      iniziativaData.eventi.push(changedEvent);
   }
  
   eventRemove = (e) => 
   {
      iniziativaData.eventi.splice(iniziativaData.eventi.indexOf(iniziativaData.eventi.find((ee) => { return e.event.id === ee.id;  })),1);
   }
   eventChange = (e) => 
   {
      let evento = iniziativaData.eventi.find((ee) => { return e.oldEvent.id === ee.id;  });
      if (evento)
      {
         iniziativaData.eventi.splice(iniziativaData.eventi.indexOf(evento));
         this.updateEvent(e);
      }
   }
   eventDrop = (e) => 
   {
      /**let evento = iniziativaData.eventi.find((ee) => { return e.oldEvent.id === ee.id;  });
      if (evento)
      {
         iniziativaData.eventi.splice(iniziativaData.eventi.indexOf(evento));
         this.updateEvent(e);
      }**/
   }
   eventSet = (e) => 
   {
      console.log(e);
   }
   /**
    * when we click on event we are displaying event details
    */
   eventClick = (eventClick) => {
      let startTime = eventClick.event.start; // || Date.parse(eventClick.event.startString);
      let endTime = eventClick.event.end;
      if (!eventClick.event.end)
      {
         endTime = new Date(eventClick.event.start);
         endTime.setHours(eventClick.event.start.getHours() + 1);
      }
      let duration = (endTime.getHours() - startTime.getHours()) * 60 + (endTime.getMinutes() - startTime.getMinutes());
      Alert.fire({
         title: eventClick.event.title,
         html:
            `<div className="table-responsive">
               <table className="table">
                  <tbody>
                     <tr>
                        <td>Brand</td>
                        <td><strong>` + eventClick.event.title + `</strong></td>
                     </tr>
                     <tr>
                        <td>Ora Avvio: </td>
                        <td><strong>` + startTime.getHours().toLocaleString('it-IT', { minimumIntegerDigits: 2, useGrouping: false }) + ':' + startTime.getMinutes().toLocaleString('it-IT', { minimumIntegerDigits: 2, useGrouping: false }) + `</strong></td>
                     </tr>
                     <tr>
                         <td>Durata:</td>
                         <td><strong>` + duration + ` Minuti </strong></td>
                     </tr>
                  </tbody>
               </table>
             
            </div>`,
         showCancelButton: true,
         confirmButtonColor: "#d33",
         cancelButtonColor: "#3085d6",
         confirmButtonText: "Elimina",
         cancelButtonText: "Annulla",
      }).then((result) => {
         if (result.value) {
            eventClick.event.remove(); // It will remove event from the calendar
            Alert.fire("Eliminato!", "L'elemento selezionato è stato rimosso.", "success");
         }
      });
   };

   render() {
      return (
         <div className="animated fadeIn">
            <Row>
               <Col lg={2}><p className="add-brand-list">Brand disponibili:</p>
                  <div id="external-events">
                  {iniziativaData.items.map((item) => (
                        <div
                           className="fc-event btn-block btnEvent"
                           title={item.nome}
                           data={item.id}
                           key={item.id}
                        >
                        <img src={process.env.REACT_APP_BASE_URL_CONTENTS + item.logo} className="brandEvent" alt={item.nome} />
                           
                        </div>
                     ))}
                  </div>
               </Col>
               <Col lg={10}>
                  <Card>
                     <Card.Body>
                        <div className="demo-app-calendar" id="mycalendartest">
                           <FullCalendar
                          
                         // scrollTime=''
                          //scrollToTime='06:00:00'
                        slotMinTime= '06:00:00'
                        slotMaxTime='24:00:00'
                          // slotDuration="01:00:00"
                          height='960px'
                        view={{
                           schedule: {
                              type: 'week',
                                    
                           }
                        }}
                        plugins={[timeGridPlugin, dayGridPlugin, interactionPlugin, momentPlugin]}
                        headerToolbar={{
                           left: "title",
                          
                           right: "prev,next,today",
                           center: "dayGridMonth,timeGridWeek,timeGridDay"
                        }}
                        eventColor='gray'
                        defaultEventMinutes="30"
                        locale={itLocale}
                        initialView='timeGridWeek'
                        slotLabelFormat={{
                           hour: '2-digit',
                           minute: '2-digit',
                           omitZeroMinute: false,
                           meridiem: '',
                           hour12:false
                        }}
                        editable={true}
                        selectable={false}
                        selectMirror={true}
                        eventOverlap={false}
                        forceEventDuration={true} 
                        //dayMaxEvents={true}
                        // dayMaxEventRow ={1} 
                        displayEventTime ={false}
                        droppable={true}
                        weekends={true}
                        allDaySlot={false}
                        firstDay={ (new Date()).getDay()}
                        // contentHeight=
                        
                     //  dayHeaderFormat={{
                     //     weekday: 'short',
                     //  }}
                        //initialEvents={this.state} // alternatively, use the `events` setting to fetch from a feed
                        select={this.handleDateSelect}
                        ref={this.calendarComponentRef}
                        // weekends={this.state.calendarWeekends}
                        events={iniziativaData.eventi}
                        eventReceive={this.eventReceive}
                        eventClick={this.eventClick}
                        eventAdd={this.eventAdd}
                        eventDrop={this.eventDrop}
                        eventChange={this.eventChange}
                        eventRemove={this.eventRemove}
                        //eventsSet={this.eventsSet}
                        // eventContent={renderEventContent} // custom render function
                        />
                        </div>
                     </Card.Body>
                  </Card>
               </Col>
            </Row>
         </div>
      );
   }
}

export default NuovaIniziativa2;