import React, { Fragment } from "react";
import { useEffect, useState, useRef } from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import Swal from "sweetalert2";
import getQueryVariable from "../functions/getQueryVariable";
import UtentiAssociaBrands from "./utenti-associa-brands";
import { Editor } from "@tinymce/tinymce-react";
import axios from "axios";
import { TextField, Typography } from "@mui/material";
import { THEME_RED } from "../../CONST";
import Divider from "../components/Divider";

export function NuovaAnagrafica() {
  const history = useHistory();
  const idCasaMadre = getQueryVariable("casa-madre");
  const [currentUser] = useState(
    JSON.parse(localStorage.getItem("userDetails", "undefined"))
  );
  const editorRef = useRef(null);

  const [itemsCategorie, setItemsCategorie] = useState([]);
  const [itemsZone, setItemsZone] = useState([]);
  const [itemsReferenti, setItemsReferenti] = useState([]);
  const [itemsTipiPunto, setItemsTipiPunto] = useState([]);
  const [utenteBrands, setUtenteBrands] = useState([]);
  const [modelsMessages] = useState([]);
  const [messageData, setMessageData] = useState({});
  const [selectedUser] = useState({ id: 0 });

  const defaultAnagraphic = {
    nome: "",
    cognome: "",
    email: "",
    id_azienda: 1,
    nome_azienda: "",
    email_azienda: "",
    id_zona: 0,
    id_tipo: 0,
    id_categoria: 0,
    telefono: "",
    codice_fiscale: "",
    p_iva: "",
    provincia: "",
    citta: "",
    indirizzo: "",
    cap: "",
  };

  const isValidEmail = (email) => {
    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(email.toLowerCase());
  };

  const getModels = () => {
    try {
      modelsMessages.push({
        id: 0,
        messaggio: "",
        categoria: 0,
        id_utente: currentUser.userid,
      });
      modelsMessages.push({
        id: 0,
        messaggio: "",
        categoria: 1,
        id_utente: currentUser.userid,
      });
      modelsMessages.push({
        id: 0,
        messaggio: "",
        categoria: 2,
        id_utente: currentUser.userid,
      });
      modelsMessages.push({
        id: 0,
        messaggio: "",
        categoria: 3,
        id_utente: currentUser.userid,
      });

      var data = { id_user: currentUser.userid, token: currentUser.idToken };
      axios
        .post(process.env.REACT_APP_BASE_URL + "get-models", data)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.length > 0) {
              var data = res.data;
              data.forEach((i) => {
                modelsMessages.forEach((m) => {
                  if (m.categoria === i.categoria) {
                    m.messaggio = i.messaggio;
                    m.id = i.id;
                  }
                });
              });
              console.log(
                modelsMessages[2].messaggio + modelsMessages[0].messaggio
              );
              setMessageData({
                to: "",
                toaddress: "",
                body: modelsMessages[2].messaggio + modelsMessages[0].messaggio,
                subject: "Invito a provare la piattaforma",
                isresponse: false,
              });
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
  };

  console.log({ messageData });

  const handleSubmit = async (event) => {
    const form = event.target;
    event.preventDefault();
    const elements = form.elements;
    const data = {};
    const userData = {};
    const emailData = {};
    const itemsUtenteBrands = [];
    let newUserName = "";
    let newUserEmail = "";

    let isFormValid = true;

for (let i = 0; i < elements.length; i++) {
  const elem = elements[i];
  if (!["nome", "cognome", "email"].includes(elem.name)) {
    if (elem.name === "nome_azienda") {
      data["nome"] = elem.value;
    }
    if (elem.name === "email_azienda") {
      data["email"] = elem.value;
      if (!isValidEmail(data["email"])) {
        Swal.fire({
          title: "Errore!",
          text: "Email punto vendita non valida",
          icon: "error",
        });
        isFormValid = false;
        break;
      }
    } else {
      data[elem.name] = elem.value;
    }
  } else {
    if (elem.name === "email") {
      newUserEmail = elem.value;
      if (!isValidEmail(newUserEmail)) {
        Swal.fire({
          title: "Errore!",
          text: "Email utente non valida",
          icon: "error",
        });
        isFormValid = false;
        break;
      }
    }
    userData[elem.name] = elem.value;
  }
}

if (!isFormValid) {
  return;
}

    newUserName = userData.nome + " " + userData.cognome;

    if (idCasaMadre !== false) {
      data.id_casa_madre = idCasaMadre;
    }

    utenteBrands.forEach((i) => {
      let b = { id_utente: 0, id_brand: i.id };
      itemsUtenteBrands.push(b);
    });
    userData["utenteBrands"] = itemsUtenteBrands;

    let createdUser = await axios.post(
      process.env.REACT_APP_BASE_URL + "nuovo-utente/",
      {
        ...userData,
        tipo: 3,
      }
    );

    if (createdUser.status !== 200) {
      Swal.fire({
        title: "Errore!",
        text: "Errore durante la creazione dell'utente.",
        ef,
      });
      return;
    }

    createdUser = createdUser.data.rows[0];
    data.id_referente = createdUser.id;

    // try {
    //     const userDataResponse =

    //     if (userDataResponse.status === 200 && userDataResponse.data && userDataResponse.data.rows && userDataResponse.data.rows.length > 0) {
    //       const createdUser = userDataResponse.data.rows[0];

    //       data.id_referente = createdUser.id;

    //     } else {
    //       throw new Error('User creation failed, no data returned');
    //     }d
    //   } catch (error) {
    //     Swal.fire({
    //       title: 'Errore!',
    //       text: 'Errore durante la creazione dell\'utente.',
    //       icon: 'error',
    //     });
    // }
    let currentUserData = currentUser;

    const sendEmailsBody = {
      emails: [
        {
          email: newUserEmail,
          nominativo: newUserName,
          data: {
            body: messageData.body,
            sendername: currentUserData.nome + " " + currentUserData.cognome,
            sendermail: currentUserData.email,
            sender: currentUserData.email,
            priority: 1,
            subject: "Invito a provare la piattaforma",
          },
        },
      ],
    };
    console.log({ sendEmailsBody });
    await axios
      .post(process.env.REACT_APP_BASE_URL + "send-email", sendEmailsBody)
      .then(function (response) {
        if (response.status !== 200) {
          throw new Error(response.status);
        }
      })
      .catch(function (error) {
        Swal.fire({
          title: "",
          text: "Errore durante l'invio dell'invito!",
          icon: "error",
        });
      });

    data.id_referente = createdUser.id;
    await fetch(process.env.REACT_APP_BASE_URL + "nuovo-punto-vendita", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then(function (response) {
        if (response.status === 200) {
          history.push("impostazioni?tab=anagrafiche");
          return response.data;
        } else {
          throw new Error(response.status);
        }
      })
      .catch(function (error) {
        Swal("", "Impossibile Salvare", "error");
      });
  };
  // const [usersForRender, setUsersForRender] = useState([]);
  const getData = () => {
    fetch(process.env.REACT_APP_BASE_URL + "categorie")
      .then((res) => res.json())
      .then((res) => {
        setItemsCategorie(res);
      });
    fetch(process.env.REACT_APP_BASE_URL + "zone")
      .then((res) => res.json())
      .then((res) => {
        setItemsZone(res);
      });
    fetch(process.env.REACT_APP_BASE_URL + "clienti")
      .then((res) => res.json())
      .then((res) => {
        setItemsReferenti(res);
      });
    fetch(process.env.REACT_APP_BASE_URL + "tipi-punto-vendita")
      .then((res) => res.json())
      .then((res) => {
        if (idCasaMadre !== false) {
          res = res.filter((itemType) => itemType.id !== 1);
        }
        setItemsTipiPunto(res);
      });
  };
  useEffect(() => {
    getData();
    getModels();
  }, []);

  const updateMessage = (newMessage) => {
    setMessageData({ ...messageData, body: newMessage });
  };

  return (
    <>
      <div className="card">
        <Typography variant="h5" style={{ marginBottom: 12 }}>
          Anagrafica
        </Typography>
        <div className="card-bodyx">
          <form onSubmit={(event) => handleSubmit(event)} autoComplete="on">
            <div>
              <div className="form-row">
                <label className="form-label">NOME*</label>
                <input
                  className="form-input"
                  type="text"
                  name="nome"
                  required={true}
                />
              </div>
              <div className="form-row">
                <label className="form-label">COGNOME*</label>
                <input
                  className="form-input"
                  type="text"
                  name="cognome"
                  required={true}
                />
              </div>
              <div className="form-row">
                <label className="form-label">EMAIL UTENTE*</label>
                <input
                  className="form-input"
                  type="text"
                  name="email"
                  required={true}
                />
              </div>
            </div>
            <Divider />
            <Typography variant="h5" style={{ marginBottom: 12 }}>
              Anagrafica punto vendita
            </Typography>
            <div class="table-dati d_az">
              <input type="hidden" name="id_azienda" value={1} />
              <div>
                <div>
                  <label>E-mail PUNTO VENDITA*:</label>
                  <span>
                    <input type="text" name="email_azienda" required={true} />
                  </span>
                </div>
                <div class="dat2">
                  <label>Ragione sociale*:</label>
                  <span>
                    <input name="nome_azienda" type="text" required={true} />
                  </span>
                </div>
              </div>
              <div>
                <div>
                  <label>Zona di vendita</label>
                  <span>
                    <select name="id_zona">
                      {itemsZone.map((zona) => (
                        <option value={zona.id}>{zona.nome}</option>
                      ))}
                    </select>
                  </span>
                </div>
                <div>
                  <label>Tipo:</label>
                  <span>
                    <select name="id_tipo">
                      {itemsTipiPunto.map((tipoPunto) => (
                        <option value={tipoPunto.id}>{tipoPunto.nome}</option>
                      ))}
                    </select>
                  </span>
                </div>
                <div>
                  <label>Categoria:</label>
                  <span>
                    <select name="id_categoria">
                      {itemsCategorie.map((categoria) => (
                        <option value={categoria.id}>{categoria.nome}</option>
                      ))}
                    </select>
                  </span>
                </div>
              </div>
              <div>
                <div>
                  <label>Telefono:</label>
                  <span>
                    <input type="text" name="telefono" />
                  </span>
                </div>
                <div>
                  <label>Codice fiscale:</label>
                  <span>
                    <input
                      type="text"
                      name="codice_fiscale"
                      maxLength={16}
                      required={false}
                    />
                  </span>
                </div>
                <div class="dat3">
                  <label>Partita IVA:</label>
                  <span>
                    <input
                      name="p_iva"
                      type="text"
                      maxLength={11}
                      required={false}
                    />
                  </span>
                </div>
              </div>
              <div>
                <div>
                  <label>Provincia:</label>
                  <span>
                    <input type="text" name="provincia" required={false} />
                  </span>
                </div>
                <div>
                  <label>Citt&agrave;:</label>
                  <span>
                    <input type="text" name="citta" required={false} />
                  </span>
                </div>
                <div>
                  <label>Indirizzo:</label>
                  <span>
                    <input type="text" name="indirizzo" required={false} />
                  </span>
                </div>

                <div>
                  <label>Codice postale:</label>
                  <span>
                    <input
                      type="text"
                      name="cap"
                      maxLength={5}
                      required={false}
                    />
                  </span>
                </div>
              </div>
            </div>
            <Divider />
            <Typography variant="h5" style={{ marginBottom: 12 }}>
              Associazione Brands
            </Typography>
            <UtentiAssociaBrands
              fullHeight
              utenteBrands={utenteBrands}
              selectedUser={selectedUser}
              toggleItem={(item) => {
                const foundIndex = utenteBrands.findIndex(
                  (brand) => brand.id === item.id
                );
                if (foundIndex === -1) {
                  setUtenteBrands([...utenteBrands, item]);
                } else {
                  const arrayCopy = JSON.parse(JSON.stringify(utenteBrands));
                  arrayCopy.splice(foundIndex, 1);
                  setUtenteBrands(arrayCopy);
                }
              }}
            />

            <Divider />

            <div style={{ margin: "24px 24px" }}>
              <Typography variant="h5" style={{ marginBottom: 12 }}>
                Messaggio di invito
              </Typography>
              <Editor
                apiKey="dco46zttpjftz9nczj7keskp9vh43pdscra2r8xgqoprc6tf"
                onInit={(evt, editor) => (editorRef.current = editor)}
                value={messageData.body}
                init={{
                  menubar: false,
                  statusbar: false,
                  resize: false,
                  height: 200,
                  min_height: 200,
                  language: "it",
                  content_style:
                    "p { margin: 0; }, body { font-family : Helvetica, Arial, sans-serif, font-size:16px }",
                  plugins: [
                    "advlist autolink autoresize lists link image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link | removeformat | code | help",
                }}
                onEditorChange={updateMessage}
              />
            </div>
            <div style={{ marginTop: 24 }} />

            <div className="form-row">
              <div
                className="form-buttons-right"
                style={{
                  float: "none",
                  display: "flex",
                  justifyContent: "flex-end",
                  marginRight: "1em",
                }}
              >
                <Button
                  className="btn btn-outline-primary rounded-1 btn-sm"
                  type="submit"
                >
                  Invia
                </Button>
                <Button
                  className="btn btn-outline-primary rounded-1 btn-sm"
                  type="button"
                  onClick={() => {
                    history.push("/impostazioni");
                  }}
                >
                  Annulla{" "}
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default NuovaAnagrafica;
