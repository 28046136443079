import React, { useState, useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link, useLocation } from "react-router-dom";
import useScrollPosition from "use-scroll-position";
import MetisMenu from "metismenujs";
import { Typography } from "antd";
import {
  CalendarViewMonth,
  FolderCopy,
  SmartDisplay,
} from "@mui/icons-material";

import { THEME_RED } from "../../../CONST";

const MM = ({ children }) => {
  useEffect(() => {
    const metismenu = new MetisMenu(".metismenu");
    return () => {
      metismenu.dispose();
    };
  }, []);

  return (
    <div className="mm-wrapper">
      <ul className="metismenu" style={{ display: "flex" }}>
        {children}
      </ul>
    </div>
  );
};

const SideBar = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    var btn = document.querySelector(".menu-button");
    var navbar = document.querySelector(".dlabnav");

    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      navbar.style.display = aaa.classList.contains("menu-toggle")
        ? "block"
        : "none";
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);
    //sidebar icon Heart blast

    return () => {
      btn.removeEventListener("click", toggleFunc);
    };
  }, []);

  useEffect(() => {
    var aaa = document.querySelector("#main-wrapper");
    aaa.classList.remove("menu-toggle");
  }, [location, location.pathname]);

  let scrollPosition = useScrollPosition();
  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  /// Active menu
  let player = ["player"],
    cartelle = ["cartelle"],
    palinsesti = ["palinsesti"];

  return (
    <div className="dlabnav">
      <PerfectScrollbar className="dlabnav-scroll">
        <MM className="metismenu" id="menu">
          <li className={`${player.includes(path) ? "mm-active" : ""}`}>
            <Link
              className={`${path === "player" ? "mm-active" : ""}`}
              to="/player"
              style={{ display: "flex", alignItems: "center" }}
            >
              <SmartDisplay sx={{ color: `${THEME_RED} !important` }} />
              <Typography>Player & Licenze</Typography>
            </Link>
          </li>
          <li className={`${cartelle.includes(path) ? "mm-active" : ""}`}>
            <Link
              className={`${path === "cartelle" ? "mm-active" : ""}`}
              to="/contenuti?tab=cartelle"
              style={{ display: "flex", alignItems: "center" }}
            >
              <FolderCopy sx={{ color: `${THEME_RED} !important` }} />
              <Typography>Contenuti</Typography>
            </Link>
          </li>
          <li className={`${palinsesti.includes(path) ? "mm-active" : ""}`}>
            <Link
              className={`${path === "palinsesti" ? "mm-active" : ""}`}
              to="/palinsesti?tab=palinsesti-sub"
              style={{ display: "flex", alignItems: "center" }}
            >
              <CalendarViewMonth sx={{ color: `${THEME_RED} !important` }} />
              <Typography>Palinsesti</Typography>
            </Link>
          </li>
        </MM>
      </PerfectScrollbar>

      <div
        className={`modal fade ${isModalOpen ? "show" : ""}`}
        id="InstructionModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="favoritesModalLabel"
        style={{
          display: isModalOpen ? "block" : "none",
          marginTop: "8vh",
          marginBottom: "8vh",
          height: "84vh",
          bavground: "rgba(0, 0, 0, 0.5)",
        }}
      ></div>
    </div>
  );
};
export default SideBar;
