import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Button } from "react-bootstrap";
import { Editor } from "@tinymce/tinymce-react";
import HelpMessages from "./help_messagges";
import { API_KEY } from "../../CONST";

export function MessaggiImpostazioni(props) {
  const editorRef = useRef(null);
  const [currentUser] = useState(props.currentUser);
  const [modelsMessages] = useState([]);
  const [enableChange, setEnableChange] = useState(false);
  const [currentModelMessage, setCurrentModelMessage] = useState({});

  useEffect(() => {
    getModels();
  }, []);

  const getModels = () => {
    try {
      modelsMessages.push({
        id: 0,
        messaggio: "",
        categoria: 0,
        id_utente: currentUser.userid,
      });
      modelsMessages.push({
        id: 0,
        messaggio: "",
        categoria: 1,
        id_utente: currentUser.userid,
      });
      modelsMessages.push({
        id: 0,
        messaggio: "",
        categoria: 2,
        id_utente: currentUser.userid,
      });
      modelsMessages.push({
        id: 0,
        messaggio: `Buongiorno,
        <br>
      necessiterei di <b>X</b> licenze per il mio account.
      <br><br>
      Grazie.`,
        categoria: 3,
        id_utente: currentUser.userid,
      });
      // static message for help by Darkman
      modelsMessages.push({
        id: 0,
        messaggio: "In fase\r\ndi implementazione",
        categoria: 4,
        id_utente: currentUser.userid,
      });

      var data = { id_user: currentUser.userid, token: currentUser.idToken };
      axios
        .post(process.env.REACT_APP_BASE_URL + "get-models", data)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.length > 0) {
              var data = res.data;
              data.forEach((i) => {
                modelsMessages.forEach((m) => {
                  if (m.categoria === i.categoria) {
                    m.messaggio = i.messaggio;
                    m.id = i.id;
                  }
                });
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
      setCurrentModelMessage(modelsMessages[0]);
    } catch (error) {
      console.log(error);
    }
  };

  const selectModel = (id) => {
    setCurrentModelMessage(modelsMessages[id]);
  };

  const saveModel = (content) => {
    if (currentModelMessage.categoria !== 4) {
      var modelMessage = currentModelMessage;
      setEnableChange(modelMessage.messaggio !== content);
      modelMessage.messaggio = content;
      setCurrentModelMessage(modelMessage);
    }
  };

  const saveChanges = () => {
    try {
      axios
        .post(process.env.REACT_APP_BASE_URL + "set-model", currentModelMessage)
        .then((res) => {
          if (res.status === 200) {

          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
    setEnableChange(false);
  };

  if (modelsMessages.length === 0) {
    return <></>;
  }

  return (
    <>
      <div className="impostazioni-messaggi">
        <p className="subtab">Modelli Predefiniti</p>
        <div style={{ marginRight: "20px", float: "left" }}>
          <p>
            <Button
              style={{
                width: "16.8vw",
                padding: "10px",
                borderRadius: "8px",
                backgroundColor:
                  currentModelMessage.categoria === 0 ? "green" : "#4885ed",
              }}
              onClick={() => {
                selectModel(0);
              }}
            >
              Firma Messaggi
            </Button>
          </p>
          <p>
            <Button
              hidden={currentUser.tipo > 2}
              style={{
                width: "16.8vw",
                padding: "10px",
                borderRadius: "8px",
                backgroundColor:
                  currentModelMessage.categoria === 1 ? "green" : "#4885ed",
              }}
              onClick={() => {
                selectModel(1);
              }}
            >
              Condivisione
            </Button>
          </p>
          <p>
            <Button
              hidden={currentUser.tipo > 2}
              style={{
                width: "16.8vw",
                padding: "10px",
                borderRadius: "8px",
                backgroundColor:
                  currentModelMessage.categoria === 2 ? "green" : "#4885ed",
              }}
              onClick={() => {
                selectModel(2);
              }}
            >
              Email Invito
            </Button>
          </p>
          <p>
            <Button
              style={{
                width: "16.8vw",
                padding: "10px",
                borderRadius: "8px",
                backgroundColor:
                  currentModelMessage.categoria === 3 ? "green" : "#4885ed",
              }}
              onClick={() => {
                selectModel(3);
              }}
            >
              Richiesta Licenze
            </Button>
          </p>
          <p>
            <Button
              hidden={currentUser.tipo > 2}
              style={{
                width: "16.8vw",
                padding: "10px",
                borderRadius: "8px",
                backgroundColor:
                  currentModelMessage.categoria === 4 ? "green" : "#4885ed",
              }}
              onClick={() => {
                selectModel(4);
              }}
            >
              Guida in linea
            </Button>
          </p>
        </div>
        <div style={{ minWidth: "300px", minHeight: "300px" }}>
          <div hidden={currentModelMessage.categoria !== 4} className="guida">
            <HelpMessages />
          </div>

          <div hidden={currentModelMessage.categoria === 4}>
            <Editor
              apiKey={API_KEY}
              onInit={(evt, editor) => (editorRef.current = editor)}
              initialValue={
                currentModelMessage.categoria !== 4
                  ? currentModelMessage.messaggio
                  : ""
              }
              init={{
                menubar: false,
                statusbar: false,
                resize: false,
                height: 200,
                min_height: 200,
                language: "it",
                plugins: [
                  "advlist autolink autoresize lists link image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste code help wordcount",
                ],
                toolbar:
                  "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link | removeformat | code | help",
                content_style:
                  "p { margin: 0; }, body { font-family:Helvetica,Arial,sans-serif, font-size:16px }",
              }}
              onEditorChange={saveModel}
            />
          </div>
        </div>
        <div style={{ textAlign: "right", marginTop: "20px" }}>
          <Button
            disabled={!enableChange}
            hidden={currentModelMessage.categoria === 4}
            onClick={saveChanges}
            style={{ width: "180px", padding: "10px", borderRadius: "8px" }}
          >
            Modifica
          </Button>
        </div>
      </div>
    </>
  );
}

export default MessaggiImpostazioni;
