import React, { useState, useEffect } from "react";
import { ProgressBar, Modal, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { v4 as uuidv4 } from "uuid";
import catalogo from "../../images/catalogo.png";
import posteraudio from "../../images/posteraudio.png";
const InserimentoContenuti3 = (props) => {
  const history = useHistory();
  const brand = props.brand;
  const categorie = props.categories;
  const cartella = props.folder;
  const items = props.fileItems;
  const [filesItems, setFilesItems] = useState([]);
  const [disabledSend, setDisabledSend] = useState(false);
  const [uploadValue, setUploadValue] = useState(0);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadText, setUploadText] = useState("");
  const [itemResultSuccess, setItemResultSuccess] = useState(true);
  const [show, setShow] = useState(false);
  
  const handleClose = () =>
    setTimeout(() => {
      setShow(false);
      if (itemResultSuccess) {
        Swal.fire({
          title: "",
          text: "Contenuti caricati con successo\nRicorda di rigenerare il palinsesto per applicare immediatamente le modifiche",
          icon: "success",
        });
      } else {
        Swal.fire({
          title: "",
          text: "Contenuti caricati con errori",
          icon: "warning",
        });
      }
    }, 1000);
  const handleShow = () => setShow(true);
  const config = {
    onUploadProgress: (progressEvent) => {
      try {
        setDisabledSend(true);
        setUploadValue(
          Math.round((progressEvent.loaded * 100) / progressEvent.total)
        );
      } catch (error) {}
    },
  };
  const distinctArray = [];
  useEffect(() => {
    items.forEach((i) => {
      if (
        !distinctArray.find((f) => {
          return f === i;
        })
      ) {
        if (i.selected) {
          distinctArray.push(i);
        }
      }
    });
    setFilesItems(distinctArray);
  }, []);
  const onScrollTop = () => {
    props.onScrollTop();
  };
  const progress = (p) => {
    setUploadProgress(Math.round((p * 100) / filesItems.length));
    setUploadText("Invio in corso " + p + " di " + filesItems.length + "...");
  };
  const loadFiles = (i) => {
    setDisabledSend(true);
    if (i + 1 <= filesItems.length) {
      let data = new FormData();
      let fileItem = filesItems[i];
      progress(i + 1);
      setUploadValue(0);
      let ext = fileItem.file.name.split(".").pop().toLowerCase();
      let fname = uuidv4() + "." + ext;
      data.append("id", uuidv4());
      data.append("nome", fileItem.title);
      data.append("descrizione", fileItem.description);
      data.append("dimensione", fileItem.size);
      data.append("durata", fileItem.duration);
      data.append("pubblicato", fileItem.published ? 1 : 0);
      data.append("inizio", fileItem.hasstart ? 1 : 0);
      data.append("fine", fileItem.hasend ? 1 : 0);
      data.append("data_inizio", fileItem.startdate);
      data.append("data_fine", fileItem.enddate);
      data.append("tipologia", fileItem.type);
      data.append("brand", JSON.stringify(brand));
      data.append("categorie", JSON.stringify(categorie));
      data.append("file", fileItem.file, fname);
      data.append(
        "idcartella",
        cartella !== undefined
          ? cartella.id
          : "000000000000-0000-0000-0000-00000000"
      );
      data.append("notificabile", fileItem.notified ? 1 : 0);
      axios
        .post(
          process.env.REACT_APP_BASE_URL + "importa-contenuti-wizard",
          data,
          config,
          {
            headers: {
              accept: "application/json",
              "Accept-Language": "en-US,en;q=0.8",
              "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
            },
          }
        )
        .then(function (response) {
          if (response.status === 200) {
            //Swal.fire({ title:"", text:"Contenuto caricato con successo",icon:"success"});
            //history.push('contenuti?tab=tutti-i-contenuti')

            setTimeout(() => {
              loadFiles(i + 1);
            }, 1000);
          } else {
            setItemResultSuccess(false);
            setTimeout(() => {
              loadFiles(i + 1);
            }, 1000);
            //throw new Error(response.status);
          }
        })
        .catch(function (error) {
          setItemResultSuccess(false);
          setTimeout(() => {
            loadFiles(i + 1);
          }, 1000);
        });
    } else {
      progress(i);
      props.loader();
      //setUploadValue(0);

      handleClose();
    }
  };
  const showContentPreview = (item) => {
    if (item.type === "Immagine") {
      return (
        <img
          style={{ maxHeight: "120px", minHeight: "120px" }}
          className="anteprima_inserimento"
          src={URL.createObjectURL(item.file)}
          alt={item.file.name}
        />
      );
    }

    if (item.type === "Audio") {
      return (
        <video
          style={{ maxHeight: "120px", minHeight: "120px" }}
          preload="metadata"
          className="anteprima_inserimento"
          id={item.file.name}
          src={URL.createObjectURL(item.file)}
          poster={posteraudio}
          controls
        />
      );
    }
    if (item.type === "Video") {
      return (
        <video
          style={{ maxHeight: "120px", minHeight: "120px" }}
          className="anteprima_inserimento"
          id={item.file.name}
          src={URL.createObjectURL(item.file)}
          controls
        />
      );
    }
    if (item.type === "Catalogo") {
      return (
        <img
          style={{ maxHeight: "120px", minHeight: "120px" }}
          className="anteprima_inserimento"
          src={catalogo}
          alt={item.file.name}
        />
      );
    }
  };
  const showStart = (item) => {
    if (item.hasstart) {
      return (
        "Data Inizio: " +
        new Date(item.startdate).toLocaleDateString("it", {
          weekday: "long",
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }) +
        " "
      );
    }
    return <br />;
  };
  const showEnd = (item) => {
    if (item.hasend) {
      return (
        "Data Scadenza: " +
        new Date(item.enddate).toLocaleDateString("it", {
          weekday: "long",
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }) +
        " "
      );
    }
    return <br />;
  };

  return (
    <>
      <div className="inserimento-contenuti">
        <div
          style={{
            textAlign: "right",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          <button
            hidden={disabledSend}
            className="btn btn-primary btncontenuti"
            onClick={() => {
              handleShow();
              loadFiles(0);
            }}
          >
            Invia
          </button>
        </div>
        <div
          style={{
            textAlign: "right",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          <button
            hidden={!disabledSend}
            className="btn btn-primary btncontenuti"
            onClick={() => {
              history.push("contenuti?tab=cartelle");
            }}
          >
            Fine
          </button>
        </div>
        <table>
          <tr>
            <th>Anteprima</th>
            <th>Nome</th>
            <th>Descrizione</th>
            <th>Durata</th>
            <th>Validit&agrave;</th>
          </tr>
          {filesItems.map((item) => (
            <tr>
              <td
                style={{
                  textAlign: "center",
                  minWidth: "160px",
                  maxWidth: "160px",
                  maxHeight: "90px",
                  minHeight: "90px",
                }}
              >
                {showContentPreview(item)}
              </td>
              <td>{item.title}</td>
              <td>{item.description}</td>
              <td>{item.duration}&nbsp;secondi</td>
              <td>
                Pubblicato{" "}
                <input
                  disabled={true}
                  type="checkbox"
                  defaultChecked={item.published}
                />
                <br />
                {showStart(item)}
                <br />
                {showEnd(item)}
              </td>
            </tr>
          ))}
        </table>
        <div
          style={{ textAlign: "right", padding: "10px" }}
          hidden={filesItems.length < 3}
        >
          <Button
            className="btn btn-success btn-floating btn-lg"
            style={{ padding: "10px 20px" }}
            onClick={() => {
              onScrollTop();
            }}
          >
            <i class="fas fa-arrow-up"></i>
          </Button>
        </div>
      </div>
      <Modal
        dialogClassName="modal-inserimento"
        show={show}
        onRequestClose={handleClose}
      >
        <Modal.Header>
          <h3>Caricamento in corso...</h3>
        </Modal.Header>
        <Modal.Body>
          <ProgressBar animated now={uploadValue} />
          <div id="loadStatus">{uploadText}</div>
          <ProgressBar animated now={uploadProgress} />
        </Modal.Body>
        <Modal.Footer>
          <h5>Non cambiare pagina e non chiudere questa finestra!</h5>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default InserimentoContenuti3;
