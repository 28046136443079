import React from "react";

const Footer = () => {
  var d = new Date();
  return (
    <div className="footer">
      <div className="copyright">
      <p>
        <a href="https://forms.gle/Ww2Vjsn1aYV6arJz7" target="_blank" rel="noreferrer">
          Segnala un problema
        </a>
        </p>
        <p>
          Copyright ©{" "}
          <a href="https://22hbg.com/" target="_blank" rel="noreferrer">
            22HBG S.r.l.
          </a>{" "}
          {d.getFullYear()}
        </p>
      </div>
    </div>
  );
};

export default Footer;
