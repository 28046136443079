import {React,useState, useEffect } from "react";
import { MDBDataTableV5 } from "mdbreact";
import axios from 'axios';
import Swal from "sweetalert2";
export function PosLicNuove(props) {
  function handleClose(){ props.handleClose();}
  const Generator = require("license-key-generator");
  const posLic = props.posLic
  const [dataLicNuove, setDataLicNuove] = useState([]);
  const [dataLicenses, setDataLicenses] = useState([]);
  const [successSave, setSuccessSave] = useState(true);
  const options = {
    type: "random", // default "random"
    length: 8, // default 16
    group: 4, // default 4
    split: "-", // default "-"
    splitStatus: true // default true
  }
  const code = new Generator(options);
  const saveLicenses = () =>
  {
    try
    {
      let messageLic = "Licenze inserite con successo";
      let errorLic = "Si sono verificati errori nell'inserimento delle licenze";
      if (dataLicNuove.length === 1)
      {
        messageLic = "Licenza inserita con successo";
        errorLic = "Si è verificato un errore nell'inserimento della licenza";
      }
      dataLicNuove.forEach(async (l) => {
          await axios.post(process.env.REACT_APP_BASE_URL + "nuova-licenza", l).then(res => {

          }).catch((err) => {setSuccessSave(false)});
        }
      )
      if (successSave)
      {
        Swal.fire({title : "", text: messageLic, icon : "success"});
        handleClose();
       //window.location.reload(false);
      }
      else
      {
        Swal.fire({title : "", text: errorLic, icon : "error"});  
      }
    }
    catch(error)
    {
      Swal.fire({title : "", text:"Si sono verificati errori nell'inserimento delle licenze", icon : "error"});  
    }
  }
  const closeModal= () => handleClose();
  const handleSubmit = async (e) =>{
    e.preventDefault();
    if (parseInt(e.target.qty.value) === 0 || parseInt(e.target.months.value) === 0)
    {
      Swal.fire({title : "", text : "Impostare quantità e durata delle licenze che si vuole generare!", icon : "warning"})
      return;
    }
    let months_expiration = e.target.months.value;
    let lics = JSON.parse(JSON.stringify(dataLicNuove));
    let licenseKey = "";
    for (var i = 0; i < parseInt(e.target.qty.value); i++) {
      /* eslint-disable */
      await code.get((error,code)=>{
        if(error) {return console.error(error)}
          licenseKey = code;
        });
        let date = new Date();
        lics.push({ codice : licenseKey, status : 0, id_pos : posLic.id, data_inserimento : date, months : months_expiration });
    }
    setDataLicNuove(lics) ;
  }
  const handleReset = () => {
    setDataLicNuove([]);
  }
  const showLicenses = () =>{
    let itemsArray = dataLicNuove;
    let dataitems = [];
    /* eslint-disable */
    itemsArray.map((license) => { 
      if (parseInt(license.months) === 12)
      {
        license.month_duration = (license.months / 12) + " Anno";
      }
      else if (parseInt(license.months) > 12)
      {
        license.month_duration = (license.months / 12) + " Anni";
      }
      else if (parseInt(license.months) < 12)
      {
        if (parseInt(license.months) === 1)
        {
          license.month_duration = license.months + " Mese";
        }
        else
        {
          license.month_duration = license.months + " Mesi";
        }
      }
      dataitems.push(license)}, setDataLicenses(dataitems));  
  }
  useEffect(showLicenses,[dataLicNuove]);
  setTimeout(() => {
    var buttons = document.getElementsByClassName('page-link')
    if (buttons.length > 0)
    {
    
      for (var i=0; i < buttons.length; i++) {
        buttons[i].onclick = function(){
      
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          });
        }
      };
    }
}, 1000);
  const data = {
    columns: [
     
        {
            label: "Codice",
            field: "codice",
            sort: "disabled",
            width: 270,
        },
        {
            label: "Durata",
            field: "month_duration",
            sort: "disabled",
            width: 200,
        }
    ],
    rows: dataLicenses
  };
  return(
    <div style={{minHeight : "600px"}}>
      <div className="cardX">
        <div className="gestione-licenze">
          <h4 style={{margin:"0 auto"}}>Generazione Licenze &gt; {posLic.nome}</h4>
          <div className="inserimento-licenze">
            <form style={{margin:"0 auto"}} onSubmit={handleSubmit}>
             
                
              
                <input className="select" min={0} max={100} style={{width:"120px"}} type="number" name="qty" placeholder="Quantit&agrave;" />
                <select name="months" className="select">
                  <option value={0} selected={true} >Selezionare la Durata</option>
                  <option value={1}>1 Mese</option>
                  <option value={2}>2 Mesi</option>
                  <option value={3}>3 Mesi</option>
                  <option value={6}>6 Mesi</option>
                  <option value={9}>9 Mesi</option>
                  <option value={12}>1 Anno</option>
                  <option value={24}>2 Anni</option>
                  <option value={60}>5 Anni</option>
                </select>
              
             
              
              <div className="right">
                <button className="btn btn-outline-primary rounded-2 btn-sm" type="submit">Conferma</button>
                <button className="btn btn-outline-primary rounded-2 btn-sm" type="button" onClick={handleReset} name="reset">Reset</button>
              </div>
            </form>
            <div className='testo'><h6>La scadenza viene sempre applicata rispetto alla data di attivazione, che avviene esclusivamente nel momento in cui la licenza viene associata al dispositivo finale (player). Le licenze vanno attivate entro 60 giorni dall'emissione.</h6></div>
            <hr />
            <div className='tabella-codici' >
              <div hidden={dataLicenses.length === 0} >
                <MDBDataTableV5 entries={5} hover data={data} proSelect searchTop={false} searchBottom={false} fullPagination responsive disableRetreatAfterSorting={true} />   
              </div>
            </div>
            <div className="right">
              <button disabled={dataLicenses.length === 0} className="btn btn-outline-primary rounded-2 btn-sm" name="save" onClick={saveLicenses}>Salva</button>
              <button className="btn btn-outline-primary rounded-2 btn-sm" name="cancel" onClick={closeModal} >Annulla</button>
            </div>
          </div> 
        </div>
      </div>
    </div>
    
)};
export default PosLicNuove
           

