function dateFormat(date) {
    var hours = (date.getHours() < 10 ? "0" + date.getHours() : date.getHours());
    var minutes = (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()); 
    var seconds = (date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds());
    var day = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate());
    var month = ((date.getMonth() + 1) < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1); 
    var year = date.getFullYear();
    var strTime = hours + ':' + minutes + ':' + seconds;
    return day + "/" + month + "/" + year + "  " + strTime;
  }
  export default dateFormat