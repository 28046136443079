import { MDBDataTableV5 } from "mdbreact";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import SectionSubtitle from "../components/Subtitles";
import CircleButtonComponent from "../components/Button/CircleButton";
import { Add, Delete, Edit } from "@mui/icons-material";
//import {default as EliminaTag} from "./elimina-tag";
//import { ItemMeta } from "semantic-ui-react";
export let itemTags = [{ nomeTag: "", idTag: 1, coloreTag: "" }];
function Tags() {
  const [items, setItems] = useState([]);
  const [usersForRender, setUsersForRender] = useState([]);
  const getData = () => {
    fetch(process.env.REACT_APP_BASE_URL + "tags")
      .then((res) => res.json())
      .then((res) => {
        if (res.length === 0) {
          setItems([{ id: -1, nome: "Nessun tag presente!" }]);
          return;
        }
        setItems(res);
      });
  };
  useEffect(() => {
    getData();
  }, []);
  setTimeout(() => {
    var buttons = document.getElementsByClassName("page-link");
    if (buttons.length > 0) {
      for (var i = 0; i < buttons.length; i++) {
        buttons[i].onclick = function () {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        };
      }
    }
  }, 1000);

  const deleteTag = (item) => {
    const dataDel = {};
    dataDel["id"] = item.id;
    fetch(process.env.REACT_APP_BASE_URL + "elimina-tag", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataDel),
    })
      .then(function (response) {
        if (response.status === 200) {
          Swal.fire({
            title: "",
            text: "Operazione eseguita correttamente",
            icon: "success",
          }).then(function () {
            getData();
          });
        }
      })
      .catch(function (error) {
        Swal.fire({
          title: "",
          text: "Impossibile eliminare il tag selezionato",
          icon: "error",
        });
      });
  };
  /* eslint-disable */
  useEffect(() => {
    let itemsArray = items;
    let tagData = [];
    itemsArray.map((item, index) => {
      if (item.id > 0) {
        item.tagcolor = (
          <span
            style={{ backgroundColor: item.colore }}
            className="c-color rounded-circle"
          ></span>
        );
        item.action = (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <CircleButtonComponent>
              <Link
                className="c-pointer button-action"
                onClick={() => {
                  itemTags = item;
                }}
                to={() => {
                  return "impostazioni?tab=tags&action=modifica";
                }}
              >
                <Edit sx={{ color: "white" }} />
              </Link>
            </CircleButtonComponent>

            <CircleButtonComponent
              icon={<Delete />}
              onClick={() =>
                Swal.fire({
                  title: "Confermi?",

                  html:
                    "<div style='padding:10px;'><span style='background-color:" +
                    item.colore +
                    "; margin-right:20px;' class='c-color rounded-circle'></span> Attenzione, il tag <b> " +
                    item.nome +
                    "</b> sarà eliminato!</div>",
                  showCancelButton: true,
                  confirmButtonText: "Elimina",
                  cancelButtonText: "Annulla",
                  reverseButtons: true,
                  icon: "question",
                }).then((result) => {
                  if (result.isConfirmed) {
                    deleteTag(item);
                  } else if (
                    /* Read more about handling dismissals below */
                    result.dismiss === Swal.DismissReason.cancel
                  ) {
                    Swal.fire("Operazione annullata");
                  }
                })
              }
            />
          </div>
        );
      }
      tagData.push(item);
    });
    setUsersForRender(tagData);
  }, [items]);

  const data = {
    columns: [
      {
        label: "",
        field: "",
        sort: "disabled",
        width: "10",
      },
      {
        label: "Nome",
        field: "nome",
        sort: "asc",
        width: 270,
      },

      {
        label: "Colore",
        field: "tagcolor",
        sort: "disabled",
        width: 270,
      },

      {
        label: "Azione",
        field: "action",
        sort: "disabled",
        width: 150,
      },
    ],
    rows: usersForRender,
  };
  return (
    <div className="tabella_tag">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "32px",
        }}
      >
        <SectionSubtitle text="Aggiungi, elimina o modifica le tipologie dei tuoi prodotti." />
        <CircleButtonComponent
          icon={<Add />}
          onClick={() =>
            (window.location.href = "impostazioni?tab=tags&action=aggiunta")
          }
        />
      </div>
      <MDBDataTableV5
        hover
        data={data}
        proSelect
        searchTop
        searchBottom={false}
        fullPagination
        searchLabel={"Ricerca tag ..."}
        responsive
        disableRetreatAfterSorting={true}
      />{" "}
    </div>
  );
}
export { Tags as default, itemTags as array };
