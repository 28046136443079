import { useEffect, useState, useRef } from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { itemCat } from "./categorie";

function CategoriaBrands() {
  const chkRef = useRef();
  const history = useHistory();
  const [itemsBrands, setItemsBrands] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const fetchBrands = () => {
    fetch(process.env.REACT_APP_BASE_URL + "brands")
      .then((res) => res.json())
      .then((res) => {
        setItemsBrands(res);
        setLoaded(true);
      });
  };
  const toggleItem = (i, e) => {
    const data = {};
    data["id_brand"] = i.id;
    data["id_categoria"] = itemCat.id;

    if (e.checked) {
      axios
        .post(
          process.env.REACT_APP_BASE_URL + "inserisci-brand-categoria",
          data
        )
        .then(function (response) {
          if (response.status === 200) {
            if (
              !itemCat.brands.find((item) => {
                return item.id === i.id;
              })
            ) {
              itemCat.brands.push(i);
            }
          } else {
            throw new Error(response);
          }
        })
        .catch(function (error) {});
    } else {
      axios
        .post(process.env.REACT_APP_BASE_URL + "rimuovi-brand-categoria", data)
        .then(function (response) {
          if (response.status === 200) {
            if (
              itemCat.brands.find((item) => {
                return item.id === i.id;
              })
            ) {
              itemCat.brands.splice(itemCat.brands.indexOf(i), 1);
            }
          } else {
            throw new Error(response);
          }
        })
        .catch(function (error) {});
    }
  };
  const getChecked = (itemId) => {
    return itemCat.brands
      .filter((b) => b !== null)
      .find((brand) => brand.id === itemId);
  };

  useEffect(() => {
    fetchBrands();
  }, []);
  if (!loaded && !itemCat.brands) {
    return <div className="App">Caricamento in corso...</div>;
  }

  return (
    <>
      <div className="nuovo-palinsesto">
        <div className="buttons-top-right">
          <Button
            className="btn btn-outline-primary rounded-1 btn-sm"
            onClick={() => {
              history.push("impostazioni");
            }}
            type="button"
          >
            Indietro
          </Button>
        </div>
        <p className="subtab">
          Associa i brands alla categoria <b>{itemCat.nome}</b>
        </p>
        <ul>
          {itemsBrands.map((item) => (
            <li>
              <input
                type="checkbox"
                id={"brand" + item.id}
                ref={chkRef}
                defaultChecked={getChecked(item.id)}
                onChange={(e) => {
                  toggleItem(item, e.currentTarget);
                }}
              />
              <label for={"brand" + item.id}>
                <img
                  className=""
                  src={process.env.REACT_APP_BASE_URL_CONTENTS + item.logo}
                  alt=""
                />
              </label>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

export default CategoriaBrands;
