import { Typography } from "@material-ui/core";
import { MDBDataTableV5 } from "mdbreact";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import SectionSubtitle from "../components/Subtitles";
import CircleButtonComponent from "../components/Button/CircleButton";
import { Add, Delete, Edit, Visibility } from "@mui/icons-material";
export let itemAnag = [];

export const setItemAnag = (item) => {
  itemAnag = item;
};
function Anagrafiche() {
  const [items, setItems] = useState([]);
  const [anagrafiche, setAnagrafiche] = useState([]);
  const getData = () => {
    fetch(process.env.REACT_APP_BASE_URL + "punti-vendita/1")
      .then((res) => res.json())
      .then((res) => {
        if (res.length === 0) {
          setItems([{ id: -1, nome: "Nessuna anagrafica presente!" }]);
          return;
        }
        setItems(res);
      });
  };
  useEffect(() => {
    getData();
  }, []);
  setTimeout(() => {
    var buttons = document.getElementsByClassName("page-link");
    if (buttons.length > 0) {
      for (var i = 0; i < buttons.length; i++) {
        buttons[i].onclick = function () {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        };
      }
    }
  }, 1000);
  /* eslint-disable */
  function deleteQuestion(item) {
    Swal.fire({
      title: "Confermi l'eliminazione del punto vendita " + item.nome + "?",
      //html: "<div style='padding:10px;'><span style='background-color:" + item.colore + "; margin-right:20px;' class='c-color rounded-circle'></span> Attenzione, il tag <b> " + item.nome + "</b> sarà eliminato!</div>",
      showCancelButton: true,
      confirmButtonText: "Elimina",
      cancelButtonText: "Annulla",
      reverseButtons: true,
      icon: "question",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteAnagrafica(item.id);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({ text: "Operazione annullata", icon: "error" });
      }
    });
  }
  function anagDetail(item) {
    const items = [];
    console.log(item);
    Swal.fire({
      title: "Dettagli punto vendita",
      html: `
      <div style="display:flex; gap:12; flex-direction:column; gap:12px">
      <span>Ragione Sociale: ${item.nome}</span>
      <span>Indirizzo:${item.indirizzo} </span>
      <span>Localit&agrave;: ${item.citta}, ${item.provincia}, ${item.cap}</span>
      <span>Referente: ${item.referente} </span>
      <span>Licenze assegnate: ${item.licenze.length} </span>
        `,
      showCancelButton: false,
      confirmButtonText: "OK",
      reverseButtons: true,
      icon: "info",
    });
  }
  function deleteAnagrafica(idAnagrafica) {
    const dataDel = {};
    dataDel["id"] = idAnagrafica;
    fetch(process.env.REACT_APP_BASE_URL + "elimina-punto-vendita", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataDel),
    })
      .then(function (response) {
        if (response.status === 200) {
          Swal.fire({
            title: "",
            text: "Operazione eseguita correttamente",
            icon: "success",
          }).then(function () {
            getData();
          });
        }
      })
      .catch(function (error) {
        Swal.fire({
          title: "",
          text: "Impossibile eliminare l'anagrafica selezionata",
          icon: "error",
        });
      });
  }

  useEffect(() => {
    let itemsArray = items;
    let anagraficheData = [];
    itemsArray.map((item) => {
      if (item.id > 0) {
        item.action = (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <CircleButtonComponent>
              <Link
                onClick={() => {
                  setItemAnag(item);
                }}
                to={() => {
                  return "impostazioni?tab=anagrafiche&action=modifica";
                }}
              >
                <Edit sx={{ color: "white" }} />
              </Link>
            </CircleButtonComponent>
            <CircleButtonComponent
              icon={<Visibility />}
              onClick={() => {
                anagDetail(item);
              }}
            />
            <CircleButtonComponent
              icon={<Delete />}
              onClick={() => deleteQuestion(item)}
            />
          </div>
        );
      }
      anagraficheData.push(item);
    });
    setAnagrafiche(anagraficheData);
  }, [items]);

  const data = {
    columns: [
      {
        label: "",
        field: "",
        sort: "disabled",
        width: "10",
      },
      {
        label: "Nome",
        field: "nome",
        sort: "asc",
        width: 270,
      },
      {
        label: "Licenze",
        field: "numero_licenze_assegnate",
        sort: "asc",
        width: 100,
      },
      {
        label: "Responsabile",
        field: "referente",
        sort: "asc",
        width: 270,
      },
      {
        label: "Categoria",
        field: "categoria",
        sort: "asc",
        width: 270,
      },

      {
        label: "Azione",
        field: "action",
        sort: "disabled",
        width: 150,
      },
    ],
    rows: anagrafiche.map((item) => ({
      ...item,
      numero_licenze_assegnate: item.licenze.length,
    })),
  };

  return (
    <div className="tabella_anagrafiche">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "32px",
        }}
      >
        <SectionSubtitle text="Visualizza le informazioni anagrafiche dei tuoi dipendenti e dei tuoi punti vendita." />
        <CircleButtonComponent
          icon={<Add />}
          onClick={() =>
            (window.location.href =
              "impostazioni?tab=anagrafiche&action=aggiunta")
          }
        />
      </div>
      <MDBDataTableV5
        hover
        data={data}
        proSelect
        searchTop
        searchBottom={false}
        fullPagination
        searchLabel={"Cerca"}
        responsive
        disableRetreatAfterSorting={true}
      />{" "}
    </div>
  );
}
export { Anagrafiche as default, itemAnag as array };
