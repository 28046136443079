

import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function SectionSubtitleComponent(props) {
  return (
    <Typography variant="body2">{props.text}</Typography>
  );
}

export default function SectionSubtitle(props) {
  const [text, setText] = React.useState(0);

  React.useEffect(() => {
  setText(props.text)
  }, []);

  return (
    <Box sx={{ marginLeft:'1%', marginBottom:'2%'}}>
      <SectionSubtitleComponent text={text}/>
    </Box>
  );
}
