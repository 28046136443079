import React from "react";
import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import UtentiAssociaBrands from "./utenti-associa-brands";
import Swal from "sweetalert2";
import { Typography } from "@mui/material";
export function NuovoUtenteAttivo() {
  const [currentUser] = useState(
    JSON.parse(localStorage.getItem("userDetails", "undefined"))
  );
  const [utenteBrands, setUtenteBrands] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [selectedUser] = useState({ id: 0 });
  const history = useHistory();

  const handleSubmit = (event) => {
    event.preventDefault();
    const elements = event.target.elements;
    const data = {};
    const itemsUtenteBrands = [];

    for (let i = 0; i < elements.length; i++) {
      const elem = elements[i];
      data[elem.name] = elem.value;
    }
    if (parseInt(event.target.tipo.value) === 3) {
      utenteBrands.forEach((i) => {
        let b = { id_utente: 0, id_brand: i.id };
        itemsUtenteBrands.push(b);
      });
    }
    data["utenteBrands"] = itemsUtenteBrands;
    fetch(process.env.REACT_APP_BASE_URL + "nuovo-utente/", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then(function (response) {
        if (response.status === 200) {
          //let responseText = JSON.stringify(response.text());
          Swal.fire({
            title: "",
            html: "L'utente &egrave; stato registrato correttamente ma non sar&agrave; attivo finch&eacute; non imposterà la password.",
            icon: "success",
          });
          history.push("utenti?tab=utenti-non-attivi");
        } else {
          throw new Error(response.status);
        }
      })
      .catch(function (error) {
        Swal.fire({
          title: "",
          text: "Errore creazione utente",
          icon: "error",
        });
      });
  };

  return (
    <>
      <div className="card">
        <Typography variant="h5" style={{ marginBottom: 12 }}>
          Nuovo utente
        </Typography>

        <div className="card-bodyx">
          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <label className="form-label">NOME</label>
              <input
                className="form-input"
                type="text"
                name="nome"
                required={true}
              />
            </div>
            <div className="form-row">
              <label className="form-label">COGNOME</label>
              <input
                className="form-input"
                type="text"
                name="cognome"
                required={true}
              />
            </div>
            <div className="form-row">
              <label className="form-label">EMAIL</label>
              <input
                className="form-input"
                type="text"
                name="email"
                required={true}
              />
            </div>
            <div class Name="form-row">
              <label className="form-label">RUOLO</label>
              <select
                onChange={(e) => {
                  setButtonDisabled(parseInt(e.currentTarget.value) !== 3);
                }}
                className="form-select-option"
                name="tipo"
                defaultValue={currentUser.tipo}
              >
                <option value="2">Operatore</option>
              </select>
            </div>
            {!buttonDisabled ? (
              <UtentiAssociaBrands
                utenteBrands={utenteBrands}
                selectedUser={selectedUser}
                toggleItem={(item) => {
                  const foundIndex = utenteBrands.findIndex(
                    (brand) => brand.id === item.id
                  );
                  if (foundIndex === -1) {
                    setUtenteBrands([...utenteBrands, item]);
                  } else {
                    const arrayCopy = JSON.parse(JSON.stringify(utenteBrands));
                    arrayCopy.splice(foundIndex, 1);
                    setUtenteBrands(arrayCopy);
                  }
                }}
              />
            ) : null}

            <div className="form-row">
              <div className="form-buttons-right">
                <Button
                  className="btn btn-outline-primary rounded-1 btn-sm"
                  type="submit"
                >
                  Salva
                </Button>
                <Button
                  className="btn btn-outline-primary rounded-1 btn-sm"
                  type="button"
                  onClick={() => {
                    history.push("utenti");
                  }}
                >
                  Annulla{" "}
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
export default NuovoUtenteAttivo;
