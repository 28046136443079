import React, { Fragment } from "react";
import { Dropdown,  Row, Card, Col} from "react-bootstrap";
import { Link } from "react-router-dom";
//import { useHistory } from "react-router-dom";
//import {default as EliminaBrand} from "./elimina-brand";
import Swal from "sweetalert2";
import SectionSubtitle from "../components/Subtitles";

export let itemBrand = [];
function deleteBrand (item){
    const data = {};
    data['id'] = item.id;
    data['logo'] = item.logo;
  //  let result = false;
    fetch(process.env.REACT_APP_BASE_URL + 'elimina-brand/', { method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(data)
    }).then(function(response) {
      if (response.status === 200) {
        Swal.fire({ title:"", text:"Operazione eseguita correttamente",icon:"success"}).then(function() { 
          window.location.href ='impostazioni?tab=brands'})}}
              
      
      ).catch(function(error)
      {
        Swal.fire({ title:"", text:"Impossibile eliminare il brand selezionato",icon:"error"})
      });
      //return result;
  }
  
class Brands extends React.Component {
  state = { 
    openModal : false,
    isLoading: true,
    initialBrands: [],
    brands: [],
    inputValue: "",
    error: null
  };

onClickButton = () =>{
    //e.preventDefault()
    this.setState({openModal : true})
}

onCloseModal = ()=>{
    this.setState({openModal : false})
}
  fetchBrands() {
    fetch(process.env.REACT_APP_BASE_URL + 'brands')
      .then(response => response.json())
      .then(data =>
        this.setState({
          initialBrands: data,
          isLoading: false
        })
      )
      .catch(error => this.setState({ error, isLoading: false }));
  }
  
  componentDidMount() {
    this.fetchBrands();
  }

  onChangeHandler(e) {
    let newArray = this.state.initialBrands.filter(d => {
      let searchValue = d.nome.toLowerCase();
      return searchValue.indexOf(e.target.value) !== -1;
    });
    this.setState({
      brands: newArray,
      inputValue: e.target.value
    });
  }
  
  render() {
    const {
      isLoading,
      brands,
      initialBrands,
      error,
      inputValue
    } = this.state;
    return (
      <div class="app-dati"> 
      <SectionSubtitle text="Gestisci i brand con cui la tua organizzazione collabora." />
        <div style={{width:"100%", display:"block", textAlign:"right", marginTop:"1rem", marginBottom:"1rem"}}>
        <input 
        className = "btn rounded-3 cerca"
          type="text"
          value={this.state.inputValue}
          placeholder="Cerca"
          onChange={this.onChangeHandler.bind(this)}
        />
       <a href="impostazioni?tab=brands&amp;action=aggiunta" className='btn btn-primary rounded-3'>+ Aggiungi</a>  
        </div>
        {error ? <p>{error.message}</p> : null}
        <Fragment>
       <Row>
          {!isLoading ? (
            (brands.length !== 0 || !!inputValue ? brands : initialBrands).map(
              brand => {
                const { logo, nome, id } = brand;
                return (
                    <Col item key={id} xl="3" md= "4" sm="6" sx="6">
              			<Card className="card2 rounded-3">
                      <Dropdown>			
                        <Dropdown.Toggle className="action-on-img">
                        <i className="bi bi-three-dots-vertical"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="brand-image-menu">
                        <Dropdown.Item className="dropitem">
                        <Link className="linkvar"  onClick={ () => { itemBrand = brand; }} to={() => { return "impostazioni?tab=brands&action=modifica"  }} >
                          Modifica
                        </Link>
                      </Dropdown.Item>
                      <Dropdown.Item className="dropitem">  
                        <Link to="#" className="linkvar"  
                          onClick={() =>
                            Swal.fire({
                              title: "Confermi?",
                              html:
                                "Attenzione, il brand <b>" + nome + "</b> sarà eliminato",
                                imageUrl: process.env.REACT_APP_BASE_URL_CONTENTS + logo ,
                                imageHeight: 100,
                                showCancelButton: true,
                                confirmButtonText: 'Elimina Brand',
                                cancelButtonText: 'Annulla',
                                reverseButtons: true,
                                icon:'question'
                              }).then((result) => {
                              if (result.isConfirmed) {
                                deleteBrand(brand);
                              } else if (
                                /* Read more about handling dismissals below */
                                result.dismiss === Swal.DismissReason.cancel
                              ) {
                                Swal.fire({text :  'Operazione annullata', icon : 'error'})
                              }
                            })}
                          >
                          Elimina
                        </Link>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
          			  <Card.Body>
			            <img
                    className="brand-image"
                    src= {process.env.REACT_APP_BASE_URL_CONTENTS + logo} 
                    alt=""
            	    />
        			  </Card.Body>
			        </Card>
		        </Col>
            );
          }
        )
      ) : (
        <h3>Loading...</h3>
      )}
   		</Row>
	  </Fragment>
      </div>
    );
  }
}
export {
  Brands as default,
  itemBrand as array
}
