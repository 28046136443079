import { MDBDataTableV5 } from "mdbreact";
import { useEffect, useState } from "react";
import dateFormat from '../functions/dateFormat';
import { Link } from "react-router-dom";
import Swal from 'sweetalert2'
export let itemIniziativa = []
function Iniziative() {
  let templates = ['Predefinito','Promo','Evidenza Sinistro','Evidenza Destro','Evidenza Alto','Evidenza Basso'];
  const [currentUser] = useState(JSON.parse(localStorage.getItem('userDetails','undefined')))
  //const [items, setItems] = useState([]);
  const [iniziative, setIniziative] = useState([]);
  /* eslint-disable */
  const getIniziative = () => {
    fetch(process.env.REACT_APP_BASE_URL + "iniziative")
      .then((res) => res.json())
      .then((res) => {
        setIniziative([]);
        if (res.length > 0)
        {
          let iniziativeData = [];
          res.map((item) => {
            
              item.datacreazione = dateFormat(new Date(item.data_creazione))
              item.templatenome = templates[item.template]
              item.action = ( <div style = {{ display: "flex", justifyContent: "space-between", width:"60px" }} >
          
              <Link hidden={currentUser.tipo > 2} className="c-pointer button-action" onClick={ () => { itemIniziativa = item; }} to={() => { return "palinsesti?tab=iniziative&action=modifica" }} ><i className="bi bi-pencil-fill"></i></Link>
              &nbsp;&nbsp;&nbsp;
              <Link hidden={currentUser.tipo > 2} className="c-pointer button-action" to='#' onClick={() =>{ clickButton(item);} }><i className="bi bi-trash-fill"></i></Link></div>)
          
          iniziativeData.push(item)});
          setIniziative(iniziativeData);              
        }
        else
        {
          setIniziative([{uuid: "0",nome: "Nessuna iniziativa presente!"}]);
        }
    });
  }
  useEffect(() => {
    getIniziative(); 
  }, []);
  const deleteIniziativa = (item) =>{
    const dataDel = {};
    dataDel['id'] = item.uuid;
    fetch(process.env.REACT_APP_BASE_URL + 'elimina-iniziativa', { method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(dataDel)
    }).then(function(response) {
      if (response.status === 200) {
        Swal.fire({ title:"", text:"Operazione eseguita correttamente",icon:"success"}).then(function() { 
          getIniziative();
        })}}    
      
      ).catch(function(error)
      {
        Swal.fire({ title:"", text:"Impossibile eliminare il iniziativa selezionato",icon:"error"})
      });
     
  }
  const clickButton = (item) => {
      Swal.fire({title: "Confermi?", html: "Attenzione, l'iniziativa <b> " + item.nome + "</b> sarà eliminata!</div>", showCancelButton: true, confirmButtonText: 'Elimina', cancelButtonText: 'Annulla',  reverseButtons: true, icon:'question'}).then((result) => {
      if (result.isConfirmed) {
        deleteIniziativa(item)
      } else if (
        result.dismiss === Swal.DismissReason.cancel
      ) {
        Swal.fire({
          text: 'Operazione annullata',
          icon: 'info'}
        )
      }
    });
  }
  setTimeout(() => {
    var buttons = document.getElementsByClassName('page-link')
      if (buttons.length > 0)
      {
      
        for (var i=0; i < buttons.length; i++) {
          buttons[i].onclick = function(){
        
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth"
            });
          }
        };
      }
  }, 1000);

  const data = {
    columns: [
      {
        label: "",
        field: "",
        sort : "disabled",
        width: "10"
    },
        {
            label: "Nome",
            field: "nome",
            sort: "asc",
            width: 270,
        },

        {
            label: "Categoria",
            field: "nome_categoria",
            sort: "asc",
            width: 200,
        },
        {
            label: "Template",
            field: "templatenome",
            sort: "asc",
            width: 200,
        },
        
        {
          label: "Creato il",
          field: "datacreazione",
          sort: "asc",
          width: 150,
        },
        {
          label: "Azione",
          field: "action",
          sort: "disabled",
          width: 150,
        }
    ],
      rows: iniziative

  };

    return ( 
      <div className = "tabella_iniziative">
 
      <p className="subtab">Gestisci le tue iniziative</p>
      <a hidden={currentUser.tipo > 2} href="palinsesti?tab=iniziative&amp;action=aggiunta" className='aggiungi'>+ Aggiungi</a>  
      <MDBDataTableV5 hover data={data} proSelect searchTop searchBottom={false} fullPagination searchLabel={'Cerca'} responsive disableRetreatAfterSorting={true} />
      
      </div>
    );
}

export default Iniziative;



