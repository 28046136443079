import {useState} from "react";
import {Tabs, Tab} from "react-bootstrap";
import {useHistory} from "react-router-dom";
import {default as UtentiAttivi} from "./utenti-attivi";
import {default as UtentiNonAttivi} from "./utenti-non-attivi";
import {default as UtentiDisattivati} from "./utenti-disattivati";
import {default as ModificaUtenteAttivo} from "./modifica-utente-attivo"
import {default as NuovoUtenteAttivo} from "./nuovo-utente-attivo"
import getQueryVariable from "../functions/getQueryVariable";

  function Amministratori() {
    const [currentUser] = useState(JSON.parse(localStorage.getItem('userDetails','undefined')));
    const [key, setKey] = useState('utenti-attivi');
    const history = useHistory();
    const tab = getQueryVariable('tab');
    const action = getQueryVariable('action');
    let titolo = "Utenti"
    if (action === "modifica"){
      titolo = "Utenti > Modifica"
    }
    if (action === "aggiunta"){
      titolo = "Utenti > Aggiunta"
    }
    let mUtentiAttivi =<UtentiAttivi currentUser={currentUser} />
    if (tab ==="utenti-attivi" && action === "modifica"){
      mUtentiAttivi= <ModificaUtenteAttivo currentUser={currentUser} />
    }
    if (tab ==="utenti-attivi" && action === "aggiunta"){
      mUtentiAttivi= <NuovoUtenteAttivo currentUser={currentUser} />
    }
    return (
      <><h2 className="tab-menu">{titolo}</h2>
      <Tabs id="controlled-utenti" activeKey={key} onSelect={(k) => { history.push('/utenti?tab=' + k);  setKey(k); }} className="mb-3 h4 custom-tab-1">
        <Tab eventKey="utenti-attivi" title="attivi">
          {mUtentiAttivi}
        </Tab>
        <Tab eventKey="utenti-non-attivi" title="non attivi">
          <UtentiNonAttivi currentUser={currentUser} />
        </Tab>
        <Tab eventKey="utenti-disattivati" title="disattivati">
          <UtentiDisattivati currentUser={currentUser} />
        </Tab>
      </Tabs>
      </>
    );
  }
  export default Amministratori;