import { MDBDataTableV5 } from "mdbreact";
import { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import PosLicenze from "./pos-licenze";
import CircleButtonComponent from "../components/Button/CircleButton";
import { Settings } from "@mui/icons-material";
function DatiLicenzeTabella(props) {
  const [refresh, setRefresh] = useState(true);
  const [items, setItems] = useState([]);
  const [dataItems, setDataItems] = useState([]);
  const [show, setShow] = useState(false);
  const [posLic, setPos] = useState();
  const handleClose = () => {
    setShow(false);
    setRefresh(false);
    props.onUpdateChart();
  };
  const handleShow = () => setShow(true);
  const fetchDataLicense = () => {
    try {
      fetch(process.env.REACT_APP_BASE_URL + "punti-vendita/1")
        .then((res) => res.json())
        .then((res) => {
          if (res.length === 0) {
            setItems([{ id: -1, nome: "Nessun punto vendita presente!" }]);
            setRefresh(true);

            return;
          }
          setItems(res);
          setRefresh(true);
        })
        .catch((error) => {
          setItems([{ id: -1, nome: "Nessun punto vendita presente!" }]);
        });
    } catch (error) {}
  };
  const showModal = (pos) => {
    setPos(pos);
    handleShow();
  };
  useEffect(() => {
    fetchDataLicense();
  }, [refresh]);
  /* eslint-disable */
  useEffect(() => {
    let itemsArray = items;
    let dataitems = [];
    itemsArray.map((pos) => {
      pos.action =
        pos.id < 1 ? (
          ""
        ) : (
          <CircleButtonComponent
            icon={<Settings />}
            onClick={() => {
              showModal(pos);
            }}
          />
        );
      pos.licensescount = pos.licenze
        ? pos.licenze.filter((l) => l).length
        : "";
      dataitems.push(pos);
    }, setDataItems(dataitems));
  }, [items]);
  setTimeout(() => {
    var buttons = document.getElementsByClassName("page-link");
    if (buttons.length > 0) {
      for (var i = 0; i < buttons.length; i++) {
        buttons[i].onclick = function () {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        };
      }
    }
  }, 1000);
  const data = {
    columns: [
      {
        label: "",
        field: "",
        sort: "disabled",
        width: "10",
      },
      {
        label: "Punto vendita",
        field: "nome",
        sort: "asc",
        width: 270,
      },
      {
        label: "Referente",
        field: "referente",
        sort: "asc",
        width: 200,
      },
      {
        label: "Licenze Assegnate",
        field: "licensescount",
        sort: "asc",
        width: 150,
      },
      {
        label: "Categoria",
        field: "categoria",
        sort: "asc",
        width: 200,
      },
      {
        label: "",
        field: "action",
        sort: "disabled",
        width: 70,
      },
    ],
    rows: dataItems,
  };
  return (
    <>
      <Modal
        dialogClassName="modal-messages"
        show={show}
        onRequestClose={handleClose}
      >
        <Modal.Body>
          <Button
            style={{ float: "right" }}
            variant="link"
            onClick={handleClose}
          >
            <i style={{ fontSize: "120%" }} className="bi bi-x"></i>
          </Button>
          <PosLicenze handleClose={handleClose} posLic={posLic} />
        </Modal.Body>
      </Modal>
      <MDBDataTableV5
        hover
        data={data}
        proSelect
        searchTop
        searchBottom={false}
        fullPagination
        searchLabel={"Cerca"}
        responsive
        disableRetreatAfterSorting={true}
      />
    </>
  );
}

export default DatiLicenzeTabella;
