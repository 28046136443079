import {React,useState, useEffect, useRef } from "react";
import { MDBDataTableV5 } from "mdbreact";
import Swal from "sweetalert2";
import PosLicNuove from "./pos-licnuove";
import { Button } from "react-bootstrap";
import axios from 'axios';
export function PosLicenze(props) {
  function handleClose () {props.handleClose();}
  const posLic = props.posLic
  const [licenses, setLicenses] = useState([]);
  const [dataLicenses, setDataLicenses] = useState([]);
  const licensesStatus = ["inattiva","attiva","disattivata","scaduta"];
  const licSection = useRef();
  const newSection = useRef();
  const fetchDataLicense = (v = -1) => {
    fetch(process.env.REACT_APP_BASE_URL + "licenze/"+ posLic.id )
    .then((res) => res.json())
    .then((res) => {
      let Lic = [];
      let LicCat = "";
      if (res.length === 0 )  
      {
        setLicenses([{id: -1,nome: "Nessuna licenza presente per il punto vendita " + posLic.nome + "!"}]);
        return;
      }

      if (v < 0)
      {
        Lic = res;
        
      }
      else if (v === 0)
      {
        Lic = res.filter((l) => l.status === 0);
        LicCat = "scaduta";
      }
      else if (v === 1)
      {
        Lic = res.filter((l) => l.status === 1);
      }
      else if (v > 1)
      {
        LicCat = "attiva";
        Lic = res.filter((l) => l.status > 1);
      }
      if (Lic.length >0)
      {
        LicCat = "non ancora attivata";
        setLicenses(Lic);
        return;
      }
      setLicenses([{id: -1,nome: "Nessuna licenza " + LicCat + " presente per il punto vendita " + posLic.nome + "!"}]);
      
    });
  }
  const filterList = (v) =>
  {
    fetchDataLicense(v);
  }
  const cleanLicense = () =>
  {
    if (licenses.filter((l) => l.status > 1).length === 0)
    {
      Swal.fire({title : "", text : "Non ci sono licenze disattivate o scadute da eliminare", showCancelButton: false, confirmButtonText: 'OK', icon : "info"}).then(result => {  
      });
      return;
    }
    Swal.fire({title : "", text : "Eliminare le licenze disattivate e scadute?", showCancelButton: true, confirmButtonText: 'Si', cancelButtonText: 'No', icon : "question"}).then(result => {
      if (result.isConfirmed) {axios.post(process.env.REACT_APP_BASE_URL + "reset-licenze").then(res => {Swal.fire({title:"",text:"Licenze scadute eliminate!",icon : "success"}); fetchDataLicense(); }).catch((err) => {Swal.fire({title:"",text:"Errore eliminazione licenze scadute",icon : "warning"})});}
    });
  }
  const disableLicense = (lic) =>
  {
    Swal.fire({title : "", text : "Disattivare la licenza " + lic.codice + "?", showCancelButton: true, confirmButtonText: 'Si', cancelButtonText: 'No', icon : "question"}).then(result => {
      if (result.isConfirmed) {
        lic.status = 2;
        axios.post(process.env.REACT_APP_BASE_URL + "modifica-licenza", lic).then(res => {Swal.fire({title:"",text:"Licenza disattivata!",icon : "success"}); fetchDataLicense();}).catch((err) => {Swal.fire({title:"",text:"Errore eliminazione licenze scadute",icon : "warning"})});
      }
    });
  }
  /* eslint-disable */
  useEffect(() => {
     fetchDataLicense(); 
  }, []);
  useEffect(() => {
    let itemsArray = licenses;
    let dataitems = [];
    /* eslint-disable */
    itemsArray.map((license) => {
      
      license.statuslic = licensesStatus[license.status]; 
      let licExp = license.status > 0 ? new Date(license.data_scadenza).toLocaleDateString('it', {day: '2-digit', month: '2-digit', year: 'numeric'  }) : "N/A";
      license.scadenza = licExp.includes("1970") ? "N/A" : licExp;
      license.action = <Button hidden={!license.codice} disabled={license.status > 1} variant="primary" onClick={(e) => { e.preventDefault(); disableLicense(license); }}>Disattiva</Button>; dataitems.push(license)}, setDataLicenses(dataitems));  
    
 }, [licenses]);
  setTimeout(() => {
    var buttons = document.getElementsByClassName('page-link')
    if (buttons.length > 0)
    {
    
      for (var i=0; i < buttons.length; i++) {
        buttons[i].onclick = function(){
      
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          });
        }
      };
    }
}, 1000);
  const data = {
    columns: [
      {
        label: "",
        field: "",
        sort : "disabled",
        width: "10"
    },
        {
            label: "Codice",
            field: "codice",
            sort: "asc",
            width: 270,
        },
        {
            label: "Scadenza",
            field: "scadenza",
            sort: "asc",
            width: 200,
        },  
        {
          label: "Stato",
          field: "statuslic",
          sort: "asc",
          width: 150,
        },
        {
            label: "",
            field: "action",
            sort : "disabled",
            width: 70,
        }
    ],
    rows: dataLicenses
  };
  return(
    <div>
      <div ref={licSection} className="gestione-licenze">
        <h4>Gestione Licenze &gt; {posLic.nome}</h4>
        <button className='aggiungi' onClick={()=>{ licSection.current.hidden=true; newSection.current.hidden=false; }} >+ Licenze</button>  
        <div style={{minHeight : "500px"}}>
          <MDBDataTableV5 entries={5} hover data={data} proSelect searchTop searchBottom={false} fullPagination searchLabel={'Cerca'} responsive disableRetreatAfterSorting={true} />   
        </div>
        <div style={{textAlign:"left", padding:"10px"}}>
          <input type="radio" onChange={() => { filterList(-1); }} defaultChecked={true} name="licenze"/><label for="licenze" style={{paddingLeft:"6px",paddingRight:"10px"}} >Tutte</label>
          <input type="radio" onChange={() => { filterList(3); }} name="licenze"/><label for="licenze" style={{paddingLeft:"6px",paddingRight:"10px"}} >Scadute</label>
          <input type="radio" onChange={() => { filterList(1); }} name="licenze"/><label for="licenze" style={{paddingLeft:"6px",paddingRight:"10px"}}>Attive</label>
          <input type="radio" onChange={() => { filterList(0); }} name="licenze"/><label for="licenze" style={{paddingLeft:"6px",paddingRight:"10px"}}>Inattive</label>
          <Button onClick={()=>{ handleClose(); }} style={{float : "right", marginLeft:"10px"}} >Chiudi</Button>      
          <Button onClick={()=>{ cleanLicense(); }} style={{float : "right"}} >Elimina Scadute e Disattivate</Button>      
          
        </div>
        <div style={{textAlign:"right", padding:"10px"}}>
        
        </div>
      </div> 
      <div hidden={true} ref={newSection} className="gestione-licenze">
        <PosLicNuove handleClose={handleClose} posLic={posLic} />        
      </div> 
    </div>
    
)};
export default PosLicenze
           

