import React, { Fragment } from "react";
import { Button} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
export function NuovaZona() {
 
  const history = useHistory();
  const handleSubmit = event => {
    event.preventDefault();
   
    const elements = event.target.elements;
    const data = {};
    for (let i=0; i < elements.length; i++) {
        const elem = elements[i];
        data[elem.name] = elem.value
    }
   
      fetch(process.env.REACT_APP_BASE_URL + 'nuova-zona/', { method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(data)
      }).then(function(response) {
          if (response.status === 200) {
              //let responseText = JSON.stringify(response.text());
              swal("", "Operazione eseguita correttamente", "success")
              history.push('impostazioni')
          }
            else {throw new Error(response.status);}
          }).catch(function(error)
          {

          });
  }
  
  return(
   <>
<div className="card">
  
    <p className="pform">Nuova zona</p>
   
  <div className="card-bodyx">
      
      <form onSubmit={handleSubmit}>
        <div className="form-row">
              <label className="form-label">NOME</label>
              <input className="form-input" type="text" name="nome" required={true} />
        </div>
        <div className="form-row">
              <label className="form-label">DESCRIZIONE</label>
              <input className="form-input" type="text" name="descrizione" required={true} />
        </div>
          <div className="form-row">
          
            <div className="form-buttons-right">
                <Button className="btn btn-outline-primary rounded-1 btn-sm" type="submit">Invia</Button>
                <Button className="btn btn-outline-primary rounded-1 btn-sm" type="button" onClick={() => {history.push('impostazioni');}}>Annulla </Button>
            </div>
            </div>
          </form>
    </div>
</div>
     </>
    
    
    )}

export default NuovaZona

