import axios from 'axios';

async function getMessages(idelement)
{
  let unreaded = "";
  try
  {
    let userData = localStorage.getItem('userDetails','undefined');
    if (localStorage.length === 0) { return; }
    if (userData !== 'undefined')
    {
      const postData = { userData };
      return await axios.post(process.env.REACT_APP_BASE_URL + "notificamessaggi", postData)
      .then((res) => {
      if (res.status === 200) {
        try {
          unreaded = res.request.response;
          var messages = document.getElementById(idelement);
          if (unreaded === "0"){unreaded = "";}
          if (messages !== null){messages.innerHTML=unreaded;}
          if (unreaded !== "")
          {
            return unreaded;
          }
          else
          {
            return false;
          }
        }
        catch (e) { document.getElementById(idelement).innerHTML=""; }
      }
      else {try {document.getElementById(idelement).innerHTML="";}catch (e) {}}});
    }
    else
    {
      try{
        unreaded = ""; 
        document.getElementById(idelement).innerHTML=unreaded; 
      }catch (error)
      {}
    }
  }
  catch (e){}
  return false;
}
export default getMessages