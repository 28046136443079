import { MDBDataTableV5 } from "mdbreact";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import SectionSubtitle from "../components/Subtitles";
import { Add, Delete, Edit } from "@mui/icons-material";
import CircleButtonComponent from "../components/Button/CircleButton";
//import {default as EliminaTag} from "./elimina-tag";
//import { ItemMeta } from "semantic-ui-react";
export let itemZone = [];
function Zone() {
  const [items, setItems] = useState([]);
  const [usersForRender, setUsersForRender] = useState([]);
  const getData = () => {
    fetch(process.env.REACT_APP_BASE_URL + "zone")
      .then((res) => res.json())
      .then((res) => {
        if (res.length === 0) {
          setItems([{ id: -1, nome: "Nessuna zona presente!" }]);
          return;
        }
        setItems(res);
      });
  };
  useEffect(() => {
    getData();
  }, []);
  setTimeout(() => {
    var buttons = document.getElementsByClassName("page-link");
    if (buttons.length > 0) {
      for (var i = 0; i < buttons.length; i++) {
        buttons[i].onclick = function () {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        };
      }
    }
  }, 1000);
  /* eslint-disable */
  useEffect(() => {
    let itemsArray = JSON.parse(JSON.stringify(items));
    let tagData = [];
    function deleteZona(id_Zona) {
      const dataDel = {};
      dataDel["id"] = id_Zona;
      fetch(process.env.REACT_APP_BASE_URL + "elimina-zona", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(dataDel),
      })
        .then(function (response) {
          if (response.status === 200) {
            Swal.fire({
              title: "",
              text: "Operazione eseguita correttamente",
              icon: "success",
            }).then(function () {
              getData();
            });
          }
        })
        .catch(function (error) {
          Swal.fire({
            title: "",
            text: "Impossibile eliminare il tag selezionato",
            icon: "error",
          });
        });
    }
    itemsArray.map((item) => {
      if (item.id > 0) {
        item.action = (
          <div style={{ display: "flex" }}>
            <CircleButtonComponent>
              <Link
                className="c-pointer button-action"
                onClick={() => {
                  itemZone = item;
                }}
                to={() => {
                  return "impostazioni?tab=zone&action=modifica";
                }}
              >
                <Edit sx={{ color: "white" }} />
              </Link>
            </CircleButtonComponent>
            <CircleButtonComponent
              icon={<Delete />}
              onClick={() =>
                Swal.fire({
                  title: "Confermi?",
                  html:
                    "<div style='padding:10px;'> Attenzione, la zona <b> " +
                    item.nome +
                    "</b> sarà eliminata!</div>",
                  showCancelButton: true,
                  confirmButtonText: "Elimina",
                  cancelButtonText: "Annulla",
                  reverseButtons: true,
                  icon: "question",
                }).then((result) => {
                  if (result.isConfirmed) {
                    deleteZona(item.id);
                  } else if (
                    /* Read more about handling dismissals below */
                    result.dismiss === Swal.DismissReason.cancel
                  ) {
                    Swal.fire("Operazione annullata");
                  }
                })
              }
            />
          </div>
        );
      }
      tagData.push(item);
    });
    setUsersForRender(tagData);
  }, [items]);

  const data = {
    columns: [
      {
        label: "",
        field: "",
        sort: "disabled",
        width: "10",
      },
      {
        label: "Nome",
        field: "nome",
        sort: "asc",
        width: 270,
      },

      {
        label: "Descrizione",
        field: "descrizione",
        sort: "disabled",
        width: 270,
      },

      {
        label: "Azione",
        field: "action",
        sort: "disabled",
        width: 150,
      },
    ],
    rows: usersForRender,
  };
  return (
    <div className="tabella_zone">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "32px",
        }}
      >
        <SectionSubtitle text="Aggiungi o elimina le zone di vendita." />
        <CircleButtonComponent
          icon={<Add />}
          onClick={() =>
            (window.location.href = "impostazioni?tab=zone&action=aggiunta")
          }
        />
      </div>
      <MDBDataTableV5
        hover
        data={data}
        proSelect
        searchTop
        searchBottom={false}
        fullPagination
        searchLabel={"Ricerca zona ..."}
        responsive
        disableRetreatAfterSorting={true}
      />{" "}
    </div>
  );
}
export { Zone as default, itemZone as array };
