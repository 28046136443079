import { useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { default as PalinsestiSub } from "./palinsesti-sub";
import { default as Iniziative } from "./iniziative";
import { default as NuovoPalinsesto } from "./nuovo-palinsesto";
import { default as ModificaPalinsesto } from "./modifica-palinsesto";
import { default as NuovaIniziativa } from "./nuova-iniziativa";
import { default as ModificaIniziativa } from "./modifica-iniziativa";
import { useHistory } from "react-router-dom";
import getQueryVariable from "../functions/getQueryVariable";
import PalinsestiModal from "./palinsesti-modal";
import CustomHeader from "../components/Header/CustomHeader";
import CircleButtonComponent from "../components/Button/CircleButton";
import { Help } from "@mui/icons-material";

function Palinsesti() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [buttonPressed, setButtonPressed] = useState(false);

  const handleButtonClick = () => {
    setIsModalOpen(true);
    setButtonPressed(true);
  };

  const tab = getQueryVariable("tab");
  const action = getQueryVariable("action");
  let titolo = "Palinsesti e Iniziative";
  if (tab === "palinsesti-sub" && action === "modifica") {
    titolo = "Palinsesti > Modifica";
  }
  if (tab === "iniziative" && action === "modifica") {
    titolo = "Iniziative > Modifica";
  }
  if (tab === "palinsesti-sub" && action === "aggiunta") {
    titolo = "Palinsesti > Aggiunta";
  }
  if (tab === "iniziative" && action === "aggiunta") {
    titolo = "Iniziative > Aggiunta";
  }
  let palinsestisub = <PalinsestiSub />;
  if (tab === "palinsesti-sub" && action === "modifica") {
    palinsestisub = <ModificaPalinsesto />;
  }
  if (tab === "palinsesti-sub" && action === "aggiunta") {
    palinsestisub = <NuovoPalinsesto />;
  }

  let iniziative = <Iniziative />;
  if (tab === "iniziative" && action === "modifica") {
    iniziative = <ModificaIniziativa />;
  }
  if (tab === "iniziative" && action === "aggiunta") {
    iniziative = <NuovaIniziativa />;
  }

  const [key, setKey] = useState(tab);
  const history = useHistory();
  return (
    <>
      <CustomHeader title={titolo}>
        <CircleButtonComponent
          text="Guida"
          icon={<Help />}
          onClick={() => setIsModalOpen(true)}
        />
      </CustomHeader>
      {isModalOpen && (
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="favoritesModalLabel">
                Guida Euracom Palinsesti
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                style={{ fontSize: "0.8rem" }}
                aria-label="Close"
                onClick={() => setIsModalOpen(false)}
              ></button>
            </div>
            <div className="modal-body" id="summaryModalBody">
              <iframe
                title="Guida applicazione web"
                src={"GuidaEuracomPalinsesti.pdf"}
                width="96%"
                height={window.innerHeight * 0.7}
                style={{ display: "block", margin: "auto" }}
              ></iframe>
            </div>
          </div>
        </div>
      )}

      {palinsestisub}
    </>
  );
}

export default Palinsesti;
