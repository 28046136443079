import React from "react";
import { MDBDataTableV5 } from "mdbreact";
import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Swal from 'sweetalert2'
import axios from 'axios';
import { Modal, Button } from "react-bootstrap";
import Messaggio from "./messaggio";
let filterMail = 0;
export function MessaggiInviati(props) {
  const [currentUser] = useState(props.currentUser);
  const [models] = useState(props.models);
  const [items, setItems] = useState([]);
  const [usersForRender, setUsersForRender] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [messageData, setMessageData] = useState({});
  const [titleComponent, setTitleComponent] = useState("Nuovo Messaggio");
  const getData = () => {
    if (localStorage.length === 0) { return; }
    if(show) {return;}
    
    if (currentUser !== 'undefined')
    {
      try
      {
        const url = "read-outbox";
        let user = JSON.stringify(currentUser);
        const postData = { userData: user };
        axios.post(process.env.REACT_APP_BASE_URL + url, postData)
        .then((res) => {
          if (res.status === 200)
            {
              let filtered = [];
              let itemsResults = JSON.parse(res.request.response);
              if (filterMail === 1)
              {
                filtered = itemsResults.filter((i) => {return i.read === 0;});
              }
              else if (filterMail === 2)
              {
                filtered =  itemsResults.filter((i) => {return i.license === 1;});
              }
              else if (filterMail === 3)
              {
                filtered = itemsResults.filter((i) => { return i.priority === 1;});
              }
              else
              {
                filtered =  itemsResults;
              }
              if (filtered.length === 0 )  
              {
                setItems([{id: -1,emailfrom: "Nessun messaggio presente!"}]);
                return;
              }
              setItems(filtered);
        }});
       

       
      }
      catch (error){}
    }
  }
  /* eslint-disable */
  useEffect(getData,[]);
  const updateRef2 = useRef(null);
  useEffect(() => {
    updateRef2.current = setInterval(() => {
      getData();
    }, 30000);
    return () => {
      clearInterval(updateRef2.current);
    }
  }, []);
  
  setTimeout(() => {
    var buttons = document.getElementsByClassName('page-link')
    if (buttons.length > 0)
    {
    
      for (var i=0; i < buttons.length; i++) {
        buttons[i].onclick = function(){
      
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          });
        }
      };
    }
}, 1000);

function filtraLicenze()
{
  
  filterMail = 2;
  setItems([]);
  getData();
}
function filtraPriority()
{
  filterMail = 3;
  setItems([]);
  getData();
}
function aggiornaTutto()
{
  filterMail = 0;
  setItems([]);
  getData();
}
  function leggiMessaggio(item)
  {
    getData();
    Swal.fire({
      html: "<div class='dettagli-messaggio-swal'><div class='dettagli-oggetto'><p><b>" 
      + item.subject + "</b></p><p class='dettagli-data'>"
      + item.dtsent + "</p></div><p class='dettagli-from'>"
      + item.to + " <span>&lt;" 
      + item.toaddress + "&gt;</span></p><div class='dettagli-body'>" 
      + item.body + "</div></div>",
      showCancelButton: true,
      confirmButtonText: '<i class="fa fa-reply" aria-hidden="true"></i> &nbsp; INOLTRA',
      cancelButtonText: '<i class="fa fa-times" aria-hidden="true"></i> &nbsp; CHIUDI',
      reverseButtons: false,
      customClass: 'swall-messaggi'
      }).then((result) => { 
        getData();
        if (result.isConfirmed) { inoltraMessaggio(item);} });
    }
function nuovoMessaggio()
{
  setTitleComponent("Nuovo messaggio");
  setMessageData({ to : "", toaddress : "", body : "<br>" + models[0].messaggio, subject : "", isresponse : false  });
  handleShow();
}


function inoltraMessaggio(item)
{
  setTitleComponent("Inoltra il messaggio");
  setMessageData({ to : item.from, toaddress : item.fromaddress, body : "<br>" + models[0].messaggio + "<br>---------------------------------------<br> Il " + item.dtsent+ " " +item.from+ " <" + item.fromaddress + "> ha scritto:<br>" + item.body, subject : "I:" + item.subject, isresponse : false  });
  handleShow();
}
const eliminaMessaggio = (item) =>{
    const dataDel = {};
    dataDel['idemail'] = item.id;
    fetch(process.env.REACT_APP_BASE_URL + 'delete-outbox', { method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify(dataDel)}).then(function(response) { if (response.status === 200) {Swal.fire({ title:"", text:"Operazione eseguita correttamente",icon:"success"}).then(function() { getData()})}}).catch(function(error){Swal.fire({ title:"", text:"Impossibile eliminare il tag selezionato",icon:"error"})});
}
useEffect(() => {
  let itemsArray = items;
  let itemData = [];
  itemsArray.map((item, index) => {
    if (item.id > 0){
      let rowitemspan="row-email-item-0";
      if (item.read===1){rowitemspan="row-email-item-1";}
      let hidenew = false
      let hideread= true
      if (item.read === 1){
        rowitemspan="row-email-item-1";
        hidenew = true;
        hideread= false}
        item.readstate = <><span style = {{padding:'10px 0 10px 10px'}}>
        <i className="bi bi-envelope-fill" hidden={hidenew}></i>
        <i className="bi bi-envelope-open" hidden={hideread}></i>
        </span>
        <span style = {{padding:'10px 0 10px 10px'}}>
        <i hidden={item.priority === 1}></i>
        <i style={{color:'red',fontSize:'120%'}} className="bi bi-exclamation" hidden={item.priority !== 1}></i>
        </span></>
 
        item.emailfrom = ( <Link className="c-pointer row-email"  to="#" onClick={() =>{ leggiMessaggio(item); }}><span className={rowitemspan}>{item.to}<br />{item.toaddress}</span></Link>);
        item.subjectfrom = ( <Link className="c-pointer row-email"  to="#" onClick={() =>{ leggiMessaggio(item); }}><span className={rowitemspan}>{item.subject}</span></Link>);
        item.datefrom = ( <Link className="c-pointer row-email"  to="#" onClick={() =>{ leggiMessaggio(item); }}><span className={rowitemspan}>{item.dtsent}</span></Link>);
        item.action = ( <div style = {{ display: "flex", justifyContent: "space-between" }}>
        <Link className="c-pointer button-action" to="#" onClick={() =>{ inoltraMessaggio(item); }}>
        <i className="bi bi-forward-fill"></i>
        </Link>&nbsp;&nbsp;&nbsp;<Link to="#" className="c-pointer button-action"  onClick={() => Swal.fire({
                      title: "Confermi?",
                      html: "<div style='padding:10px;'> Attenzione, il messaggio<b> " + item.subject + "</b> sarà eliminato!</div>",
                      showCancelButton: true,
                      confirmButtonText: 'Elimina',
                      cancelButtonText: 'Annulla',
                      reverseButtons: true,
                      icon:'question'
                    }).then((result) => {if (result.isConfirmed) { eliminaMessaggio(item) } else if ( result.dismiss === Swal.DismissReason.cancel ) { Swal.fire('Operazione annullata')}})}>
          <i className="bi bi-trash-fill"></i> 
          </Link> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
  );}
  itemData.push(item);
});
setUsersForRender(itemData);
}, [items]);
 
const data = {
columns: [
{
  label: "",
  field: "readstate",
  sort: "disabled",
  width: 30,
},
{
  label: "",
  field: "emailfrom",
  sort: "disabled",
  width: 270,
},

{
  label: "",
  field: "subjectfrom",
  sort: "disabled",
  width: 270,
},
{
  label: "",
  field: "datefrom",
  sort: "disabled",
  width: 270,
},
{
  label: "",
  field: "action",
  sort: "disabled",
  width: 150,
},
],
rows: usersForRender};
return ( <div className = "tabella_messaggi">
  <p className="subtab">&nbsp;</p>
      <button title='riavvia' onClick={() =>{ aggiornaTutto() }} className='bt4'><i className="bi bi-arrow-clockwise"></i></button>  
      <button title='Richiesta licenze' onClick={() =>{ filtraLicenze() }} className='bt3'><i className="bi bi-bookmark-fill"></i></button>  
      <button title='Priorit&agrave; alta' onClick={() =>{ filtraPriority() }} className='bt2'><i className="bi bi-exclamation-square-fill"></i></button> 
      <button title='scrivi' onClick={nuovoMessaggio} className='bt1'><i className="bi bi-pencil-square"></i></button>  
      <Modal dialogClassName="modal-messages" enforceFocus={false} show={show} onRequestClose={handleClose}>
          <Modal.Body>
              <Button style={{float:'right'}} variant="link" onClick={handleClose}><i style={{fontSize:'120%'}} className="bi bi-x"></i></Button>
              <Messaggio onCloseModal={handleClose} message={messageData} title={titleComponent} />
          </Modal.Body>
        </Modal>
  <MDBDataTableV5 hover data={data} proSelect searchTop searchBottom={false} fullPagination searchLabel={'Ricerca messaggi ...'} responsive disableRetreatAfterSorting={true} /> </div>
  );
}			
export default MessaggiInviati



