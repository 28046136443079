import { MDBDataTableV5 } from "mdbreact";
import { useEffect, useState } from "react";
import dateFormat from "../functions/dateFormat";
import Swal from "sweetalert2";
import SectionSubtitle from "../components/Subtitles";
import CircleButtonComponent from "../components/Button/CircleButton";
import { PersonAdd } from "@mui/icons-material";

function UtentiDisattivati() {
  const [currentUser] = useState(
    JSON.parse(localStorage.getItem("userDetails", "undefined"))
  );
  const [items, setItems] = useState([]);
  const [usersForRender, setUsersForRender] = useState([]);

  const fetchUtenti = () => {
    try {
      fetch(process.env.REACT_APP_BASE_URL + "clienti")
        .then((res) => res.json())
        .then((res) => {
          if (res.length === 0) {
            setItems([{ id: -1, nome: "Nessun utente disattivato presente!" }]);
            return;
          }
          setItems(res.filter((u) => u.status === 2 && u.tipo === 2));
        });
    } catch (error) {}
  };

  useEffect(() => {
    fetchUtenti();
  }, []);

  setTimeout(() => {
    var buttons = document.getElementsByClassName("page-link");
    if (buttons.length > 0) {
      for (var i = 0; i < buttons.length; i++) {
        buttons[i].onclick = function () {
          fetchUtenti();
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        };
      }
    }
  }, 1000);

  const userReactivate = (item) => {
    item.status = 1;
    let data = { status: item.status, id: item.id };
    fetch(process.env.REACT_APP_BASE_URL + "modifica-stato-utente/", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then(function (response) {
        if (response.status === 200) {
          Swal.fire(
            "",
            "Utente " + item.nome + " " + item.cognome + " riattivato!",
            "success"
          );
          fetchUtenti();
        } else {
          throw new Error(response.status);
        }
      })
      .catch(function (error) {
        Swal.fire(
          "",
          "Impossibile riattivare l'utente " +
            item.nome +
            " " +
            item.cognome +
            "!",
          "error"
        );
      });
  };
  /* eslint-disable */
  useEffect(() => {
    let itemsArray = JSON.parse(JSON.stringify(items));
    let userData = [];

    let TipoUtente = function (tipo) {
      if (tipo === 1) {
        return "Amministratore";
      } else if (tipo === 2) {
        return "Operatore";
      } else if (tipo === 3) {
        return "Cliente";
      }
    };
    // eslint-disable-next-line array-callback-return
    itemsArray.map((item) => {
      if (item.id > 0 && item.id !== currentUser.userid) {
        item.nometipo = TipoUtente(item.tipo);
        if (item.status === 2) {
          item.dataora = dateFormat(new Date(item.ultimo_aggiornamento));
          item.action = (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <CircleButtonComponent
                icon={<PersonAdd />}
                onClick={() => userReactivate(item)}
              />
            </div>
          );
        }
        userData.push(item);
      }
    });
    if (userData.length > 0) {
      setUsersForRender(userData);
    } else {
      setUsersForRender([{ id: -1, nome: "Nessun utente disattivato!" }]);
    }
  }, [items]);

  const data = {
    columns: [
      {
        label: "",
        field: "",
        sort: "disabled",
        width: "10",
      },
      {
        label: "Nome",
        field: "nome",
        sort: "asc",
        width: 270,
      },

      {
        label: "Cognome",
        field: "cognome",
        sort: "asc",
        width: 200,
      },
      {
        label: "Email",
        field: "email",
        sort: "asc",
        width: 200,
      },
      {
        label: "Ruolo",
        field: "nometipo",
        sort: "asc",
        width: 200,
      },

      {
        label: "Disattivato il",
        field: "dataora",
        sort: "asc",
        width: 150,
      },
      {
        label: "",
        field: "action",
        sort: "disabled",
        width: 70,
      },
    ],

    rows: usersForRender,
  };

  return (
    <div className="tabelladati">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "32px",
        }}
      >
        <SectionSubtitle text="Visualizza gli utenti che non fanno più parte della tua organizzazione." />
      </div>
      <MDBDataTableV5
        hover
        data={data}
        proSelect
        searchTop
        searchBottom={false}
        fullPagination
        searchLabel={"Cerca"}
        responsive
        disableRetreatAfterSorting={true}
      />{" "}
    </div>
  );
}

export default UtentiDisattivati;
