import React, {useState, useEffect } from 'react';

const Contenuto = () => {
	const queryParams = new URLSearchParams(window.location.search);
	const item = queryParams.get('item');
	const [currentContent, setCurrentContent] = useState(false);
	const [currentTagElement, setCurrentTagElement] = useState(null);
	useEffect(()=>{
		fetch(process.env.REACT_APP_BASE_URL + `contenuto/${item}`)
		.then((res) => res.json())
		.then((res) => {
			setCurrentContent(res);

		})

	},[])
	useEffect(() => {
		if (currentContent.file)
		{
			if (currentContent.tipologia === "Immagine")
			{
				setCurrentTagElement(<img style={{width:"640px"}} src={process.env.REACT_APP_BASE_URL_CONTENTS + currentContent.file} />)
			}
			else if (currentContent.tipologia === "Catalogo")
			{
				setCurrentTagElement(<h1>Anteprima non disponibile</h1>)
				//setCurrentTagElement(<iframe width="100%" height="600" frameborder="0" src={`https://docs.google.com/gview?url=${process.env.REACT_APP_BASE_URL_CONTENTS + currentContent.file}&embedded=true`}></iframe>)
			}
			else
			{
				setCurrentTagElement(<video style={{width:"640px"}}  controls autoplay src={process.env.REACT_APP_BASE_URL_CONTENTS + currentContent.file} />)
			}
		}
	}, [currentContent])
	if (!currentTagElement)
	{
		return(<></>);
	}
	return(
		<>
			<div className='singolo_contenuto'>
				<a className="scarica" href={process.env.REACT_APP_BASE_URL_CONTENTS + currentContent.file} target="_blank">Scarica</a>
			</div>
			<p><h1>{currentContent.nome}</h1></p>
			<div>{currentTagElement}</div>
			
		</>
	)
}
export default Contenuto;