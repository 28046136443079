import { Fab } from "@material-ui/core";
import { getUniqueID } from "@syncfusion/ej2-base";
import Button from "@mui/material/Button";
import { THEME_BLUE } from "../../../CONST";

const CircleButtonComponent = (props) => {
  const id = getUniqueID();
  return (
    <Button
      className={props.className}
      disabled={props.disabled}
      size="small"
      onClick={() => {
        props.onClick && props.onClick();
      }}
    >
      <Fab
        size="small"
        color={props.color ? props.color : "primary"}
        variant={props.text ? "extended" : null}
        style={{ padding: 12, backgroundColor: props.bgColor ?? THEME_BLUE }}
      >
        {props.children}
        {props.icon}
        {props.text && (
          <span style={{ marginLeft: props.icon ? 8 : 0 }}>{props.text}</span>
        )}
      </Fab>
    </Button>
  );
};

export default CircleButtonComponent;
