import React from "react";
import { useHistory } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { Button} from "react-bootstrap";
import swal from "sweetalert";
const AppProfile= () => {
	const history = useHistory();
	let userData = localStorage.getItem('userDetails','undefined');
	const passRef = useRef();
	const cPassRef = useRef();
	const formDataRef = useRef();
	const formDataPassRef = useRef();
	const [profiloUtente, setProfiloUtente] = useState([]);
	/* eslint-disable */
	const fetchUser = () => 
	{
		let user = JSON.parse(userData);
		fetch(process.env.REACT_APP_BASE_URL+'utenti/' + user.userid)
		.then((res) => res.json())
		.then((res) => {
			setProfiloUtente(res[0]);
		});
	}
	const ruolo = ['','Amministratore', 'Operatore', 'Cliente'];
	const handleSubmitData = event => {
		event.preventDefault();
		const elements = event.target.elements;
		const data = {};
		for (let i=0; i < elements.length; i++) {
			const elem = elements[i];
			data[elem.name] = elem.value
		}
		fetch(process.env.REACT_APP_BASE_URL + 'modifica-profilo', { method: 'POST',
		headers: {'Content-Type': 'application/json'},
		body: JSON.stringify(data)
		}).then(function(response) {
			if (response.status === 200) {
			
				swal("", "Dati modificati", "success")
				history.push('/')
		  	}
		  	else {throw new Error(response.status);}
		  	}).catch(function(error)
		  	{
				swal("", "Impossibile aggiornare i dati", "error")
		  	});
	}
	const handleSubmitPass = event => {
		event.preventDefault();
		if (passRef.current.value !== cPassRef.current.value)
		{
			swal("", "Le password non corrispondono", "error")
			return;
		}
		const elements = event.target.elements;
		const data = {};
		for (let i=0; i < elements.length; i++) {
			const elem = elements[i];
			data[elem.name] = elem.value
		}
		fetch(process.env.REACT_APP_BASE_URL + 'password-utente', { method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify(data)}).then(function(response) {
			if (response.status === 200) {
				swal("", "Password modificata!", "success")
				formDataRef.current.hidden = false; formDataPassRef.current.hidden=true;	  
		  	}
		  	else {throw new Error(response.status);}
		  	}).catch(function(error)
		  	{
				swal("", "Impossibile modificare la password", "error")
		  	});
	  	}
	useEffect(() =>
	{
		fetchUser();
	},[]);
	return(
		<>
	
<	div className="card">
		<p className="subtab">Profilo Utente di <strong>{profiloUtente.nome + " " + profiloUtente.cognome}</strong> -  Ruolo: <strong>{ruolo[profiloUtente.tipo] }</strong></p> 
    	<div className="card-bodyx">
      
      		<form ref={formDataRef} autoComplete="off" onSubmit={handleSubmitData}>
        		<input type="hidden" name="id" value={profiloUtente.id} />
        		<input type="hidden" name="status" value={profiloUtente.status} />
        		<div className="form-row">
              		<label className="form-label">NOME</label>
              		<input className="form-input" type="text" name="nome" defaultValue={profiloUtente.nome} required={true} disabled />
        		</div>
        		<div className="form-row">
              		<label className="form-label">COGNOME</label>
              		<input className="form-input" type="text" name="cognome" defaultValue={profiloUtente.cognome} required={true} disabled/>
        		</div>
        		<div className="form-row">
              		<label className="form-label">EMAIL</label>
              		<input className="form-input" type="text" name="email" defaultValue={profiloUtente.email} required={true} disabled/>
        		</div>
        		<div className="form-row">
					<div className="form-buttons-right">
						<Button className="btn btn-outline-primary rounded-1 btn-sm" type="button" onClick={() => { formDataRef.current.hidden = true; formDataPassRef.current.hidden = false;  }}>Modifica Password</Button>
						{/* <Button className="btn btn-outline-primary rounded-1 btn-sm" type="submit">Salva</Button>
                		<Button className="btn btn-outline-primary rounded-1 btn-sm" type="button" onClick={() => {history.push('/');}}>Annulla </Button> */}
					</div>
            	</div>
          	</form>
    
			<form hidden={true} autoComplete="off" ref={formDataPassRef} onSubmit={handleSubmitPass}>
        		<input type="hidden" name="id" value={profiloUtente.id} />
				<div className="form-row">
              		<label className="form-label">PASSWORD</label>
              		<input ref={passRef} className="form-input" type="password" name="password" required={true} />
        		</div>
        		<div className="form-row">
              		<label className="form-label">CONFERMA PASSWORD</label>
              		<input ref={cPassRef} className="form-input" type="password" name="password-conferma" required={true} />
        		</div>
        		<div className="form-row">
        			<div className="form-buttons-right">
                		<Button className="btn btn-outline-primary rounded-1 btn-sm" type="submit">Conferma</Button>
                		<Button className="btn btn-outline-primary rounded-1 btn-sm" type="button" onClick={() => { formDataRef.current.hidden = false; formDataPassRef.current.hidden=true; }}>Annulla </Button>
            		</div>
            	</div>
          	</form>
    	</div>
	</div>
    </>








	)
}
export default AppProfile
