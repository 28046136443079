import {React,useState, useEffect } from "react";
import Swal from "sweetalert2";
import { Button } from "react-bootstrap";
export function RichiestaLicenze(props) {
  const title = useState(props.title);
  function onModalClose(){
    props.onCloseModal();
  }
  const [usersEmail, setUsersEmails] = useState([]); 
  const [pos, setPos] = useState([]); 
  const userData = localStorage.getItem('userDetails','undefined');
  const user = JSON.parse(userData);
 
  const getData = () => 
  {
    let currentUser = JSON.parse(userData);
    fetch(process.env.REACT_APP_BASE_URL + 'email-admins')
    .then((res) => res.json())
    .then((res) => {
      if (res.length > 0 )  
      {
        setUsersEmails(res);
      }
      else {throw new Error(res.status);}
    }).catch(function(error)
    {
      setUsersEmails([usersEmail]);
    });
    
    fetch(process.env.REACT_APP_BASE_URL + 'punti-vendita/1/ref/' + currentUser.userid)
    .then((res) => res.json())
    .then((res) => {
      if (res.length > 0 )  
      {
        setPos(res);
      }
      else {throw new Error(res.status);}
    }).catch(function(error)
    {
      
    });
  }
 
  /* eslint-disable */
  useEffect(() => {getData();},[])
 
  const handleSubmit = event => {
    event.preventDefault();
    if (event.target.pos.value === 0 || event.target.qty.value === 0)
    {
      Swal.fire({title : "",text : "Indicare le licenze da richiedere e su quale punto vendita!",icon : "warning"});
      return;
    }
    const dataMail = {};
    const data = {};
    const emails = [];
    let currentUser = JSON.parse(userData);
    let selectedPos = pos.find((p) => {return p.id = event.target.pos.value})
    dataMail['sendername'] = currentUser.nome + " " + currentUser.cognome;
    dataMail['sendermail'] = currentUser.email;
    dataMail['subject'] = "Richiesta Licenze";
    dataMail['message'] = "Richiesta di " +  event.target.qty.value + " licenze. per il punto vendita " + selectedPos.nome + "\r\nNote: " + event.target.notes.value;
    usersEmail.forEach((i) => { emails.push({to : i.label, toaddress : i.value, data : dataMail }); });
    data['emails'] = emails;
    fetch(process.env.REACT_APP_BASE_URL + 'richiesta-licenze', { method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(data)
    }).then(function(response) {
        if (response.status === 200) {
            Swal.fire({title : "",text : "Messaggio inviato ai destinatari selezionati",icon : "success"})
            onModalClose();
        }
          else {throw new Error(response.status);}
        }).catch(function(error)
        {
          Swal.fire({title : "",text : "Errore!",icon : "error"});
        });
  }
 
  return(
  <>
      <div className="dettagli-messaggio" style={{height:"300px;"}}>
        <div className="clearfix"></div>
        <form className='messages' onSubmit={handleSubmit}>
          <h3>{title}</h3>
          <input type='hidden' value= {user.email} disabled name='sender' /><br />
          <br />
          <div style={{width:'100%'}}>
            <div className="form-row">
              <label for="qty">Licenze richieste:</label>
              <input type="number" style={{width:"80px"}} min={1} max={100} name="qty" defaultValue="1" className="form-input"/>&nbsp;
            </div>
            <div className="form-row">
            <label for="pos">Punto Vendita:</label>
            <select name="pos" className="form-select" defaultValue={ pos.length === 1 ? pos[0].id : 0} style={{width:"200px", float:"right"}}>
                <option value={0}>Seleziona il punto vendita</option>
                {pos.map(p =>(
                    <option value={p.id}>{p.nome}</option>
                ))}
              </select>
            </div>
            <br/>
            <label for="message">Note:</label>
            <textarea style={{resize:"none"}} name="notes"></textarea>
            <br/>
            <div style={{width:'100%',textAlign:"right"}}>
              <Button className="btn btn-outline-primary rounded-1 btn-sm buttonmodal" type="submit">Invia Richiesta</Button>
              <Button className="btn btn-outline-primary rounded-1 btn-sm buttonmodal" type="button" onClick={onModalClose}>Annulla </Button> 
            </div>
          </div>
        </form>
      </div>
  </>
)};
export default RichiestaLicenze
           

