import React, { useState, useEffect } from 'react'

const PlayerPreview = (props) => {
    const [widthPlayer, setWidthPlayer] = useState(640)
    const [heightPlayer, setHeightPlayer] = useState(360)
    const [palinsesto, setPalinsesto] = useState(null)
    useEffect(() => {
        if (props.scheduleId === '0') {
            setPalinsesto(null)
            return
        }
        try {
            fetch(process.env.REACT_APP_BASE_URL + 'palinsesto/' + props.scheduleId)
            .then((res) => res.json())
            .then((res) => {
                if (res.length === 0) {
                    return
                }
                setPalinsesto(res[0])
            })
        } catch (error) {
            console.error(error)
        }
    }, [])

    useEffect(() => {
        try {
            if (palinsesto.template === 0) {
                setWidthPlayer(640)
                setHeightPlayer(360)
            } else if (palinsesto.template === 1) {
                setWidthPlayer(360)
                setHeightPlayer(640)
            }
            if (palinsesto) {
                start()
            }
        } catch (Error) {}
    }, [palinsesto])
    
    
    return (
      <div
        style={{
          width: `${widthPlayer}px`,
          height: `${heightPlayer}px`,
        }}
      >
        <iframe
          style={{
            width: `${widthPlayer}px`,
            height: `${heightPlayer * 0.9}px`,
          }}
          src={
            "https://d2ocklk6c40eyp.cloudfront.net?id_pal=" + props.scheduleId
          }
        ></iframe>
      </div>
    );
    }
    export default PlayerPreview
    