import React from "react";
import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import HomeCategorie from "../../pages/home_categorie";
import getMessages from "../../functions/getMessages";

//Images
import messaggi from "./../../../images/messaggi.png";
import palinsesto from "./../../../images/palinsesto.png";
import iniziative from "./../../../images/iniziative.png";

//Import Components

const Home = () => {
  const [unreaded, setUnreaded] = useState(false);
  const [currentUser] = useState(
    JSON.parse(localStorage.getItem("userDetails", "undefined"))
  );
  const getUnreaded = async () => {
    try {
      setUnreaded(await getMessages("messaggi"));
    } catch (error) {}
  };
  useEffect(() => {
    getUnreaded();
    updateUnreadedRef.current = setInterval(() => {
      getUnreaded();
    }, 5000);
    return () => {
      clearInterval(updateUnreadedRef.current);
    };
  }, []);
  const updateUnreadedRef = useRef(null);
  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <div className="row">
            <div hidden={currentUser.tipo > 2} className="col-xl-12">
              <HomeCategorie />
            </div>

            {/* <div className="col-xl-8 boxright">
              <div className="row">
                <div className="col-xl-12">
                  <Link to={"messaggi?tab=tutti-i-messaggi"}>
                    <div>
                      <div
                        className="card-body row homebutton"
                        style={{ backgroundColor: "rgb(30,119,200" }}
                      >
                        <div className="col-xl-7 col-sm-6 dtable">
                          <p hidden={!unreaded}>
                            <span>
                              Hai <b>{unreaded}</b>{" "}
                              <span hidden={unreaded !== "1"}>
                                messaggio non letto
                              </span>
                              <span hidden={unreaded === "1"}>
                                messaggi non letti
                              </span>
                            </span>
                            <br />
                            Visualizza
                          </p>
                          <p hidden={unreaded} id="messagebox">
                            <span>Visualizza i tuoi messaggi</span>
                          </p>
                        </div>
                        <div className="col-xl-5 col-sm-6">
                          <img
                            src={messaggi}
                            alt=""
                            className="sd-shape-home"
                          />
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-xl-6 col-sm-6 col-x6-6">
                  <Link to={"contenuti?tab=cartelle"}>
                    <div>
                      <div
                        className="card-body row homebutton"
                        style={{ backgroundColor: "rgb(155,35,235" }}
                      >
                        <div className="col-xl-7 col-sm-6 dtable">
                          <p>
                            <b>Contenuti</b>
                          </p>
                        </div>
                        <div className="col-xl-5 col-sm-6">
                          <img
                            src={iniziative}
                            alt=""
                            className="sd-shape-home"
                          />
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div
                  hidden={currentUser.tipo > 2}
                  className="col-xl-6 col-sm-6 col-xs-6"
                >
                  <Link to={"nuovo-palinsesto"}>
                    <div>
                      <div
                        className="card-body row homebutton"
                        style={{ backgroundColor: "rgb(236,21,129" }}
                      >
                        <div className="col-xl-7 col-sm-6 dtable">
                          <p>
                            Nuovo <b>palinsesto</b>
                          </p>
                        </div>
                        <div className="col-xl-5 col-sm-6">
                          <img
                            src={palinsesto}
                            alt=""
                            className="sd-shape-home"
                          />
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div
                  hidden={currentUser.tipo < 3}
                  className="col-xl-6 col-sm-6 col-xs-6"
                >
                  <Link to={"palinsesti?tab=palinsesti-sub"}>
                    <div>
                      <div
                        className="card-body row homebutton"
                        style={{ backgroundColor: "rgb(236,21,129" }}
                      >
                        <div className="col-xl-7 col-sm-6 dtable">
                          <p>
                            <b>Palinsesti</b>
                          </p>
                        </div>
                        <div className="col-xl-5 col-sm-6">
                          <img
                            src={palinsesto}
                            alt=""
                            className="sd-shape-home"
                          />
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};
export default Home;
