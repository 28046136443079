import React, {useEffect, useState} from "react";
import { Pie } from "react-chartjs-2";

const ChartGestioneLicenze= (props,{ color1, color2,color3, height}) => {
   
   const [data, setData] = useState([])
   const options = {
      responsive: true,
      legend: false,
      maintainAspectRatio: false,
   };
   const [licensesData, setLicensesData] = useState({});
   const [licenses, setLicenses] = useState([]);
   const fetchLicenze = () =>
   {
      fetch(process.env.REACT_APP_BASE_URL + 'licenze')
      .then(response => response.json())
      .then(res => setLicenses(res))
      .catch(error => this.setState({ error, isLoading: false }));
      props.setRefresh(false);
   }
  
   useEffect(() => {fetchLicenze();}, [props.refresh]);
   /* eslint-disable */
   useEffect(() => 
   {
      let licActives = licenses.filter((l) => { return parseInt(l.status) === 1 }).length;
      let licFree = licenses.filter((l) => { return parseInt(l.status) === 0 }).length;
      let licExpired = licenses.filter((l) => { return parseInt(l.status) > 1 }).length;
      let licTotal = licenses.length;
      let licData = { actives : licActives, expired : licExpired, free : licFree, total : licTotal }
      setLicensesData(licData);
      let dataGraph = {
         datasets: [
            {
               data: [licFree, licActives, licExpired],
               borderWidth: 0,
               backgroundColor: [
                  `${color1 ? color1 : "#ff0000"}`,
                  `${color2 ? color2 : "#00ff00"}`,
                  `${color3 ? color3 : "#bbccff"}`,
                  
               ],
               hoverBackgroundColor: [
                  `${color1 ? color1 : "#aa0000"}`,
                  `${color2 ? color2 : "#00aa00"}`,
                  `${color3 ? color3 : "#aa00aa"}`,
                  
               ],
            },
         ],
         labels: ["NON ASSEGNATE", "ATTIVE", "SCADUTE"],
      };
      setData(dataGraph);
   }, [licenses]);

return (
   <>
      <div className="row">
            <div className="col-xl-12">
               <div>
                  <div className="card-body row homebutton" style={{backgroundColor:"rgb(30,119,200"}}>
                     <div className="col-xl-7 col-sm-6 dtable">
                        <table className="table1">
                           <tr><td>LICENZE TOTALI</td><td>{licensesData.total}</td></tr>
                           <tr><td>LICENZE USATE</td><td>{licensesData.actives}</td></tr>
                           <tr><td>LICENZE NON ASSEGNATE</td><td>{licensesData.free}</td></tr>
                           <tr><td>LICENZE SCADUTE</td><td>{licensesData.expired}</td></tr>
                        </table>
                     </div>
                     <div className="col-xl-5 col-sm-6" style={{padding:"10px"}}>
                        <Pie data={data} height={height ? height : 200} options={options} />
                     </div>
                  </div>
               </div>
            </div>
         </div>					   
      </>
   );
};
   
export default ChartGestioneLicenze;