import React, { useState, useEffect, useMemo } from 'react'
import { ProgressBar, Button, Modal } from 'react-bootstrap'
import Swal from 'sweetalert2'
//import DatePicker, { registerLocale } from "react-datepicker";
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import catalogo from '../../images/catalogo.png'
import posteraudio from '../../images/posteraudio.png'

const Row = (props) => {
    const [data, setData] = useState(props.item)
    /* eslint-disable */
    useEffect(() => {
        props.onChange(data)
    }, [data])

    const getHtmlComponent = useMemo(() => {
        const type = props.item.type

        if (type === 'Immagine') {
            return <img style={{maxHeight:"120px", minHeight:"120px"}} className="anteprima_inserimento" src={URL.createObjectURL(props.item.file)} alt={props.item.file.name} />
        }

        if (type === 'Audio') {
            return (
                <video style={{maxHeight:"120px", minHeight:"120px"}}
                    onLoadedMetadata={(event) => {
                        setData({
                            ...data,
                            duration: parseInt(event.target.duration, 10)
                        })
                    }}
                    preload="metadata"
                    className="anteprima_inserimento"
                    id={props.item.file.name}
                    src={URL.createObjectURL(props.item.file)}
                    poster={posteraudio}
                    controls
                />
            )
        }
        if (type === 'Video') {
            return (
                <video
                    style={{maxHeight:"120px", minHeight:"120px"}}
                    onLoadedMetadata={(event) => {
                        setData({
                            ...data,
                            duration: parseInt(event.target.duration, 10)
                        })
                    }}
                    preload="metadata"
                    className="anteprima_inserimento"
                    id={props.item.file.name}
                    src={URL.createObjectURL(props.item.file)}
                    controls
                />
            )
        }
        if (type === 'Catalogo') {
            return <img style={{maxHeight:"120px", minHeight:"120px"}} className="anteprima_inserimento" src={catalogo} alt={props.item.file.name} />
        }

        return null
    }, [])

    return (
        <tr>
            <td style={{ textAlign: 'center' }}>
                
                {getHtmlComponent}
                </td>
            <td>
                Titolo
                <br />
                <input type="text" defaultValue={data.title} onChange={(e) => setData({ ...data, title: e.target.value })} />
                <br />
                <br />
                Descrizione
                <br />
                <input type="text" defaultValue={data.description} onChange={(e) => setData({ ...data, description: e.target.value })} />
                <br />
                <br />
                Durata (secondi)
                <br />
                <label hidden={!['Catalogo'].includes(data.type)}>Non applicabile</label>
                <input
                    hidden={['Catalogo'].includes(data.type)}
                    type="text"
                    disabled={['Audio', 'Video'].includes(data.type)}
                    value={data.duration}
                    onChange={(e) => setData({ ...data, duration: e.target.value })}
                />
                <br />
                <br />
                Tipologia: <b>{data.type}</b>
                <input hidden={true} defaultValue={data.size} />
            </td>
            <td></td>
            <td>
                <div style={{fontSize:"1.5em"}} >
                <input onChange={(e) => setData({ ...data, selected: e.target.checked })} style={{ msTransform: "scale(2)", mozTransform: "scale(2)", webkitTransform: "scale(2)", oTransform: "scale(2)", transform: "scale(2)", padding: "10px"}} type="checkbox" name="selected" defaultChecked={data.selected}/>&nbsp;&nbsp;&nbsp;
                <label for="idoneo" >Selezionato per il caricamento</label>
                </div>
                <div className='clearfix'></div>
                <br />
                Pubblicato{' '}
                <input
                    type="checkbox"
                    disabled={data.type === 'Catalogo'}
                    defaultChecked={data.published}
                    onChange={(e) => setData({ ...data, published: e.target.checked })}
                />
                <br />
                <br />
                <b>Data Inizio e Data fine, se impostati permettono di temporizzare la visualizzazione in una determinata finestra temporale.<br></br>Altrimenti il contenuto verr&agrave; inserito sempre nel palinsesto</b>
                <br />
                <br />
                Data inizio <input type="checkbox" defaultChecked={data.hasstart} onChange={(e) => setData({ ...data, hasstart: e.target.checked })} />
                <DatePicker
                    name="start_date"
                    dateFormat="dd/MM/yyyy"
                    locale="it"
                    selected={data.startdate}
                    onChange={(date) => {
                        console.log('new date: ', date)
                        setData({
                            ...data,
                            startdate: date
                        })
                    }}
                />
                Data fine <input type="checkbox" defaultChecked={data.hasend} onChange={(e) => setData({ ...data, hasend: e.target.checked })} />
                <DatePicker
                    name="end_date"
                    dateFormat="dd/MM/yyyy"
                    locale="it"
                    selected={data.enddate}
                    onChange={(date) => {
                        setData({
                            ...data,
                            enddate: date
                        })
                    }}
                />
                Può essere notificato{' '}
                <input
                    type="checkbox"
                    disabled={data.type === 'Catalogo'}
                    defaultChecked={data.notified}
                    onChange={(e) => setData({ ...data, notified: e.target.checked })}
                />
            </td>
        </tr>
    )
}

const InserimentoContenuti2 = (props) => {
    const extensionsAudio = '.mp3,.wav,.aac,.mp2,.m4a,.wma,.ogg,.ac3'
    const extensionsVideo = '.mp4,.m4v,.mpeg,.ts'
    const extensionsDocs = '.pdf,.doc,.docx,.xls,.xlsx,.csv,.ppt,.pptx'
    //Non rimuovere questo commento
    //const extensionsUnable = "wmv|avi|mkv|ps,.tif,.tiff"
    //------------------------>
    const extensionsImages = '.png,.jpeg,.jpg,.bmp,.gif'
    //const [tags, setTags] = useState([]);
    const [fileItems, setFilesCandidates] = useState(props.fileItems)
    const filesArray = []
    const [uploadValue, setUploadValue] = useState(0)
    const [uploadText, setUploadText] = useState('')
    const [show, setShow] = useState(false)
    const handleClose = () =>
        setTimeout(() => {
            setShow(false)
        }, 2000)
    const handleShow = () => setShow(true)
    const progress = (p) => {
        if (p + 1 >= fileItems.length) {
            if (show) {
                handleClose()
                return
            }
        }
        setUploadValue(Math.round(((p + 1) * 100) / fileItems.length))
        setUploadText('Elaborati ' + (p + 1) + ' di ' + fileItems.length)
    }
    const onScrollTop = () => {
        props.onScrollTop()
    }
    useEffect(() => props.changeFiles(fileItems), [fileItems])
    useEffect(() => {
        fetch(process.env.REACT_APP_BASE_URL + 'tags')
            .then((res) => res.json())
            .then((res) => {
                //    setTags(res);
            })
    }, [])
    const handleChangeFiles = () => {
        document.getElementById('buttonFolder').hidden = true
        document.getElementById('buttonFiles').hidden = false
    }
    const handleChangeFolder = () => {
        document.getElementById('buttonFolder').hidden = false
        document.getElementById('buttonFiles').hidden = true
    }
    const handleChange = (e) => {
        e.preventDefault()
        handleShow()
        try {
            let unsupported = false
            setUploadText("Attendere l'elaborazione...")
            for (let file of e.target.files) {
                let ext = file.name.split('.').pop().toLowerCase()
                let title = file.name
                    .replace('.' + ext, '')
                    .split(' - ')
                    .shift()
                let description = file.name
                    .replace('.' + ext, '')
                    .split(' - ')
                    .pop()
                description = description !== title ? description : 'Nessuna descrizione'
                let type = ''
                let duration = -1
                let published = false
                let allowed = false
                let tagHtml = ''
                let selected = true;
                let notified = true;
                try {
                    if (extensionsImages.includes(ext)) {
                        type = 'Immagine'
                        published = true
                        allowed = true
                        tagHtml = <img height="90px" maxWidth="200px" src={URL.createObjectURL(file)} alt="" />
                        duration = 5
                    } else if (extensionsAudio.includes(ext)) {
                        type = 'Audio'
                        published = true
                        allowed = true
                        tagHtml = (
                            <video
                                preload="metadata"
                                height="90px;"
                                minWidth="200px"
                                maxWidth="200px"
                                id={file.name}
                                src={URL.createObjectURL(file)}
                                poster={catalogo}
                                controls
                            />
                        )
                    } else if (extensionsVideo.includes(ext)) {
                        type = 'Video'
                        published = true
                        allowed = true
                        tagHtml = (
                            <video
                                preload="metadata"
                                height="90px;"
                                minWidth="200px"
                                maxWidth="200px"
                                id={file.name}
                                src={URL.createObjectURL(file)}
                                controls
                            />
                        )
                    } else if (extensionsDocs.includes(ext)) {
                        if (ext === 'avi' || ext === 'mkv' || ext === 'wmv' || ext === 'm4v') {
                            unsupported = true
                        }
                        type = 'Catalogo'
                        allowed = true
                        tagHtml = <img height="90px" maxWidth="200px" src={catalogo} alt="" />
                        duration = 0
                        published = false
                    }
                    let data = {
                        file: file,
                        type: type,
                        title: title,
                        size: file.size,
                        description: description,
                        duration: duration,
                        published: published,
                        selected: selected,
                        hasstart: false,
                        hasend: false,
                        startdate: new Date(),
                        enddate: new Date(new Date().setFullYear(2099)),
                        isdocument: document,
                        tagHtml: tagHtml,
                        notified : notified
                    }
                    if (allowed) {
                        filesArray.push(data)
                    }
                } catch (e) {}
                if (unsupported) {
                    Swal.fire({
                        title: 'Avviso importante',
                        text: 'Sono stati inseriti dei formati audio e/o video che non sono idonei alla riproduzione nel palinsesto.\r\nVerranno inseriti come cataloghi',
                        icon: 'warning'
                    })
                }
                setFilesCandidates(filesArray)
            }
        } catch (error) {
            Swal.fire({ title: '', text: 'Errore nel caricamento!' + error, icon: 'error' })
            e.target.value = null
        }
    }

    return (
        <>
            <div className="inserimento-contenuti">
                <div className="contenuti-files" hidden={fileItems.length > 0}>
                    <input type="radio" className="inputcontenuti" id="checkFiles" name="checkFolder" defaultChecked={true} onClick={handleChangeFiles} />
                    &nbsp;&nbsp;&nbsp;<label for="checkFiles">Uno o pi&ugrave; files</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <input type="radio" className="inputcontenuti" id="checkFolder" name="checkFolder" defaultChecked={false} onClick={handleChangeFolder} />
                    &nbsp;&nbsp;&nbsp;<label for="checkFolder">Cartella</label>
                    <input
                        hidden={true}
                        className="inputcontenuti"
                        directory=""
                        webkitdirectory=""
                        type="file"
                        name="up_folder"
                        id="up_folder"
                        onChange={handleChange}
                    />
                    <label id="buttonFolder" className="btn btn-primary btncontenuti" hidden={true} for="up_folder">
                        Carica
                    </label>
                    <input
                        hidden={true}
                        className="inputcontenuti"
                        accept={extensionsAudio + ',' + extensionsVideo + ',' + extensionsImages + ',' + extensionsDocs}
                        multiple={true}
                        type="file"
                        name="up_file"
                        id="up_file"
                        onChange={handleChange}
                    />
                    <label className="btn btn-primary btncontenuti" id="buttonFiles" hidden={false} for="up_file">
                        Carica
                    </label>
                </div>

                <div>
                    <table>
                        {fileItems.map((item) => {
                            return (
                                <Row
                                    item={item}
                                    onChange={(data) => {
                                        setFilesCandidates(
                                            fileItems.map((file) => {
                                                progress(fileItems.filter((f) => f.duration >= 0).length)
                                                if (file.title === item.title) {
                                                    return data
                                                }
                                                return file
                                            })
                                        )
                                    }}
                                />
                            )
                        })}
                    </table>
                    <div style={{ textAlign: 'right', padding: '10px' }} hidden={fileItems.length < 3}>
                        <Button
                            className="btn btn-success btn-floating btn-lg"
                            style={{ padding: '10px 20px' }}
                            onClick={() => {
                                onScrollTop()
                            }}
                        >
                            <i class="fas fa-arrow-up"></i>
                        </Button>
                    </div>
                </div>
            </div>
            <Modal dialogClassName="modal-inserimento" show={show} onRequestClose={handleClose}>
                <Modal.Header>
                    <h3>Analisi metadati in corso...</h3>
                </Modal.Header>
                <Modal.Body>
                    <ProgressBar animated now={uploadValue} />
                    <div id="loadStatus">{uploadText}</div>
                </Modal.Body>
                <Modal.Footer>
                    <h5>Non abbandonare questa pagina e non chiudere questa finestra!</h5>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default InserimentoContenuti2
