import React, { Fragment, useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'
import { itemUtenti } from './utenti-attivi'
import UtentiAssociaBrands from './utenti-associa-brands'
export function ModificaUtenteAttivo() {
    const [currentUser] = useState(JSON.parse(localStorage.getItem('userDetails', 'undefined')))
    const [utenteBrands, setUtenteBrands] = useState([])
    const [buttonDisabled, setButtonDisabled] = useState(itemUtenti.tipo !== 3)
    const [selectedUser] = useState({ id: itemUtenti.id })
    const history = useHistory()
    const handleShow = () => setShow(true)
    const getUtenteBrands = () => {
        fetch(process.env.REACT_APP_BASE_URL + 'get-clienti-brands/' + itemUtenti.id)
            .then((res) => res.json())
            .then((res) => {
                setUtenteBrands(res)
            })
    }
    useEffect(() => {
        getUtenteBrands()
    }, [])
    const handleSubmit = (event) => {
        event.preventDefault()
        const elements = event.target.elements
        const data = {}
        const itemsUtenteBrands = []
        for (let i = 0; i < elements.length; i++) {
            const elem = elements[i]
            data[elem.name] = elem.value
        }
        if (parseInt(event.target.tipo.value) === 3) {
            utenteBrands.forEach((i) => {
                let b = { id_utente: 0, id_brand: i.id }
                itemsUtenteBrands.push(b)
            })
        }
        data['utenteBrands'] = itemsUtenteBrands
        fetch(process.env.REACT_APP_BASE_URL + 'modifica-utente', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        })
            .then(function (response) {
                if (response.status === 200) {
                    //let responseText = JSON.stringify(response.text());
                    Swal.fire({ title: '', text: 'Operazione eseguita correttamente', icon: 'success' })
                    history.push('utenti')
                } else {
                    throw new Error(response.status)
                }
            })
            .catch(function (error) {
                Swal.fire({ title: '', text: "Impossibile salvare l'utente corrente", icon: 'error' })
            })
    }

    return (
        <>
            <div className="card">
                <p className="pform">Modifica Utente</p>

                <div className="card-bodyx">
                    <form onSubmit={handleSubmit}>
                        <input type="hidden" name="id" value={itemUtenti.id} />
                        <input type="hidden" name="status" value={itemUtenti.status} />
                        <div className="form-row">
                            <label className="form-label">NOME</label>
                            <input className="form-input" type="text" name="nome" defaultValue={itemUtenti.nome} required={true} />
                        </div>
                        <div className="form-row">
                            <label className="form-label">COGNOME</label>
                            <input className="form-input" type="text" name="cognome" defaultValue={itemUtenti.cognome} required={true} />
                        </div>
                        <div className="form-row">
                            <label className="form-label">EMAIL</label>
                            <input className="form-input" type="text" name="email" defaultValue={itemUtenti.email} required={true} />
                        </div>

                        <div className="form-row">
                            <label className="form-label">RUOLO</label>

                            <select
                                onChange={(e) => {
                                    setButtonDisabled(parseInt(e.currentTarget.value) !== 3)
                                }}
                                className="form-select-option"
                                defaultValue={itemUtenti.tipo}
                                name="tipo"
                            >
                                <option value="2">Operatore</option>
                                <option value="3">Cliente</option>
                            </select>
                        </div>
                        <div className="form-row" style={{ minHeight: '400px' }}>
                            <div hidden={buttonDisabled}>
                                <UtentiAssociaBrands
                                    hideClose={true}
                                    utenteBrands={utenteBrands}
                                    selectedUser={selectedUser}
                                    toggleItem={(item) => {
                                        const foundIndex = utenteBrands.findIndex((brand) => brand.id === item.id)
                                        if (foundIndex === -1) {
                                            setUtenteBrands([...utenteBrands, item])
                                        } else {
                                            const arrayCopy = JSON.parse(JSON.stringify(utenteBrands))
                                            arrayCopy.splice(foundIndex, 1)
                                            setUtenteBrands(arrayCopy)
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div
                                className="form-buttons-right"
                                style={{
                                    paddingBottom: 20
                                }}
                            >
                                <Button className="btn btn-outline-primary rounded-1 btn-sm" type="submit">
                                    Salva
                                </Button>
                                <Button
                                    className="btn btn-outline-primary rounded-1 btn-sm"
                                    type="button"
                                    onClick={() => {
                                        history.push('utenti')
                                    }}
                                >
                                    Annulla{' '}
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default ModificaUtenteAttivo
