import React from 'react';
import axios from 'axios';
import Swal from "sweetalert2";
import 'antd/dist/antd.css';
import { Steps, Button } from 'antd';
import NuovoPalinsesto1 from "./nuovo-palinsesto-1";
import NuovoPalinsesto2 from "./nuovo-palinsesto-2";
import NuovoPalinsesto3 from "./nuovo-palinsesto-3";

export let palinsestoData = { palinsesto : "",uuid: "", categoria : 0 , template : 0, items : [], eventi : []};
const { Step } = Steps;

const steps = [
{
  title: 'Informazioni generali',
  content: <NuovoPalinsesto1 />,
  disabled: false,
  //subTitle: "subtitle1",
  // description: "descrizione 1"
},
{
  title: 'Gestione brand',
  content: <NuovoPalinsesto2 />,
  disabled: false,
  //subTitle: "subtitle2",
  // description: "descrizione 2"
},
{
  title: 'Assegnazione template',
  content: <NuovoPalinsesto3 />,
  disabled: false,
  //subTitle: "subtitle3",
  //description: "descrizione 3"
}];
class NuovoPalinsesto extends React.Component { state = { current: 0,};
getDate = (datetime) =>
{
  let dateObj = new Date(datetime);
  return (dateObj.getHours() < 10 ? "0" + dateObj.getHours() : dateObj.getHours()) + ":" + (dateObj.getMinutes() < 10 ? "0" + dateObj.getMinutes() : dateObj.getMinutes())
}
getDay = (datetime) =>
{
  let dateObj = new Date(datetime);
  return dateObj.getDay();
}
getDuration = (startDateTime, endDateTime) =>
{
  let startDateObj = new Date(startDateTime);
  let endDateObj = new Date(endDateTime);
  let duration = ((endDateObj.getHours() * 60) + endDateObj.getMinutes()) - ((startDateObj.getHours() * 60) + startDateObj.getMinutes());
  return duration;
}  

parseData = () => {
    let eventi = JSON.parse(JSON.stringify(palinsestoData.eventi))
    palinsestoData.eventi = [];
    if (eventi.length >0)
    {
        eventi.forEach((item) => {
          let itemStartTime = item.start ? this.getDate(item.start) : item.extendedProps.startTime;
          let itemEndTime = item.end ? this.getDate(item.end) : item.extendedProps.endTime;
          let itemDayOfWeek = item.start ? this.getDay(item.start) : item.extendedProps.dayOfWeek;
          let duration = item.start ? this.getDuration(item.start, item.end) : item.extendedProps.duration;
          let evento = {
            id : item.id,
            title : item.title,
            startTime : itemStartTime,
            endTime : itemEndTime,
            allDay : false,
            daysOfWeek : [ itemDayOfWeek ],
            extendedProps : {
              palinsesto : item.extendedProps.palinsesto,
              idItem : item.extendedProps.idItem,
              idContent : item.extendedProps.id_content,
              isNew : item.extendedProps.isNew,
              startTime : itemStartTime,
              endTime : itemEndTime,
              duration : duration,
              dayOfWeek : itemDayOfWeek,
              elementsToHide: item.extendedProps.elementsToHide,
              orderedList: item.extendedProps.orderedList
            },
            durationEditable : true,
            startEditable : true
          }
          palinsestoData.eventi.push(evento);
        });
      }
    }
  verifyEmpty()
  {
    if (this.state.current === 0) {
      if (palinsestoData.categoria === 0 || palinsestoData.palinsesto === null || palinsestoData.palinsesto.length === 0 || palinsestoData.items.length === 0){
        Swal.fire({
          icon: 'error',
          text: 'Inserire tutte le informazioni',
        })
        return true;
      }
    }
    return false;
  }
  onChange = current => {
    if (this.verifyEmpty())
    {
      return;
    }
    this.parseData();
    this.setState({ current });
    
  };
  next = () => {
    if (this.verifyEmpty())
    {
      return;
    }
    this.parseData();
    let plus=this.state.current + 1
    this.setState({current: plus})
  }
  prev = () => {
    let minus=this.state.current - 1
    this.setState({current: minus})
  }
  salvaPalinsesto = () => 
  {
    
      axios.post(process.env.REACT_APP_BASE_URL + 'inserisci-palinsesto', palinsestoData )
      .then(function(response) {
        if (response.status === 200) {
          //history.push("palinsesti?tab=palinsesti-sub")
          Swal.fire({ title:"", text:"Operazione eseguita correttamente",icon:"success"}).then(function() { 
            window.location.href ='palinsesti?tab=palinsesti-sub'})
          }
        else {throw new Error(response.status);}
        }).catch(function(error)
        {
          Swal.fire({ title:"", html:"Errore nel salvataggio. <b>" + palinsestoData.palinsesto + "</b>", icon:"error"})
    });
  }
 
  render() {
    const { current } = this.state;

    return (
    <>   
      <Steps style={{margin: '0 auto', width: '90%'}} type="navigation" className = "nav-steps" current={current} onChange={this.onChange}>
        <Step title="informazioni generali" description="Inserisci le informazioni generali per definire il palinsesto." />
        <Step title="Gestione brand" description="Gestisci la distribuzione dei brand durante i giorni della settimana." />
        <Step title="Impostazione template" description="Seleziona il template da associare al palinsesto che stai creando." />
      </Steps>  
      <div className="nuovo-palinsesto">
        {steps[current].content}
        <div className="steps-actions"> 
          {current > 0 && (<Button className="step-previous" onClick={this.prev} >Indietro</Button>)}
          {current < steps.length - 1 && (<Button type="primary" className="step-next"  onClick={this.next}>Avanti</Button>)}
          {current  === 2 &&( <Button type="primary" className="step-next"  onClick={ this.salvaPalinsesto }>Salva palinsesto</Button>)}  
        </div> 
      </div>
    </>
    );
  }
}
export {NuovoPalinsesto as default, palinsestoData as array};
