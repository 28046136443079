import React from 'react';
import axios from 'axios';
import Swal from "sweetalert2";
import 'antd/dist/antd.css';
import { Steps, Button } from 'antd';
import ModificaIniziativa1 from "./modifica-iniziativa-1";
import ModificaIniziativa2 from "./modifica-iniziativa-2";
import ModificaIniziativa3 from "./modifica-iniziativa-3";
import {itemIniziativa} from "./iniziative";
export let iniziativaData = { iniziativa : "", categoria : 0 , template : 0, items : [], eventi : []};
const { Step } = Steps;
const steps = [
{
  title: 'Informazioni generali',
  content: <ModificaIniziativa1 />,
  disabled: false,
  
},
{
  title: 'Gestione brand',
  content: <ModificaIniziativa2 />,
  disabled: false,
},
{
  title: 'Assegnazione template',
  content: <ModificaIniziativa3 />,
  disabled: false,
}];
class ModificaIniziativa extends React.Component { state = { current: 0,};
  loadData = () => {
    iniziativaData.eventi = [];
    iniziativaData.iniziativa = itemIniziativa.nome;
    iniziativaData.uuid = itemIniziativa.uuid;
    iniziativaData.categoria = itemIniziativa.categoria;
    iniziativaData.template = itemIniziativa.template;
   
    fetch(process.env.REACT_APP_BASE_URL + "iniziativa/"+ iniziativaData.uuid )
    .then((res) => res.json())
    .then((res) => {
    if (res.length >0)
    {
      let dataIniziativa = res[0];
      iniziativaData.iniziativa = dataIniziativa.nome;
      iniziativaData.uuid = dataIniziativa.uuid;
      iniziativaData.template = dataIniziativa.template;
      iniziativaData.categoria = dataIniziativa.categoria;
      iniziativaData.items = dataIniziativa.items;
      /**datainiziativa.items.forEach((item) => {
      if (!iniziativaData.items.find((e) => {return e.id === item.id})) {
          iniziativaData.items.push(item);
      }});;**/
      dataIniziativa.eventi.forEach((item) => {
        let evento = {
          id : item.id,
          title : item.title,
          start : item.start,
          end : item.end,
          allDay : false,
          extendedProps : {
            iniziativa : item.uuid_iniziativa,
            idItem : item.id_item,
            idContent : item.id_content,
            isNew : false,
            duration : item.duration,
            dayOfWeek : item.day_of_week,
            elementsToHide: item.elements_to_hide === undefined ? '[]' : item.elements_to_hide,
            orderedList: item.ordered_list === undefined ? '[]' : item.ordered_list
          },
          durationEditable : true,
          startEditable : true
        }
        iniziativaData.eventi.push(evento);
        /**if (!iniziativaData.eventi.find((e) => {return e.id === item.id})) {
         
        }});**/
      });
    }});
  }
  componentDidMount() {
    this.loadData();
  }
  onChange = current => {
    if (this.state.current === 0) {
      if (iniziativaData.categoria === 0 || iniziativaData.iniziativa === null || iniziativaData.iniziativa.length === 0 || iniziativaData.items.length === 0){
        Swal.fire({
          icon: 'error',
          text: 'Inserire tutte le informazioni',
        })
        return
      }
    }
    this.setState({ current });
  };
  next = () => {
    if (this.state.current === 0)
    {
      if (iniziativaData.categoria === 0 || iniziativaData.iniziativa === null || iniziativaData.iniziativa.length === 0 || iniziativaData.items.length === 0)
      {
        Swal.fire({
          icon: 'error',
          text: 'Inserire tutte le informazioni',
        })
        return
      }
    }
    let plus=this.state.current + 1
    this.setState({current: plus})
  }
  prev = () => {
    let minus=this.state.current - 1
    this.setState({current: minus})
  }
  salvainiziativa = () => 
  {
    
      axios.post(process.env.REACT_APP_BASE_URL + 'modifica-iniziativa', iniziativaData )
      .then(function(response) {
        if (response.status === 200) {
          //history.push("palinsesti?tab=palinsesti-sub")
          Swal.fire({ title:"", text:"Operazione eseguita correttamente",icon:"success"}).then(function() { 
            window.location.href ='palinsesti?tab=iniziative'})
          }
        else {throw new Error(response.status);}
        }).catch(function(error)
        {
          Swal.fire({ title:"", html:"Errore nel salvataggio delle modifiche del iniziativa <b>" + iniziativaData.iniziativa + "</b>", icon:"error"})
    });
  }
 
  render() {
    const { current } = this.state;
    
    
    return (
    <>   
      <Steps style={{margin: '0 auto', width: '90%'}} type="navigation" className = "nav-steps" current={current} onChange={this.onChange}>
        <Step title="informazioni generali" description="Inserisci le informazioni generali per definire il iniziativa." />
        <Step title="Gestione brand" description="Gestisci la distribuzione dei brand durante i giorni della settimana." />
        <Step title="Impostazione template" description="Seleziona il template da associare al iniziativa che stai creando." />
      </Steps>  
      <div className="nuovo-palinsesto">
        {steps[current].content}
        <div className="steps-actions"> 
          {current > 0 && (<Button className="step-previous" onClick={this.prev} >Indietro</Button>)}
          {current < steps.length - 1 && (<Button type="primary" className="step-next"  onClick={this.next}>Avanti</Button>)}
          {current  === 2 &&( <Button type="primary" className="step-next"  onClick={ this.salvainiziativa }>Salva iniziativa</Button>)}  
        </div> 
      </div>
    </>
    );
  }
}
export {ModificaIniziativa as default, iniziativaData as array};
