import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { ThemeContext } from "../../../context/ThemeContext";
import pkg from "../../../../package.json";
import logo from "../../../images/logo-full.png";
import { Button } from "@mui/material";
import CircleButtonComponent from "../../components/Button/CircleButton";
import { Menu, MenuOpen } from "@mui/icons-material";
import { THEME_LIGHT_GRAY } from "../../../CONST";

const NavHeader = () => {
  const [toggle, setToggle] = useState(false);
  const { openMenuToggle } = useContext(ThemeContext);

  return (
    <div
      className="nav-header"
      style={{
        zIndex: 8,
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        border: "none",
        boxShadow: "none",
      }}
    >
      <CircleButtonComponent
        color=" "
        bgColor={THEME_LIGHT_GRAY}
        icon={toggle ? <Menu /> : <MenuOpen />}
        onClick={() => setToggle(!toggle)}
        className="menu-button"
      />
      <Link to="/dashboard">
        <div style={{ display: "flex", alignItems: "flex-end", width: 200 }}>
          <img id="imglogo" src={logo} alt="logo" />
          <Typography
            style={{ marginBottom: "-3%", marginLeft: "3%" }}
            variant="caption"
          >
            v{pkg.version}
          </Typography>
        </div>
      </Link>
    </div>
  );
};

export default NavHeader;
