import React from "react";
import templateV from "../../images/templateV.png"
import templateH from "../../images/templateH.png"

import { palinsestoData } from "./modifica-palinsesto";
const ModificaPalinsesto3= () => {
  const handleChange = (e) => {
    palinsestoData.template = e.target.value;
  }
  

   return (<>
      <div className="templates "> 
        <p className="subtab">Seleziona il template da associare al palinsesto che stai creando.</p>
        <label className="template" >
          <input defaultChecked={palinsestoData.template === 0} onChange={handleChange} type="radio" name="test" value="0" />
          <img src= {templateH}  alt="templateH"/>
        </label>
      <label className="template" >
        <input defaultChecked={palinsestoData.template === 1} onChange={handleChange} type="radio" name="test" value="1"  />
        <img src= {templateV}  alt="templateV"/>
      </label>
      
    </div>

</>

   );
};

export default ModificaPalinsesto3;