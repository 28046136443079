import React, { Fragment, useState } from "react";
import SideBar from "./SideBar";
import NavHeader from "./NavHeader";
import Header from "./Header";
import ChatBox from "../ChatBox";
import { THEME_WHITE } from "../../../CONST";

const JobieNav = ({ title, onClick: ClickToAddEvent, onClick2, onClick3 }) => {
  const [toggle, setToggle] = useState("");
  const onClick = (name) => setToggle(toggle === name ? "" : name);
  return (
    <div
      style={{
        position: "fixed",
        left: 0,
        right: 0,
        zIndex: 999,
        backgroundColor: THEME_WHITE,
        borderBottom: "3px solid red",
      }}
    >
      <NavHeader
        style={{
          zIndex: 999,
          position: "relative",
        }}
      />
      <Header
        style={{
          zIndex: 999,
          position: "relative",
        }}
        onNote={() => onClick("chatbox")}
        onNotification={() => onClick("notification")}
        onProfile={() => onClick("profile")}
        toggle={toggle}
        title={title}
        onBox={() => onClick("box")}
        onClick={() => ClickToAddEvent()}
      />
      <SideBar />
    </div>
  );
};

export default JobieNav;
