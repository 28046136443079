import {useState, useEffect} from "react";
import {Tabs, Tab} from "react-bootstrap";
import {useHistory} from "react-router-dom";
import {default as MessaggiInviati} from "./messaggi-inviati";
import {default as MessaggiImpostazioni} from "./messaggi-impostazioni";
import {default as TuttiIMessaggi} from "./tutti-i-messaggi";
import getQueryVariable from "../functions/getQueryVariable";
import axios from 'axios';

function Messaggi() {
  const [modelsMessages] = useState([]);
  const [currentUser] = useState(JSON.parse(localStorage.getItem('userDetails','undefined')))
  const tab = getQueryVariable('tab');
  
  let titolo = "Messaggi"
  if (tab === "nuovo-messaggio"){
    titolo = "Messaggi > Nuovo messaggio"
  }
  
  const [key, setKey] = useState(tab);
  const history = useHistory();
  
  const getModels = () =>{
    try
    {
      modelsMessages.push({id : 0, messaggio : "", categoria : 0, id_utente : currentUser.userid });
      modelsMessages.push({id : 0, messaggio : "", categoria : 1, id_utente : currentUser.userid });
      modelsMessages.push({id : 0, messaggio : "", categoria : 2, id_utente : currentUser.userid });
      modelsMessages.push({id : 0, messaggio : "", categoria : 3, id_utente : currentUser.userid });
      
      var data = {id_user : currentUser.userid, token : currentUser.idToken}
      axios.post(process.env.REACT_APP_BASE_URL + 'get-models', data).then(res =>{
        if (res.status === 200)
        {
          if (res.data.length > 0)
          {
            var data = res.data;
            data.forEach((i) => 
            {
                modelsMessages.forEach(m => {
                  if (m.categoria === i.categoria)
                  {
                    m.messaggio = i.messaggio;
                    m.id = i.id;
               }});
            });
          }
        }
      }).catch((error) => {console.log(error)});
    }
    catch(error)
    {
      console.log(error);

    }
    
  }
  /* eslint-disable */
  useEffect(() => {getModels();},[])

  return (
    <><h2 className="tab-menu">{titolo}</h2>
    <Tabs id="controlled-messaggi" activeKey={key} onSelect={(k) => { history.push('messaggi?tab=' + k);  setKey(k);}} 
   
    className="mb-3 h4 custom-tab-1">
        <Tab eventKey="tutti-i-messaggi" title="In arrivo">
          <TuttiIMessaggi currentUser={currentUser} models={modelsMessages}/>
        </Tab>
        <Tab eventKey="messaggi-inviati" title="Inviati">
          <MessaggiInviati currentUser={currentUser} models={modelsMessages}/>
        </Tab>
        <Tab eventKey="impostazioni-messaggi" title="Impostazioni">
          <MessaggiImpostazioni currentUser={currentUser} />
        </Tab>
    </Tabs>
    </>
  );
}
export default Messaggi;
  