import { useEffect, useState, useRef} from "react";
import { Button} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import axios from 'axios';
import {itemContenuti} from "./contenuti";
function ContenutoCategorie() {
  const chkRef = useRef();
  const history = useHistory();
  const [itemsCategorie, setItemsCategorie] = useState([]);
  const [itemsAggregations, setItemsAggregations] = useState([]);

  const fetchCategorie= () => {
    fetch(process.env.REACT_APP_BASE_URL + "contenuti-categorie/" + itemContenuti.id)
    .then((res) => res.json())
    .then((res) => {
        setItemsAggregations(res);
        fetch(process.env.REACT_APP_BASE_URL + "categorie")
        .then((res) => res.json())
        .then((res) => {
            setItemsCategorie(res);
        });
    });
   
  }
  const toggleItem = (i,e) => 
  {
    const data = {};
    data['id_categoria'] = i.id;
    data['id_contenuto'] = itemContenuti.id;

    if (e.checked) {
      axios.post(process.env.REACT_APP_BASE_URL + "inserisci-categoria-contenuto", data)
      .then(function(response) {
        if (response.status === 200) {
          if (!itemContenuti.categorie.find((item) => { return item.id === i.id; })) {itemContenuti.categorie.push(i);}
        }
        else {throw new Error(response);}}).catch(function(error){});
    }
    else {
      axios.post(process.env.REACT_APP_BASE_URL + "rimuovi-categoria-contenuto", data)
        .then(function(response) {
        if (response.status === 200) {
          if (itemContenuti.categorie.find((item) => {return item.id === i.id; }))
          {
            itemContenuti.categorie.splice(itemContenuti.categorie.indexOf(i),1);      
          }
        }
        else {throw new Error(response);}}).catch(function(error){});
      
    }    
}
const getChecked = (itemId) => 
{
  try
  {
    return itemsAggregations[0].categorie.find((item) => { return item.id === itemId; });
  }
  catch(error)
  {
    return false;
  }
}


useEffect(() => { fetchCategorie(); }, []); 
if (itemsCategorie.length === 0)
{
  return (<div className="App">Caricamento in corso...</div>);
}
 
  
return(
  <>
    <div className="nuovo-palinsesto">  
      <div className="buttons-top-right">
        <Button className="btn btn-outline-primary rounded-1 btn-sm" onClick={() => {history.push('contenuti?tab=cartelle');}} type="button">Indietro</Button>
      </div>
      <p className="subtab">Gestisci l'associazione del contenuto <b>{itemContenuti.nome} </b>alle categorie</p>
      <ul>
        {itemsCategorie.map(item => (
           <li> 
           <input type="checkbox" id={"brand" + item.id} ref={chkRef} defaultChecked={getChecked(item.id)} onChange={(e) => { toggleItem(item,e.currentTarget); } }/>
           <label for={"brand" + item.id}>
             {item.nome}<br /><img className="" src= {process.env.REACT_APP_BASE_URL_CONTENTS + item.immagine} alt="" />
             <div style={{ 
				  backgroundColor: item.colore, 
				  borderRadius:"10px 10px 0 0", 
				  width:"60%", 
          height:"8px",
				  marginLeft:"auto",
			    marginRight:"auto",
				  paddingTop:"0",
				  paddingBottom:"0",
          marginBottom:"-6px"
			   }}></div>
           </label>
           </li>   
        ))}
      </ul> 
    </div>
  </>
  ) 
} 							

export default ContenutoCategorie
