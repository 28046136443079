import { Typography } from "@material-ui/core";

const CustomHeader = (props) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        marginBottom: "12px",
      }}
    >
      <Typography
        variant="h4"
        style={{
          textAlign: "center",
          padding: "20px",
          borderLeft: "0.3rem solid rgb(34, 106, 228)",
        }}
      >
        {props.title}
      </Typography>
      {props.children}
    </div>
  );
};

export default CustomHeader;
