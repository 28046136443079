export const BASEPATH = "";

/*COLORS*/
export const THEME_RED = "#d0021b";
export const THEME_WHITE = "#FFFFFF";
export const THEME_LIGHT_GRAY = "#EBEBEB";
export const THEME_GRAY = "#5E6266";
export const THEME_BLUE = "#4885ED";

/*TINYMCE*/
export const API_KEY="dco46zttpjftz9nczj7keskp9vh43pdscra2r8xgqoprc6tf"