import { MDBDataTableV5 } from 'mdbreact'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'
import swal from 'sweetalert'
import Swal from 'sweetalert2'
import catalogo from '../../images/catalogo.png'
import posteraudio from '../../images/posteraudio.png'
//import postervideo from '../../images/postervideo.png'
import axios from 'axios'
import { Modal, Button } from 'react-bootstrap'
import Messaggio from './messaggio'
import getQueryVariable from '../functions/getQueryVariable'
export let itemContenuti = []

export const setItemContenuti = (item) => {
    itemContenuti = item
}

function Contenuti(props) {
    const [currentUser] = useState(JSON.parse(localStorage.getItem('userDetails', 'undefined')))
    const [currentFolder] = useState(props.currentFolder)
    const [modelsMessages] = useState([])
    const [itemsContenuti, setItemsContenuti] = useState(props.items)
    const [itemBrands, setItemsBrands] = useState([])
    const [itemsForRender, setItemsForRender] = useState([])
    const history = useHistory()
    const [show, setShow] = useState(false)
    const [messageData, setMessageData] = useState({})
    const [titleComponent, setTitleComponent] = useState('Nuovo Messaggio')
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)
    useEffect(() => {
        let tab = getQueryVariable('tab')
        if (tab === 'cartelle') {
            props.visualizzaCartelle()
        } else {
            props.visualizzaCartella(currentFolder)
        }
    }, [getQueryVariable('tab')])
    const getModels = () => {
        try {
            modelsMessages.push({ id: 0, messaggio: '', categoria: 0, id_utente: currentUser.userid })
            modelsMessages.push({ id: 0, messaggio: '', categoria: 1, id_utente: currentUser.userid })
            modelsMessages.push({ id: 0, messaggio: '', categoria: 2, id_utente: currentUser.userid })
            modelsMessages.push({ id: 0, messaggio: '', categoria: 3, id_utente: currentUser.userid })

            var data = { id_user: currentUser.userid, token: currentUser.idToken }
            axios
                .post(process.env.REACT_APP_BASE_URL + 'get-models', data)
                .then((res) => {
                    if (res.status === 200) {
                        if (res.data.length > 0) {
                            var data = res.data
                            data.forEach((i) => {
                                modelsMessages.forEach((m) => {
                                    if (m.categoria === i.categoria) {
                                        m.messaggio = i.messaggio
                                        m.id = i.id
                                    }
                                })
                            })
                        }
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        fetchBrands()
    }, [])
    /* eslint-disable */
    useEffect(() => {
        getModels()
    }, [])
    const handleChangeBrand = (e) => {
        if (e.target.value !== '0') {
            let brand = itemBrands.find((b) => {
                return b.id === parseInt(e.target.value)
            })
            let filtered = props.items.filter((i) =>
                i.brands.find((bb) => {
                    return bb.id === brand.id
                })
            )
            if (filtered.length === 0) {
                setItemsContenuti([{ id: '-1', nome: 'Nessun contenuto presente!' }])
                return
            }
            setItemsContenuti(filtered)
        } else {
            setItemsContenuti(props.items)
        }
    }
    const fetchBrands = () => {
        fetch(process.env.REACT_APP_BASE_URL + 'brands')
            .then((res) => res.json())
            .then((res) => {
                if (res.length === 0) {
                    setItemsBrands([{ id: '-1', nome: 'Nessun brands presente!' }])
                    return
                }
                setItemsBrands(res)
            })
    }
    const calcSize = (s) => {
        let size = '0 Bytes'
        let um = ' Bytes'
        if (s > 1024) {
            size = s / 1024
            um = ' KB'
        }
        if (size > 1024) {
            size = size / 1024
            um = ' MB'
        }
        if (size > 1024) {
            size = size / 1024
            um = ' TB'
        }
        return size.toLocaleString(undefined, { maximumFractionDigits: 2 }) + um
    }
    useEffect(() => {
        setTimeout(() => {
            var buttons = document.getElementsByClassName('page-link')
            if (buttons.length > 0) {
                for (var i = 0; i < buttons.length; i++) {
                    buttons[i].onclick = function () {
                        window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: 'smooth'
                        })
                    }
                }
            }
        }, 1000)
    }, [])

    function inviaMessaggio(item) {
        let genre = 'o'
        if (item.tipologia === 'Immagine') {
            genre = 'a'
        }

        let subject = 'Nuov' + genre + ' ' + item.tipologia + ': ' + item.nome
        let body = modelsMessages[1].messaggio
        let locationItem = process.env.REACT_APP_BASE_URL_SERVER + 'contenuti?tab=contenuti&action=contenuto&item='
        body = body.replace('((%contenuto%))', '<a href="' + locationItem + item.id + '">' + item.nome + '</a>')
        body += modelsMessages[0].messaggio
        //let body = "Un nuovo contenuto è disponibile: " + item.tipologia + " " + item.nome + " " + item.descrizione + "\nE' disponibile  <a href='https://dev.22hbg.com/euracom/tutti-i-contenuti?item="+ item.file + "'>cliccando qui</a>";
        setTitleComponent('Condividi ' + item.nome)
        setMessageData({ body: body, subject: subject, isresponse: false, issharing: true })
        handleShow()
    }

    const SweetAlertCustomButtons = (item) => {
        itemContenuti = item
        swal({
            title: 'Gestione associazioni',
            text: 'gestisci le associazioni ai brands, alle categorie e ai tags di ' + item.nome,
            icon: 'info',
            showConfirmButton: false,
            showCancelButton: false,
            buttons: {
                buttonOne: {
                    text: 'Brands',
                    value: 'brands',
                    visible: true,
                    className: 'customSwalBtn',
                    closeModal: true
                },
                buttonTwo: {
                    text: 'Categorie',
                    value: 'categorie',
                    visible: true,
                    className: 'customSwalBtn',
                    closeModal: true
                },
                buttonThree: {
                    text: 'Tags',
                    value: 'tags',
                    visible: true,
                    className: 'customSwalBtn',
                    closeModal: true
                },
                buttonFour: {
                    text: 'Chiudi',
                    value: 'chiudi',
                    visible: true,
                    className: 'customSwalBtnClose',
                    closeModal: true
                }
            }
        }).then((result) => {
            if (result === 'brands') {
                history.push('contenuti?tab=contenuti&action=associazione-brands')
            } else if (result === 'categorie') {
                history.push('contenuti?tab=contenuti&action=associazione-categorie')
            } else if (result === 'tags') {
                history.push('contenuti?tab=contenuti&action=associazione-tags')
            }
        })
    }

    const deleteContent = (item) => {
        const dataDel = {}
        dataDel['id'] = item.id
        dataDel['filename'] = item.file
        fetch(process.env.REACT_APP_BASE_URL + 'elimina-contenuto/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(dataDel)
        })
            .then(function (response) {
                if (response.status === 200) {
                    Swal.fire({ title: '', text: 'Operazione eseguita correttamente', icon: 'success' }).then(function () {
                        fetchItems()
                    })
                }
            })
            .catch(function (error) {
                Swal.fire({ title: '', text: 'Impossibile eliminare il contenuto selezionato', icon: 'error' })
            })
    }

    const visualizzaContenuto = (item) => {
        let videoTag = 'none'
        let imageTag = 'none'
        let docTag = 'none'
        let downloadButton = ''
        let ext = item.file.split('.').pop().toLowerCase()
        if (ext === 'jpg' || ext === 'jpeg' || ext === 'png' || ext === 'bmp' || ext === 'gif') {
            imageTag = ''
            videoTag = 'none'
            docTag = 'none'
        } else if (
            ext === 'mp4' ||
            ext === 'm4v' ||
            ext === 'mpg' ||
            ext === 'mpeg' ||
            ext === 'wmv' ||
            ext === 'ts' ||
            ext === 'mkv' ||
            ext === 'avi' ||
            ext === 'ps'
        ) {
            imageTag = 'none'
            videoTag = ''
            docTag = 'none'
        } else if (ext === 'mp3' || ext === 'wav' || ext === 'wma' || ext === 'aac' || ext === 'ac3' || ext === 'mp2') {
            imageTag = 'none'
            videoTag = ''
            docTag = 'none'
        } else if (ext === 'pdf' || ext === 'xlsx' || ext === 'xls' || ext === 'docx' || ext === 'doc' || ext === 'csv' || ext === "ppt" || ext === "pptx") {
            imageTag = 'none'
            videoTag = 'none'
            docTag = ''
            downloadButton =
                '<a href="' +
                process.env.REACT_APP_BASE_URL_CONTENTS +
                item.file +
                '" download="' +
                item.nome +
                "\" target='_blank'><i style='font-size:30px;' class='bi bi-cloud-arrow-down'></i></a>"
        }
        let brands = ' - '
        let categorie = ' - '
        let tags = ' - '
        let pubblicato = 'Pubblicato'
        if (item.pubblicato === 0) {
            pubblicato = 'Non pubblicato'
        }
        item.brands.forEach((i) => (brands += i.nome + ' - '))
        item.categorie.forEach((i) => (categorie += i.nome + ' - '))
        item.tags.forEach((i) => (tags += i.nome + ' - '))
        Swal.fire({
            html:
                "<video height='160px' style='display:" +
                videoTag +
                ";' controls src='" +
                process.env.REACT_APP_BASE_URL_CONTENTS +
                item.file +
                "' ></video><img height='160px' style='display:" +
                imageTag +
                ";' src='" +
                process.env.REACT_APP_BASE_URL_CONTENTS +
                item.file +
                "' alt='anteprima immagine' /><img height='160px' style='display:" +
                docTag +
                ";' src='" +
                catalogo +
                "' alt='catalogo' /><div style='width: 100%; text-align:right;'>" +
                downloadButton +
                "</div><table class='dettagli-contenuto'><tr><th>Nome</th><td>" +
                item.nome +
                ' </td></tr><tr><th>Descrizione</th><td>' +
                item.descrizione +
                '</td></tr><tr><th>Tipo</th><td>' +
                item.tipologia +
                '</td></tr><tr><th>Dimensione</th><td>' +
                calcSize(item.dimensione) +
                '</td></tr><tr><th>Brands</th><td>' +
                brands +
                '</td></tr><tr><th>Categorie</th><td>' +
                categorie +
                '</td></tr><tr><th>Tag</th><td>' +
                tags +
                '</td></tr><tr><th>Stato</th><td>' +
                pubblicato +
                '</td></tr><tr><th>Validit&agrave;</th><td>' +
                new Date(item.data_inizio_pubblicazione).toLocaleDateString('it', { weekday: 'long', day: '2-digit', month: '2-digit', year: 'numeric' }) +
                ' <br /> ' +
                new Date(item.data_fine_pubblicazione).toLocaleDateString('it', { weekday: 'long', day: '2-digit', month: '2-digit', year: 'numeric' }) +
                '</td></tr></table>',
            showCancelButton: false,
            confirmButtonText: 'OK',
            reverseButtons: true
        })
    }
    useEffect(() => {
        setItemsContenuti(props.items)
    }, [props.items])

    useEffect(() => {
        let itemsArray = itemsContenuti
        let ContenutoData = []

        itemsArray.map((item) => {
            if (item.id !== '-1') {
                let mediaElementControl = 'mp3|mp4|wma|wmv|mkv|ogg|m4v|m4a|avi|wav|mpeg|ts|ps|aac|ac3|mp2'
                let docElementControl = 'doc|docx|xls|xlsx|pdf|csv|rtf|odt|ppt|pptx'
                if (mediaElementControl.includes(item.file.split('.').pop())) {
                    item.preview = (
                        <div style={{ backgroundColor: 'black!important;' }} className="video_anteprima">
                            <video
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                                id={item.id}
                                poster={item.tipologia === 'Audio' ? posteraudio : ''}
                                src={process.env.REACT_APP_BASE_URL_CONTENTS + item.file}
                                controls
                            />
                        </div>
                    )
                } else if (docElementControl.includes(item.file.split('.').pop())) {
                    item.preview = (
                        <div style={{ backgroundColor: 'black' }} className="immagine_anteprima">
                            <img src={catalogo} alt="catalogo" />
                        </div>
                    )
                } else {
                    item.preview = (
                        <div style={{ backgroundColor: 'black' }} className="immagine_anteprima">
                            <img src={process.env.REACT_APP_BASE_URL_CONTENTS + item.file} alt="anteprima immagine" />
                        </div>
                    )
                }

                item.published = item.pubblicato ? (
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                        <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
                    </svg>
                ) : (
                    ''
                )
                let startDate = item.inizio
                    ? new Date(item.data_inizio_pubblicazione).toLocaleDateString('it', { day: '2-digit', month: '2-digit', year: 'numeric' })
                    : '--/--/----'
                let endDate = item.fine
                    ? new Date(item.data_fine_pubblicazione).toLocaleDateString('it', { day: '2-digit', month: '2-digit', year: 'numeric' })
                    : '--/--/----'
                item.date = startDate + '   ' + endDate
                item.size = calcSize(item.dimensione)
                item.action = (
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <a
                            className="c-pointer button-action"
                            onClick={() => {
                                inviaMessaggio(item)
                            }}
                        >
                            <i className="bi bi-share-fill"></i>{' '}
                        </a>
                        &nbsp;&nbsp;&nbsp;
                        <a
                            className="c-pointer button-action"
                            onClick={() => {
                                visualizzaContenuto(item)
                            }}
                        >
                            <i className="bi bi-eye-fill"></i>{' '}
                        </a>
                        &nbsp;&nbsp;&nbsp;
                        <a
                            hidden={currentUser.tipo > 2}
                            className="c-pointer button-action"
                            onClick={() => {
                                SweetAlertCustomButtons(item)
                            }}
                        >
                            <i className="bi bi-diagram-3-fill"></i>
                        </a>
                        &nbsp;&nbsp;&nbsp;
                        <Link
                            hidden={currentUser.tipo > 2}
                            className="c-pointer button-action"
                            onClick={() => {
                                itemContenuti = item
                            }}
                            to={() => {
                                return 'contenuti?tab=contenuti&action=modifica'
                            }}
                        >
                            <i className="bi bi-pencil-fill"></i>
                        </Link>
                        &nbsp;&nbsp;&nbsp;
                        <a
                            hidden={currentUser.tipo > 2}
                            className="c-pointer button-action"
                            onClick={() =>
                                Swal.fire({
                                    title: 'Confermi?',
                                    html:
                                        "<div style='padding:10px;'><span>" +
                                        item.descrizione +
                                        '</span> Attenzione, il contenuto <b> ' +
                                        item.nome +
                                        '</b> sarà eliminato!</div>',
                                    showCancelButton: true,
                                    confirmButtonText: 'Elimina',
                                    cancelButtonText: 'Annulla',
                                    reverseButtons: false,
                                    icon: 'question'
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        deleteContent(item)
                                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                                        Swal.fire('Operazione annullata')
                                    }
                                })
                            }
                        >
                            <i className="bi bi-trash-fill"></i>
                        </a>{' '}
                        &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                    </div>
                )
            }
            ContenutoData.push(item)
        })
        setItemsForRender(ContenutoData)
    }, [itemsContenuti])

    const data = {
        columns: [
            {
                label: 'Anteprima',
                field: 'preview',
                sort: 'disabled',
                width: 320
            },
            {
                label: 'Nome',
                field: 'nome',
                sort: 'asc',
                width: 270
            },
            {
                label: 'Descrizione',
                field: 'descrizione',
                sort: 'disabled',
                width: 270
            },
            {
                label: 'Tipo',
                field: 'tipologia',
                sort: 'disabled',
                width: 270
            },
            {
                label: 'Pubblicato',
                field: 'published',
                sort: 'disabled',
                width: 0
            },
            {
                label: 'Date Inizio/Fine',
                field: 'date',
                sort: 'disabled',
                width: 0
            },

            {
                label: 'Azione',
                field: 'action',
                sort: 'disabled',
                width: 150
            }
        ],
        rows: itemsForRender
    }
    return (
        <div className="contenuti ">
            <Modal dialogClassName="modal-messages" enforceFocus={false} show={show} onRequestClose={handleClose}>
                <Modal.Body>
                    <Button style={{ float: 'right' }} variant="link" onClick={handleClose}>
                        <i style={{ fontSize: '120%' }} className="bi bi-x"></i>
                    </Button>
                    <Messaggio onCloseModal={handleClose} message={messageData} title={titleComponent} />
                </Modal.Body>
            </Modal>
            <p className="subtab">Contenuti {props.currentFolder.nome}</p>
            <span>
                <a hidden={currentUser.tipo > 2} onClick={() => history.push('contenuti?tab=contenuti&action=wizard')} className="wizard">
                    <i className="fa fa-magic" aria-hidden="true"></i>&nbsp;&nbsp; Wizard
                </a>
                <a
                    className="indietro"
                    onClick={() => {
                        history.push('contenuti?tab=cartelle')
                        props.visualizzaCartelle()
                    }}
                >
                    indietro
                </a>
            </span>
            <select onChange={handleChangeBrand} name="selection" className="select-brand">
                <option value={0}>Tutti i contenuti</option>
                {itemBrands.map((iBrand) => (
                    <option value={iBrand.id}>{iBrand.nome}</option>
                ))}
            </select>
            <MDBDataTableV5
                hover
                data={data}
                proSelect
                searchTop={true}
                searchBottom={false}
                fullPagination
                searchLabel={'Ricerca Contenuto ...'}
                responsive
                disableRetreatAfterSorting={true}
            />
        </div>
    )
}
export { Contenuti as default, itemContenuti as array }
