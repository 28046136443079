import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store/store";
import reportWebVitals from "./reportWebVitals";
import SimpleReactLightbox from "simple-react-lightbox";
import ThemeContext from "./context/ThemeContext";
import { BASEPATH } from "./CONST";
{
  <script
    src="https://cdn.tiny.cloud/1/dco46zttpjftz9nczj7keskp9vh43pdscra2r8xgqoprc6tf/tinymce/5/tinymce.min.js"
    referrerpolicy="origin"
  ></script>;
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <SimpleReactLightbox>
        <BrowserRouter basename={`/${BASEPATH}`}>
          <ThemeContext>
            <App />
          </ThemeContext>
        </BrowserRouter>
      </SimpleReactLightbox>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
reportWebVitals();
