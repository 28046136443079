import React, { useState, useEffect, useRef } from 'react';
import { Button} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import { ProgressBar } from 'react-bootstrap';
import axios from 'axios';
import Swal from "sweetalert2";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import it from "date-fns/locale/it";
import moment from 'moment';
import catalogo from '../../images/catalogo.png';

registerLocale("it", it);
function NuovoContenuto() {
   
    const mediaRef = useRef();
    const imageRef = useRef();
    const tipiRef = useRef();
    //const [file, setFile] = useState(''); // storing the uploaded file
    const history = useHistory();
    const [itemsTipi, setItemsTipi] = useState([]);
    const [uploadValue, setUploadValue] = useState(0);
    const [disabledSend, setDisabledSend] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());  
      

    const config = {
        onUploadProgress: progressEvent => 
        {
            try
            {
                setDisabledSend(true);
                setUploadValue(Math.round((progressEvent.loaded * 100) / progressEvent.total));
            }
            catch(error)
            {
                
            }
        }
    }
    const fetchTipi= () => {
        fetch(process.env.REACT_APP_BASE_URL + "tipi-contenuto")
        .then((res) => res.json())
        .then((res) => {
            setItemsTipi(res);
        });
        
    }
    useEffect(() => { fetchTipi(); }, []);
    useState(() => {
        setTimeout(() => {
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth"
            });
          }, 100);
    },[]);
    const handleChange = (e) => {
        try
        {
            const file = e.target.files[0]; 
            //console.log(file);
            //setFile(file); 
            if (file.name)
            {
                setPreview(file.name, file);
            }
        }
        catch(error)
        {
            Swal.fire({ title:"", text:"Tipo di file non riconosciuto o non consentito!",icon:"error"});
            e.target.value = null;
        }
    }
    
    const setPreview = (filename, file) =>
    {
        let previewVideo = mediaRef.current;
        let previewImage = imageRef.current;
                
        let durata = document.getElementById('durata'); 
        let ext = filename.split('.').pop().toLowerCase();
        if (ext === "jpg" || ext === "jpeg" || ext === "png" || ext === "bmp" || ext === "gif" || ext === "svg" )
        {
            previewImage.src = URL.createObjectURL(file);
            durata.value = 30;
            previewVideo.hidden = true;
            previewImage.hidden = false;
            
        }
        else if  (ext === "mp4" || ext === "m4v" || ext === "mpg" || ext === "mpeg" ||  ext === "wmv" || ext === "ts" || ext === "mkv" || ext === "avi" || ext === "ps")
        {
            previewVideo.src =  URL.createObjectURL(file);
            previewVideo.preload = "metadata";                               
            previewVideo.addEventListener("loadedmetadata", function() { durata.value = Math.floor( previewVideo.duration) ; });
            previewVideo.hidden = false;
            previewImage.hidden = true;
            
        }        
        else if  (ext === "mp3" || ext === "wav" || ext === "wma" || ext === "aac" || ext === "ac3" || ext === "mp2")
        {
            previewVideo.src =  URL.createObjectURL(file);
            previewVideo.poster = ""
            previewVideo.preload = "metadata";                               
            previewVideo.addEventListener("loadedmetadata", function() { durata.value = Math.floor( previewVideo.duration) ; });
            previewVideo.hidden = false;
            previewImage.hidden = true;
            
        }
        else if  (ext === "doc" || ext === "docx" || ext === "pdf" || ext === "xls" || ext === "xlsx" || ext === "csv" || ext === "ppt" || ext === "pptx")
        {
            previewImage.src = catalogo;
            durata.value = 30;
            previewVideo.hidden = true;
            previewImage.hidden = false;
            
        }
        else
        {
            if (ext !== "")
            {
                Swal.fire({ title:"", text:"Tipo di file " + ext + " non consentito!",icon:"error"});
            }
        }
    }
    
    /**const togglePlaPause = () =>
    {
        let preview = document.getElementById('content');
        if (!preview.paused)
        {
            preview.pause();
            preview.position = 0;
        }
        else
        {
            preview.play();
        }
    }**/
    const handleSubmit = event => {
        event.preventDefault();
        setUploadValue(0);
        if (tipiRef.current.value === 0)            
        {
            Swal.fire({ title:"", text:"Impostare il tipo!",icon:"error"});
            return;
        }
        const form = event.target;
        const fileup = event.target.up_file.files[0];
        const data = new FormData();
        for (let i=0; i < form.elements.length; i++) {
            const elem = form.elements[i];
            if (elem.name === "published")
            {
                data.append(elem.name, elem.checked ? 1: 0);
            }
            else if (elem.name === "start_date")
            {
                data.append(elem.name, moment(startDate).format("YYYY-MM-DDTHH:mm:ss"));
            }
            else if (elem.name === "end_date")
            {
                data.append(elem.name, moment(endDate).format("YYYY-MM-DDTHH:mm:ss"));
            }
            else
            {
                data.append(elem.name, elem.value);
            }
        }
        let ext =  fileup.name.split('.').pop();
        let fname = uuidv4() + "." + ext;
        data.append('file',fileup, fname );
        data.append('nome_brand',"contents" );
        axios.post(process.env.REACT_APP_BASE_URL+'nuovo-contenuto',data, config,{
            headers: {
                'accept': 'application/json',
                'Accept-Language': 'en-US,en;q=0.8',
                'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
            }
            })
            .then(function(response) {
                if (response.status === 200) {
                   
                    setDisabledSend(false);
                    Swal.fire({ title:"", text:"Contenuto caricato con successo",icon:"success"});
                    history.push('contenuti?tab=tutti-i-contenuti')
                }
                else {
                    alert(response.text)
                    throw new Error(response.status);}
                }).catch(function(error)
                {
                    console.log(error);
                    try
                    {
                        setDisabledSend(false);
                        if (axios.isCancel(error)) { Swal.fire({ title:"", text:"Caricamento non completato!",icon:"warning"})} 
                        else {Swal.fire({ title:"", text:"Errore caricamento",icon:"error"})}
                   
                    }
                    catch(err){}
                });
    }
    
    return (
     <>
        <div className="card">
            <p className="pform">Nuovo Contenuto</p>
            <div className="card-bodyx">
                
                <form controlId="formContenuto" onSubmit={handleSubmit} encType='multipart/form-data'>
                    <div className="form-row" >
                        <label  className="form-label">ANTEPRIMA</label>
                        <video ref={mediaRef} height='180px' style={{maxHeight: "180px", minHeight: "180px" }} controls hidden={true} alt ="" />&nbsp;&nbsp;&nbsp;
                        <img ref={imageRef} height='180px' style={{maxHeight: "180px", minHeight: "180px" }} hidden={true} alt ="" />
                    </div>
                    
                    <div hidden={disabledSend} className="form-row">
                        <label  className="form-label">FILE</label>
                        <input className="form-input" type="file" name="up_file" onChange={handleChange} required/>
                    </div>  
                    <div hidden={!disabledSend} className="form-row" >
                        <label className="form-label">UPLOAD %</label>
                        <div className="form-input">
                            <ProgressBar animated now={uploadValue} />
                        </div>
                    </div>
                    <div className="form-row">
                        <label  className="form-label">TITOLO</label>
                        <input className="form-input" type="text" placeholder='Inserire il titolo del contenuto' name="nome" required/>
                    </div>
                    <div className="form-row">
                        <label  className="form-label">DESCRIZIONE</label>
                        <input className="form-input" type="text" placeholder='Inserire la descrizione del contenuto' name="descrizione" required/>
                    </div>
                    <div className="form-row">
                        <label  className="form-label">TIPO</label>
                        <select id="id_tipo" name="id_tipo" className="form-select-option" ref={tipiRef}>
                            <option value="0" >Seleziona il Tipo</option> {itemsTipi.map(itemsTipi => ( <option value={itemsTipi.id} >{itemsTipi.nome}</option> ))}
                        </select>
                    </div>
                    
                   
                <div className='form-row'>
                    <label  className="form-label">PUBBLICATO</label>   
                    <div className="form-input">
                    <input  name='published' type='checkbox' /></div>
                </div> 
                <div className="form-row">  
                <label  className="form-label">VALIDIT&Agrave;</label>
                <div className="form-select-option-data">
                <div style={{display:'table'}}>
                <div style={{display:'table-row'}}>
                <div style={{display:'table-cell'}}>
                 <span>DA</span></div>
                <div style={{display:'table-cell'}}>
                   <DatePicker
                    name="start_date"
                    dateFormat="dd/MM/yyyy"
                    locale="it"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    />
                    </div><div style={{display:'table-cell'}}>
                <span className='s1'>A</span></div>
                <div style={{display:'table-cell'}}>
                    <DatePicker
                    name="end_date"
                    dateFormat="dd/MM/yyyy"
                    locale="it"
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate} style={{float:"left"}}
                    />
                    </div></div></div>
                </div>
            </div>
            <div className="form-row">
                        <label  className="form-label">DURATA (Secondi)</label>
                        <input className="form-input" type="text" placeholder='Inserire la durata del contenuto' name="durata" id="durata" required/>
                    </div> 
            <div className="form-row">
                <div className="form-buttons">
                    <Button disabled={disabledSend} className="btn btn-outline-primary rounded-1 btn-sm" type="submit">Invia</Button>
                    <Button disabled={disabledSend} className="btn btn-outline-primary rounded-1 btn-sm" type="button" onClick={() => {history.push('contenuti');}}>Annulla </Button>
                    </div>
                </div>
            </form>
        </div>
    </div>
     </>
    )}
    export default NuovoContenuto;