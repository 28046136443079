import { MDBDataTableV5 } from 'mdbreact'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { setItemAnag } from './anagrafiche'
import Swal from 'sweetalert2'
export let azienda = []

function DatiAziendaliTabella(props) {
    const [items, setItems] = useState([])

    const handleRowClick = (event) => {
    }

    function deleteQuestion(item) {
        Swal.fire({
            title: "Confermi l'eliminazione del punto vendita " + item.nome + '?',
            //html: "<div style='padding:10px;'><span style='background-color:" + item.colore + "; margin-right:20px;' class='c-color rounded-circle'></span> Attenzione, il tag <b> " + item.nome + "</b> sarà eliminato!</div>",
            showCancelButton: true,
            confirmButtonText: 'Elimina',
            cancelButtonText: 'Annulla',
            reverseButtons: true,
            icon: 'question'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteAnagrafica(item.id)
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire({ text: 'Operazione annullata', icon: 'error' })
            }
        })
    }

    function deleteAnagrafica(idAnagrafica) {
        const dataDel = {}
        dataDel['id'] = idAnagrafica
        fetch(process.env.REACT_APP_BASE_URL + 'elimina-punto-vendita', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(dataDel)
        })
            .then(function (response) {
                if (response.status === 200) {
                    Swal.fire({ title: '', text: 'Operazione eseguita correttamente', icon: 'success' }).then(function () {
                        getData()
                    })
                }
            })
            .catch(function (error) {
                Swal.fire({ title: '', text: "Impossibile eliminare l'anagrafica selezionata", icon: 'error' })
            })
    }

    useEffect(() => {
        getData()
    }, [])

    const getData = () => {
        fetch(process.env.REACT_APP_BASE_URL + `punti-vendita/1/${props.companyId}`)
            .then((res) => res.json())
            .then((res) => {
                if (res.length === 0) {
                    setItems([{ id: -1, nome: 'Nessun punto vendita presente!' }])
                    return
                }

                let userData = []

                res.map((item, index) => {
                    if (item.id > 0) {
                        if (res[index].id > 1) {
                            item.action = (
                                <div style={{ display: 'flex' }}>
                                    <Link
                                        className="c-pointer button-action"
                                        onClick={() => {
                                            setItemAnag(item)
                                        }}
                                        to={() => {
                                            return 'impostazioni?tab=anagrafiche&action=modifica'
                                        }}
                                    >
                                        <i className="bi bi-pencil-fill"></i>
                                    </Link>
                                    <div
                                        style={{
                                            marginLeft: 25
                                        }}
                                        className="c-pointer button-action"
                                        onClick={() => {
                                            deleteQuestion(item)
                                        }}
                                    >
                                        <i className="bi bi-trash-fill"></i>
                                    </div>
                                </div>
                            )
                        }
                    }
                    userData.push(item)
                })

                setItems(userData)
            })
    }

    setTimeout(() => {
        var buttons = document.getElementsByClassName('page-link')
        if (buttons.length > 0) {
            for (var i = 0; i < buttons.length; i++) {
                buttons[i].onclick = function () {
                    window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: 'smooth'
                    })
                }
            }
        }
    }, 1000)

    const data = {
        columns: [
            {
                label: '',
                field: '',
                sort: 'disabled',
                width: '10'
            },
            {
                label: 'Punto vendita',
                field: 'nome',
                sort: 'asc',
                width: 270
            },
            {
                label: 'Referente',
                field: 'referente',
                sort: 'asc',
                width: 200
            },
            {
                label: 'Licenze attive',
                field: 'licenze_attive',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Categoria',
                field: 'categoria',
                sort: 'asc',
                width: 200
            },
            {
                label: 'Azione',
                field: 'action',
                sort: 'disabled',
                width: 70
            }
        ],
        rows: items
    }
    const options = {
        onRowClick: handleRowClick
    }
    return (
        <div className="App">
            <MDBDataTableV5
                hover
                data={data}
                proSelect
                searchTop
                searchBottom={false}
                options={options}
                fullPagination
                searchLabel={'Cerca'}
                responsive
                disableRetreatAfterSorting={true}
            />
        </div>
    )
}

export { DatiAziendaliTabella as default, azienda as array }
