import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import CircleButton from "../components/Button/CircleButton";
import CustomHeader from "../components/Header/CustomHeader";
import CategoryCard from "../components/Cards/CategoryCard";

function HomeCategorie() {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  /* eslint-disable */
  useEffect(() => {
    polling();
    fetchcategorie();
  }, []);
  const fetchcategorie = () => {
    setInterval(() => {
      polling();
    }, 30000);
  };
  function polling() {
    fetch(process.env.REACT_APP_BASE_URL + "categorie")
      .then((res) => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result);
        },

        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }
  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <div>
        <CustomHeader title="Categorie">
          <CircleButton
            text="Vedi tutte"
            onClick={() =>
              (window.location.href = "impostazioni?tab=categorie")
            }
          />
        </CustomHeader>
        <Grid container spacing={3}>
          {items.map((item) => (
            <Grid item xs={3}>
              <CategoryCard item={item} />
            </Grid>
          ))}
        </Grid>
      </div>
    );
  }
}

export default HomeCategorie;
