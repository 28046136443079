  import { MDBDataTableV5 } from "mdbreact";
  import { useEffect, useState } from "react";
  import { Link } from "react-router-dom";
  import Swal from 'sweetalert2'
  export let itemTipologie = [{nomeTipologia:"",idTipologia:1, coloreTipologia:""}]
  function TipologieContenuti() {
      const [items, setItems] = useState([]);
      const [usersForRender, setUsersForRender] = useState([]);
 
  
      const fetchh = () => {
        fetch(process.env.REACT_APP_BASE_URL + "tipi-contenuto")
        .then((res) => res.json())
        .then((res) => {
          if (res.length === 0 )  
          {
            setItems([{id: -1,nome: "Nessuna tipologia presente!"}]);
            return;
          }
          setItems(res);
        });
      }
      useEffect(() => {
        fetchh();
      }, []);
      setTimeout(() => {
        var buttons = document.getElementsByClassName('page-link')
        if (buttons.length > 0)
        {
        
          for (var i=0; i < buttons.length; i++) {
            buttons[i].onclick = function(){
          
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth"
              });
            }
          };
        }
    }, 1000);
    function deleteCat (idCat){
      const dataDel = {};
      dataDel['id'] = idCat
      fetch(process.env.REACT_APP_BASE_URL + 'elimina-tipo-contenuto', { method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(dataDel)
      }).then(function(response) {
        if (response.status === 200) {
          Swal.fire({ title:"", text:"Operazione eseguita correttamente",type:"success"}).then(function() { 
           fetchh()})}}    
        
        ).catch(function(error)
        {
          Swal.fire({ title:"", text:"Impossibile eliminare la tipologia selezionata",type:"error"})
        });
       
    }
    /* eslint-disable */
    useEffect(() => {
      let itemsArray = items;
      let itemsData = [];
      
      itemsArray.map((item, index) => {
      if (item.id)
      {
      
      item.color = ( <span style={{ backgroundColor: item.colore }} className="c-color rounded-circle"></span>);
      item.action = ( <div style = {
        { display: "flex", justifyContent: "space-between" }
      } >
       <Link className="c-pointer button-action" onClick={ () => { itemTipologie.idTipologia = item.id; itemTipologie.nomeTipologia = item.nome; itemTipologie.coloreTipologia = item.colore}} to={() => { return "contenuti?tab=tipologie-contenuti&action=modifica" }} >
              <i className="bi bi-pencil-fill"></i> 
              </Link>
              
              &nbsp;&nbsp;&nbsp;
  
              <Link to="#" className="c-pointer button-action"  onClick={() =>
                      Swal.fire({
                        
                        title: "Confermi?",
                        
                        html: "<div style='padding:10px;'><span style='background-color:" + item.colore + "; margin-right:20px;' class='c-color rounded-circle'></span> Attenzione, la tipologia di contenuto <b> " + item.nome + "</b> sarà eliminata!</div>",
                          showCancelButton: true,
                          confirmButtonText: 'Elimina',
                          cancelButtonText: 'Annulla',
                          reverseButtons: true
                      }).then((result) => {
                        if (result.isConfirmed) {
                          deleteCat(item.id)
                          
                        } else if (
                          /* Read more about handling dismissals below */
                          result.dismiss === Swal.DismissReason.cancel
                        ) {
                          Swal.fire(
                            'Operazione annullata'
                          )
                      }
                    })}
                   >
            <i className="bi bi-trash-fill"></i> 
            </Link></div>
    
      );}
      itemsData.push(item);
    });
    setUsersForRender(itemsData);
  }, [items]);
     
  const data = {
    columns: [
    {
      label: "",
      field: "",
      sort : "disabled",
      width: "10"
    },
    {
      label: "Nome",
      field: "nome",
      sort: "asc",
      width: 270,
    },
  
    {
      label: "Colore",
      field: "color",
      sort: "disabled",
      width: 270,
    },
  
    {
      label: "Azione",
      field: "action",
      sort: "disabled",
      width: 150,
    },
  ],
  rows: usersForRender};
  return ( <div className = "tabella_tipologie">
  
      <p className="subtab">Gestisci le tipologie dei tuoi contenuti.
      </p><a href="contenuti?tab=tipologie-contenuti&amp;action=aggiunta" className='aggiungi'>+ Aggiungi</a>  
      <MDBDataTableV5 hover data={data} proSelect searchTop searchBottom={false} fullPagination searchLabel={'Ricerca tipologia contenuti...'} responsive disableRetreatAfterSorting={true} /> </div>
    );
  }
  export {
    TipologieContenuti as default,
    itemTipologie as array
  }