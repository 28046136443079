import { React, useState, useEffect, useRef } from "react";
import { MDBDataTableV5 } from "mdbreact";
import { Typography } from "@material-ui/core";

const PlayerLicenze = (props) => {
  const [currentUser] = useState(props.currentUser);
  const [val, setVal] = useState(-1);
  const [licenses, setLicenses] = useState([]);
  const [dataLicenses, setDataLicenses] = useState([]);
  const licensesStatus = ["Non associata", "attiva", "disattivata", "scaduta"];
  const licSection = useRef();
  const [palinsesti, setPalinsesti] = useState([]);
  const fetchPalinsesti = () => {
    fetch(process.env.REACT_APP_BASE_URL + "palinsesti")
      .then((res) => res.json())
      .then((res) => {
        if (res.length === 0) {
          setPalinsesti([{ id: -1, nome: "Nessun palinsesto!" }]);
          return;
        }
        setPalinsesti(res);
      });
  };
  const fetchDataLicense = (v = -1) => {
    let url = "licenze";
    if (currentUser.tipo > 2) {
      url += "-cliente/" + currentUser.userid;
    }
    fetch(process.env.REACT_APP_BASE_URL + url)
      .then((res) => res.json())
      .then((res) => {
        if (res.length === 0) {
          setLicenses([{ id: -1, nome: "Nessuna licenza presente!" }]);
          return;
        }
        if (v < 0) {
          setLicenses(res);
        } else if (v === 0) {
          setLicenses(res.filter((l) => l.status === 0));
        } else if (v === 1) {
          setLicenses(res.filter((l) => l.status === 1));
        } else if (v > 1) {
          setLicenses(res.filter((l) => l.status > 1));
        }
      });
  };
  const filterList = (v) => {
    setVal(v);
    fetchDataLicense(v);
  };

  useEffect(() => {
    fetchPalinsesti();
  }, []);
  /* eslint-disable */
  useEffect(() => {
    fetchDataLicense();
  }, [palinsesti]);
  /* eslint-disable */
  useEffect(() => {
    let itemsArray = licenses;
    let dataitems = [];

    itemsArray.map((license) => {
      license.statuslic = licensesStatus[license.status];
      let palinsesto = palinsesti.find((p) => {
        return p.uuid === license.uuid_palinsesto;
      });
      license.scadenza =
        license.status > 0 && license.status !== 2
          ? new Date(license.data_scadenza).toLocaleDateString("it", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            })
          : "N/A";
      dataitems.push(license);
    }, setDataLicenses(dataitems));
  }, [licenses]);
  setTimeout(() => {
    var buttons = document.getElementsByClassName("page-link");
    if (buttons.length > 0) {
      for (var i = 0; i < buttons.length; i++) {
        buttons[i].onclick = function () {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        };
      }
    }
  }, 1000);
  const data = {
    columns: [
      {
        label: "",
        field: "",
        sort: "disabled",
        width: "10",
      },
      {
        label: "Codice",
        field: "codice",
        sort: "asc",
        width: 270,
      },
      {
        label: "Scadenza",
        field: "scadenza",
        sort: "asc",
        width: 200,
      },
      {
        label: "Stato",
        field: "statuslic",
        sort: "asc",
        width: 150,
      },
    ],
    rows: dataLicenses,
  };
  return (
    <>
      <div>
        <div ref={licSection} className="gestione-licenze">
          <Typography variant="h6" gutterBottom component="div">
            Visualizza lo stato delle licenze
          </Typography>
          <p>
            <div style={{ textAlign: "left", padding: "10px" }}>
              <input
                type="radio"
                onChange={() => {
                  filterList(-1);
                }}
                defaultChecked={true}
                name="licenze"
              />
              <label
                for="licenze"
                style={{ paddingLeft: "6px", paddingRight: "10px" }}
              >
                Tutte
              </label>
              <input
                type="radio"
                onChange={() => {
                  filterList(3);
                }}
                name="licenze"
              />
              <label
                for="licenze"
                style={{ paddingLeft: "6px", paddingRight: "10px" }}
              >
                Scadute
              </label>
              <input
                type="radio"
                onChange={() => {
                  filterList(1);
                }}
                name="licenze"
              />
              <label
                for="licenze"
                style={{ paddingLeft: "6px", paddingRight: "10px" }}
              >
                Attive
              </label>
              <input
                type="radio"
                onChange={() => {
                  filterList(0);
                }}
                name="licenze"
              />
              <label
                for="licenze"
                style={{ paddingLeft: "6px", paddingRight: "10px" }}
              >
                Non associate
              </label>
            </div>
          </p>
          <div style={{ minHeight: "500px", width: "100%" }}>
            <MDBDataTableV5
              entries={30}
              hover
              data={data}
              proSelect
              searchTop
              searchBottom={false}
              fullPagination
              searchLabel={"Cerca"}
              responsive
              disableRetreatAfterSorting={true}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default PlayerLicenze;
