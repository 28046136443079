import React, { useContext } from "react";

/// React router dom
import { Switch, Route } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
/// Dashboard
import Home from "./components/Dashboard/Home";

/// Pages
import Error404 from "./pages/Error404";
import Player from "./pages/player";
import Utenti from "./pages/utenti";
import UtentiAttivi from "./pages/utenti-attivi";
import UtentiNonAttivi from "./pages/utenti-non-attivi";
import Impostazioni from "./pages/impostazioni";
import NuovoPalinsesto from "./pages/nuovo-palinsesto";
import NuovaCategoria from "./pages/nuova-categoria";
import NuovaAnagrafica from "./pages/nuova-anagrafica";
import ModificaAnagrafica from "./pages/modifica-anagrafica";
import Categorie from "./pages/categorie";
import NuovoUtente from "./pages/nuovo-utente";
import GestioneUtenti from "./pages/gestione-utenti";
import ModificaUtente from "./pages/modifica-utente";
import ModificaUtenteAttivo from "./pages/modifica-utente-attivo";
import ModificaBrand from "./pages/modifica-brand";
import NuovoBrand from "./pages/nuovo-brand";
import EliminaUtente from "./pages/elimina-utente";
import NuovaIniziativa from "./pages/nuova-iniziativa";
import ModificaIniziativa from "./pages/modifica-iniziativa";
import Elementi from "./pages/elementi";
import Messaggi from "./pages/messaggi";
import TuttiIContenuti from "./pages/contenuti";
import NuovoContenuto from "./pages/nuovo-contenuto";
import TipologieContenuti from "./pages/tipologie-contenuti";
import NuovaTipologiaContenuti from "./pages/nuova-tipologia-contenuti";
import Webinar from "./pages/webinar";
import ModificaTipologiaContenuti from "./pages/modifica-tipologia-contenuti";
import Palinsesti from "./pages/palinsesti";
import PalinsestiSub from "./pages/palinsesti-sub";
import Iniziative from "./pages/iniziative";
import AppProfile from "./pages/app-profile";
import { ThemeContext } from "../context/ThemeContext";
<script
  src="https://cdn.tiny.cloud/1/dco46zttpjftz9nczj7keskp9vh43pdscra2r8xgqoprc6tf/tinymce/5/tinymce.min.js"
  referrerpolicy="origin"
></script>;

////test
import Test from "./pages/test";

const Markup = () => {
  const { menuToggle } = useContext(ThemeContext);
  const routes = [
    /// Dashboard
    { url: "", component: Home },
    { url: "dashboard", component: Home },

    /// pages

    { url: "page-error-404", component: Error404 },
    { url: "player", component: Player },
    { url: "nuovo-palinsesto", component: NuovoPalinsesto },
    { url: "nuova-categoria", component: NuovaCategoria },
    { url: "nuova-anagrafica", component: NuovaAnagrafica },
    { url: "modifica-anagrafica", component: ModificaAnagrafica },
    { url: "categorie", component: Categorie },
    { url: "nuovo-utente", component: NuovoUtente },
    { url: "gestione-utenti", component: GestioneUtenti },
    { url: "modifica-utente", component: ModificaUtente },
    { url: "modifica-utente-attivo", component: ModificaUtenteAttivo },
    { url: "modifica-brand", component: ModificaBrand },
    { url: "nuovo-brand", component: NuovoBrand },
    { url: "elimina-utente", component: EliminaUtente },
    { url: "nuova-iniziativa", component: NuovaIniziativa },
    { url: "modifica-iniziativa", component: ModificaIniziativa },
    { url: "impostazioni", component: Impostazioni },
    { url: "contenuti", component: Elementi },
    { url: "messaggi", component: Messaggi },
    { url: "tutti-i-contenuti", component: TuttiIContenuti },
    { url: "nuovo-contenuto", component: NuovoContenuto },
    { url: "tipologie-contenuti", component: TipologieContenuti },
    {
      url: "modifica-tipologia-contenuti",
      component: ModificaTipologiaContenuti,
    },
    { url: "nuova-tipologia-contenuti", component: NuovaTipologiaContenuti },
    { url: "webinar", component: Webinar },
    { url: "palinsesti", component: Palinsesti },
    { url: "palinsesti-sub", component: PalinsestiSub },
    { url: "iniziative", component: Iniziative },
    { url: "utenti", component: Utenti },
    { url: "utenti-attivi", component: UtentiAttivi },
    { url: "utenti-non-attivi", component: UtentiNonAttivi },
    { url: "app-profile", component: AppProfile },

    ////test
    { url: "test", component: Test },
  ];

  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  const redirect =
    "/" +
    path +
    window.location.href
      .replace(window.location.host, "")
      .replace(window.location.protocol, "")
      .replace("//", "")
      .replace(window.location.pathname, "");
  if (path !== "login" && path !== "logout") {
    localStorage.setItem("redirect-to", redirect);
  }

  let pagePath = path.split("-").includes("page");

  setTimeout(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, 100);
  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}  ${
          menuToggle ? "menu-toggle" : ""
        }`}
      >
        {!pagePath && <Nav />}

        <div className={`${!pagePath ? "content-body" : ""}`}>
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
            style={{ minHeight: window.screen.height - 60 }}
          >
            <Switch>
              {routes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                />
              ))}
            </Switch>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
    </>
  );
};

export default Markup;
