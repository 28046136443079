import React from "react";
import axios from "axios";
import Swal from "sweetalert2";
import "antd/dist/antd.css";
import { Steps, Button } from "antd";
import ModificaPalinsesto1 from "./modifica-palinsesto-1";
import ModificaPalinsesto2 from "./modifica-palinsesto-2";
import ModificaPalinsesto3 from "./modifica-palinsesto-3";
import { itemPalinsesto } from "./palinsesti-sub";
import { EventHandler } from "@syncfusion/ej2-base";
import CircleButtonComponent from "../components/Button/CircleButton";
export let palinsestoData = {
  palinsesto: "",
  uuid: "",
  categoria: 0,
  template: 0,
  items: [],
  eventi: [],
};
const { Step } = Steps;
const steps = [
  {
    title: "Informazioni generali",
    content: <ModificaPalinsesto1 />,
    disabled: false,
  },
  {
    title: "Gestione brand",
    content: <ModificaPalinsesto2 />,
    disabled: false,
  },
  {
    title: "Assegnazione template",
    content: <ModificaPalinsesto3 />,
    disabled: false,
  },
];
class ModificaPalinsesto extends React.Component {
  state = { current: 0 };
  loadData = () => {
    palinsestoData.eventi = [];
    palinsestoData.palinsesto = itemPalinsesto.nome;
    palinsestoData.uuid = itemPalinsesto.uuid;
    palinsestoData.categoria = itemPalinsesto.categoria;
    palinsestoData.template = itemPalinsesto.template;

    fetch(process.env.REACT_APP_BASE_URL + "palinsesto/" + palinsestoData.uuid)
      .then((res) => res.json())
      .then((res) => {
        if (res.length > 0) {
          let dataPalinsesto = res[0];
          palinsestoData.palinsesto = dataPalinsesto.nome;
          palinsestoData.uuid = dataPalinsesto.uuid;
          palinsestoData.template = dataPalinsesto.template;
          palinsestoData.categoria = dataPalinsesto.categoria;
          palinsestoData.items = dataPalinsesto.items;

          this.setState({
            templateOrientation:
              dataPalinsesto.template === 0 ? "Orizzontale" : "Verticale",
          });

          dataPalinsesto.eventi.forEach((item) => {
            let evento = {
              id: item.id,
              title: item.title,
              startTime: item.start_time,
              endTime: item.end_time,
              allDay: false,
              daysOfWeek: [item.day_of_week],
              extendedProps: {
                palinsesto: item.uuid_palinsesto,
                idItem: item.id_item,
                idContent: item.id_content,
                isNew: false,
                startTime: item.start_time,
                endTime: item.end_time,
                duration: item.duration,
                dayOfWeek: item.day_of_week,
                elementsToHide:
                  item.elements_to_hide === undefined
                    ? "[]"
                    : item.elements_to_hide,
                orderedList:
                  item.ordered_list === undefined ? "[]" : item.ordered_list,
              },
              durationEditable: true,
              startEditable: true,
            };
            palinsestoData.eventi.push(evento);
          });
        }
      });
  };
  getDate = (datetime) => {
    let dateObj = new Date(datetime);
    return (
      (dateObj.getHours() < 10
        ? "0" + dateObj.getHours()
        : dateObj.getHours()) +
      ":" +
      (dateObj.getMinutes() < 10
        ? "0" + dateObj.getMinutes()
        : dateObj.getMinutes())
    );
  };
  getDay = (datetime) => {
    let dateObj = new Date(datetime);
    return dateObj.getDay();
  };
  getDuration = (startDateTime, endDateTime) => {
    let startDateObj = new Date(startDateTime);
    let endDateObj = new Date(endDateTime);
    let duration =
      endDateObj.getHours() * 60 +
      endDateObj.getMinutes() -
      (startDateObj.getHours() * 60 + startDateObj.getMinutes());
    return duration;
  };
  parseData = () => {
    let eventi = JSON.parse(JSON.stringify(palinsestoData.eventi));
    palinsestoData.eventi = [];
    if (eventi.length > 0) {
      eventi.forEach((item) => {
        let itemStartTime = item.start
          ? this.getDate(item.start)
          : item.extendedProps.startTime;
        let itemEndTime = item.end
          ? this.getDate(item.end)
          : item.extendedProps.endTime;
        let itemDayOfWeek = item.start
          ? this.getDay(item.start)
          : item.extendedProps.dayOfWeek;
        let duration = item.start
          ? this.getDuration(item.start, item.end)
          : item.extendedProps.duration;
        let evento = {
          id: item.id,
          title: item.title,
          startTime: itemStartTime,
          endTime: itemEndTime,
          allDay: false,
          daysOfWeek: [itemDayOfWeek],
          extendedProps: {
            palinsesto: item.extendedProps.palinsesto,
            idItem: item.extendedProps.idItem,
            idContent: item.extendedProps.id_content,
            isNew: item.extendedProps.isNew,
            startTime: itemStartTime,
            endTime: itemEndTime,
            duration: duration,
            dayOfWeek: itemDayOfWeek,
            elementsToHide: item.extendedProps.elementsToHide,
            orderedList: item.extendedProps.orderedList,
          },
          durationEditable: true,
          startEditable: true,
        };
        palinsestoData.eventi.push(evento);
      });
    }
  };
  componentDidMount() {
    this.loadData();
  }
  verifyEmpty() {
    if (this.state.current === 0) {
      if (
        palinsestoData.categoria === 0 ||
        palinsestoData.palinsesto === null ||
        palinsestoData.palinsesto.length === 0 ||
        palinsestoData.items.length === 0
      ) {
        Swal.fire({
          icon: "error",
          text: "Inserire tutte le informazioni",
        });
        return true;
      }
    }
    return false;
  }
  onChange = (current) => {
    if (this.verifyEmpty()) {
      return;
    }
    this.parseData();
    this.setState({ current });
  };
  next = () => {
    if (this.verifyEmpty()) {
      return;
    }
    this.parseData();
    let plus = this.state.current + 1;
    this.setState({ current: plus });
  };
  prev = () => {
    let minus = this.state.current - 1;
    this.setState({ current: minus });
  };
  salvaPalinsesto = () => {
    axios
      .post(
        process.env.REACT_APP_BASE_URL + "modifica-palinsesto",
        palinsestoData
      )
      .then(function (response) {
        if (response.status === 200) {
          fetch(
            process.env.REACT_APP_BASE_URL + `autoplan/${palinsestoData.uuid}`,
            {
              method: "GET",
              headers: { "Content-Type": "application/json" },
            }
          )
            .then(function (response) {
              if (response.status === 200) {
                response.text().then(function (text) {
                  let result = JSON.parse(text);
                  let icon = result.status === 200 ? "success" : "warning";
                  Swal.fire({
                    title: "",
                    text: "Operazione eseguita correttamente",
                    icon: "success",
                  }).then(function () {
                    window.location.href = "palinsesti?tab=palinsesti-sub";
                  });
                });
              }
            })
            .catch(function (error) {
              Swal.fire({
                title: "",
                text: "Impossibile rigenerare il palinsesto selezionato",
                icon: "error",
              });
            });
        } else {
          throw new Error(response.status);
        }
      })
      .catch(function (error) {
        Swal.fire({
          title: "",
          html:
            "Errore nel salvataggio delle modifiche del palinsesto <b>" +
            palinsestoData.palinsesto +
            "</b>",
          icon: "error",
        });
      });
  };

  render() {
    const { current, templateOrientation } = this.state;

    return (
      <>
        <div style={{ marginBottom: 24, marginLeft: 12 }}>
          <CircleButtonComponent
            text={`Orientamento display: ${templateOrientation}`}
          />
        </div>
        <Steps
          style={{ margin: "0 auto", width: "90%" }}
          type="navigation"
          className="nav-steps"
          current={current}
          onChange={this.onChange}
        >
          <Step
            title="informazioni generali"
            description="Inserisci le informazioni generali per definire il palinsesto."
          />
          <Step
            title="Gestione brand"
            description="Gestisci la distribuzione dei brand durante i giorni della settimana."
          />
          <Step
            title="Impostazione template"
            description="Seleziona il template da associare al palinsesto che stai creando."
          />
        </Steps>

        <div className="nuovo-palinsesto">
          {steps[current].content}

          <div className="steps-actions">
            {current > 0 && (
              <Button className="step-previous" onClick={this.prev}>
                Indietro
              </Button>
            )}
            {current < steps.length - 1 && (
              <Button type="primary" className="step-next" onClick={this.next}>
                Avanti
              </Button>
            )}
            {current === 2 && (
              <Button
                type="primary"
                className="step-next"
                onClick={this.salvaPalinsesto}
              >
                Salva palinsesto
              </Button>
            )}
          </div>
        </div>
      </>
    );
  }
}
export { ModificaPalinsesto as default, palinsestoData as array };
