import { Typography } from "@material-ui/core";
import React from "react";
import { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert";
import { itemAnag } from "./anagrafiche";
import DatiAziendaliTabella from "./dati-aziendali-tabella";
import { Modal, Button } from "react-bootstrap";
import PosLicenze from "./pos-licenze";
import UtentiAssociaBrands from "./utenti-associa-brands";
import axios from "axios";

export function ModificaAnagrafica() {
  const history = useHistory();
  const [showLicenceModal, setShowLicenceModal] = useState(false);
  const [itemsCategorie, setItemsCategorie] = useState([]);
  const [currentClient, setCurrentClient] = useState(null);
  const [itemsZone, setItemsZone] = useState([]);
  const [itemsReferenti, setItemsReferenti] = useState([]);
  const [itemsTipiPunto, setItemsTipiPunto] = useState([]);
  const [isLoadingCat, setIsLoadingCat] = useState(true);
  const [isLoadingZone, setIsLoadingZone] = useState(true);
  const [isLoadingReferenti, setIsLoadingReferenti] = useState(true);
  const [isLoadingTipiPuntoVendita, setIsLoadingTipiPuntoVendita] =
    useState(true);
  const [utenteBrands, setUtenteBrands] = useState([]);
  const [selectedUser] = useState({ id: 0 });

  const categorieRef = useRef();
  const zoneRef = useRef();
  const referentiRef = useRef();
  const tipiPuntoRef = useRef();

  const isValidEmail = (email) => {
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(email.toLowerCase());
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const elements = event.target.elements;
    const data = {};
    const userData = {};
    const itemsUtenteBrands = [];

      
    for (let i = 0; i < elements.length; i++) {
      const elem = elements[i];
  
      const requiredFields = ["nome_azienda", "cognome", "email", "email_azienda", "ragione sociale"];
  
      if (requiredFields.includes(elem.name) && (!elem.value.trim() || elem.value.trim().length < 2)) {
        Swal("Errore", `Il campo ${elem.name} è obbligatorio e non può essere vuoto o meno di 2 caratteri.`, "error");
        return;
    }
  
      if (["nome", "cognome", "email"].includes(elem.name)) {
          userData[elem.name] = elem.value;
  
          if (elem.name === 'email' && !isValidEmail(elem.value)) {
              Swal("Errore", "Inserire una email valida per l'utente", "error");
              return;
          }
      } else {
          if (elem.name === "nome_azienda") {
              data["nome"] = elem.value;
          } else if (elem.name === 'email_azienda') {
              data['email'] = elem.value;
  
              if (!isValidEmail(elem.value)) {
                  Swal("Errore", "Inserire una email valida per il punto vendita", "error");
                  return;
              }
          } else {
              data[elem.name] = elem.value;
          }
      }
  }
    utenteBrands.forEach((i) => {
      let b = { id_utente: 0, id_brand: i.id };
      itemsUtenteBrands.push(b);
    });
    userData["utenteBrands"] = itemsUtenteBrands;

    await axios
      .post(process.env.REACT_APP_BASE_URL + "modifica-utente/", {
        ...userData,
        tipo: 3,
        id: currentClient.id,
        status: currentClient.status,
      })
      .then(function (response) {
        return response.data.rows[0];
      })
      .catch(function (error) {
        swal("", "Errore creazione utente", "error");
      });
    data.id_referente = currentClient.id;
    fetch(process.env.REACT_APP_BASE_URL + "modifica-punto-vendita", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then(function (response) {
        if (response.status === 200) {
          //let responseText = JSON.stringify(response.text());
          swal("", "Operazione eseguita correttamente", "success");
          history.push("impostazioni");
        } else {
          throw new Error(response.status);
        }
      })
      .catch(function (error) {
        swal(
          "",
          "Impossibile Salvare, verificare l'inserimento di tutti i dati",
          "error"
        );
      });
  };
  //const [usersForRender, setUsersForRender] = useState([]);
  const getData = () => {
    fetch(
      process.env.REACT_APP_BASE_URL +
        "get-clienti-brands/" +
        itemAnag.id_referente
    )
      .then((res) => res.json())
      .then((res) => {
        setUtenteBrands(res);
      });
    fetch(process.env.REACT_APP_BASE_URL + "categorie")
      .then((res) => res.json())
      .then((res) => {
        setItemsCategorie(res);
        setIsLoadingCat(false);
        categorieRef.current.value = itemAnag.id_categoria;
      });
    fetch(process.env.REACT_APP_BASE_URL + "zone")
      .then((res) => res.json())
      .then((res) => {
        setItemsZone(res);
        setIsLoadingZone(false);
        zoneRef.current.value = itemAnag.id_zona;
      });
    fetch(process.env.REACT_APP_BASE_URL + "clienti")
      .then((res) => res.json())
      .then((res) => {
        const foundClient = res.find(
          (referente) => referente.id == itemAnag.id_referente
        );
        if (foundClient) {
          setCurrentClient(foundClient);
        }
        setItemsReferenti(res);
        setIsLoadingReferenti(false);
        referentiRef.current &&
          (referentiRef.current.value = itemAnag.id_referente);
      });
    fetch(process.env.REACT_APP_BASE_URL + "tipi-punto-vendita")
      .then((res) => res.json())
      .then((res) => {
        setItemsTipiPunto(res);
        setIsLoadingTipiPuntoVendita(false);
        tipiPuntoRef.current.value = itemAnag.id_tipo;
      });
  };
  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    if (
      isLoadingCat ||
      isLoadingZone ||
      isLoadingReferenti ||
      isLoadingTipiPuntoVendita
    ) {
      return <div className="App">Caricamento dati in corso...</div>;
    }
  });

  const handleClose = () => {
    setShowLicenceModal(false);
  };

  console.log(itemsReferenti);

  return (
    <>
      <Modal
        dialogClassName="modal-messages"
        show={showLicenceModal}
        onRequestClose={handleClose}
      >
        <Modal.Body>
          <Button
            style={{ float: "right" }}
            variant="link"
            onClick={handleClose}
          >
            <i style={{ fontSize: "120%" }} className="bi bi-x"></i>
          </Button>
          <PosLicenze handleClose={handleClose} posLic={itemAnag} />
        </Modal.Body>
      </Modal>
      <div className="card">
        <Typography
          variant="h6"
          gutterBottom
          component="div"
          style={{ marginLeft: "2%", marginTop: "2%" }}
        >
          Modifica anagrafica
        </Typography>
        <div className="card-bodyx">
          <form onSubmit={handleSubmit}>
            <div>
              <div className="form-row">
                <label className="form-label">NOME*</label>
                <input
                  defaultValue={currentClient ? currentClient.nome : ""}
                  className="form-input"
                  type="text"
                  name="nome"
                  required={true}
                />
              </div>
              <div className="form-row">
                <label className="form-label">COGNOME*</label>
                <input
                  defaultValue={currentClient ? currentClient.cognome : ""}
                  className="form-input"
                  type="text"
                  name="cognome"
                  required={true}
                />
              </div>
              <div className="form-row">
                <label className="form-label">EMAIL UTENTE*</label>
                <input
                  defaultValue={currentClient ? currentClient.email : ""}
                  className="form-input"
                  type="text"
                  name="email"
                  required={true}
                />
              </div>
            </div>
            <UtentiAssociaBrands
              fullHeight
              utenteBrands={utenteBrands}
              selectedUser={selectedUser}
              toggleItem={(item) => {
                const foundIndex = utenteBrands.findIndex(
                  (brand) => brand.id === item.id
                );
                if (foundIndex === -1) {
                  setUtenteBrands([...utenteBrands, item]);
                } else {
                  const arrayCopy = JSON.parse(JSON.stringify(utenteBrands));
                  arrayCopy.splice(foundIndex, 1);
                  setUtenteBrands(arrayCopy);
                }
              }}
            />
            <div class="table-dati d_az">
              <input type="hidden" name="id" value={itemAnag.id} />
              <input
                type="hidden"
                name="id_azienda"
                value={itemAnag.id_azienda}
              />
              <div>
                <div>
                  <label>E-mail PUNTO VENDITA*:</label>
                  <span>
                    <input
                      type="text"
                      name="email_azienda"
                      defaultValue={itemAnag.email}
                      required={true}
                    />
                  </span>
                </div>
                <div class="dat2">
                  <label>Ragione sociale*:</label>
                  <span>
                    <input
                      name="nome_azienda"
                      type="text"
                      defaultValue={itemAnag.nome}
                      required={true}
                    />
                  </span>
                </div>
              </div>
              <div></div>

              <div></div>
              <div>
                {/* <div>
                                    <label>Referente commerciale:</label>
                                    <span>
                                        <select ref={referentiRef} name="id_referente" defaultValue={itemAnag.id_referente}>
                                            {itemsReferenti.filter(r => r.tipo === 3).map((referente) => (
                                                <option value={referente.id}>{referente.nome + ' ' + referente.cognome}</option>
                                            ))}
                                        </select>
                                    </span>
                                </div> */}
              </div>
              <div>
                <div>
                  <label>Zona di vendita</label>
                  <span>
                    <select
                      ref={zoneRef}
                      name="id_zona"
                      defaultValue={itemAnag.id_zona}
                    >
                      {itemsZone.map((zona) => (
                        <option value={zona.id}>{zona.nome}</option>
                      ))}
                    </select>
                  </span>
                </div>
                <div>
                  <label>Tipo:</label>
                  <span>
                    <select
                      ref={tipiPuntoRef}
                      name="id_tipo"
                      defaultValue={itemAnag.id_tipo}
                    >
                      {itemsTipiPunto
                        .sort((a, b) => a.id > b.id)
                        .map((tipoPunto) => (
                          <option value={tipoPunto.id}>{tipoPunto.nome}</option>
                        ))}
                    </select>
                  </span>
                </div>
                <div>
                  <label>Categoria:</label>
                  <span>
                    <select
                      ref={categorieRef}
                      name="id_categoria"
                      defaultValue={itemAnag.id_categoria}
                    >
                      {itemsCategorie.map((categoria) => (
                        <option value={categoria.id}>{categoria.nome}</option>
                      ))}
                    </select>
                  </span>
                </div>
              </div>

              <div>
                <div>
                  <label>Telefono:</label>
                  <span>
                    <input
                      type="text"
                      name="telefono"
                      defaultValue={itemAnag.telefono}
                    />
                  </span>
                </div>
                <div>
                  <label>Codice fiscale:</label>
                  <span>
                    <input
                      type="text"
                      name="codice_fiscale"
                      defaultValue={itemAnag.codice_fiscale}
                      maxLength={16}
                      required={false}
                    />
                  </span>
                </div>
                <div class="dat3">
                  <label>Partita IVA:</label>
                  <span>
                    <input
                      name="p_iva"
                      type="text"
                      defaultValue={itemAnag.p_iva}
                      maxLength={11}
                      required={false}
                    />
                  </span>
                </div>
              </div>
              <div>
                <div>
                  <label>Provincia:</label>
                  <span>
                    <input
                      type="text"
                      name="provincia"
                      defaultValue={itemAnag.provincia}
                      required={false}
                    />
                  </span>
                </div>
                <div>
                  <label>Citt&agrave;:</label>
                  <span>
                    <input
                      type="text"
                      name="citta"
                      defaultValue={itemAnag.citta}
                      required={false}
                    />
                  </span>
                </div>
                <div>
                  <label>Indirizzo:</label>
                  <span>
                    <input
                      type="text"
                      name="indirizzo"
                      defaultValue={itemAnag.indirizzo}
                      required={false}
                    />
                  </span>
                </div>
                <div>
                  <label>Codice postale:</label>
                  <span>
                    <input
                      type="text"
                      name="cap"
                      defaultValue={itemAnag.cap}
                      maxLength={5}
                      required={false}
                    />
                  </span>
                </div>
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <label>Licenze assegnate:</label>
                  <label
                    style={{
                      cursor: "pointer",
                      background: "#4885ed",
                      color: "#fff",
                      borderRadius: 4,
                      padding: "4px 8px",
                    }}
                    onClick={() => setShowLicenceModal(true)}
                  >
                    Gestisci
                  </label>
                </div>
                {/* <span>
                                        <input type="text" name="numero_licenze_assegnate" defaultValue={itemAnag.licenze.length} disabled />
                                    </span> */}
              </div>
            </div>
            <div className="form-row">
              <div className="form-buttons-right">
                <Button
                  className="btn btn-outline-primary rounded-1 btn-sm"
                  type="submit"
                >
                  Salva
                </Button>
                <Button
                  className="btn btn-outline-primary rounded-1 btn-sm"
                  type="button"
                  onClick={() => {
                    history.push("/impostazioni");
                  }}
                >
                  Annulla{" "}
                </Button>
              </div>
            </div>
            {itemAnag.id_tipo === 1 ? (
              <>
                <hr style={{ marginTop: "5%" }} />
                <div className="tabella_sub_anagrafiche">
                  <Typography
                    variant="h6"
                    gutterBottom
                    component="div"
                    style={{ marginLeft: "2%", marginTop: "2%" }}
                  >
                    Visualizza le informazioni anagrafiche dei tuoi punti
                    vendita.
                  </Typography>
                  <a
                    href={`impostazioni?tab=anagrafiche&action=aggiunta&casa-madre=${itemAnag.id}`}
                    className="sub_aggiungi"
                  >
                    + Aggiungi
                  </a>
                  <div className="tabella_dati_aziendali">
                    <DatiAziendaliTabella companyId={itemAnag.id} />
                  </div>
                </div>
              </>
            ) : null}
          </form>
        </div>
      </div>
    </>
  );
}

export default ModificaAnagrafica;
