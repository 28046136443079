import React, { useState } from 'react';
import { Button} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import axios from 'axios';
import {itemCat} from "./categorie";
function ModificaCategoria() {
  const [colorChange, setColorChange] = useState(null);
  const [file, setFile] = useState(''); // storing the uploaded file
  //const [progress, setProgess] = useState(0); // progess bar  
  const history = useHistory();
  setTimeout(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, 100);
  const handleChange = (e) => {
    //setProgess(0)
    const file = e.target.files[0]; // accessing file
    console.log(file);
    setFile(file); // storing file
    var imgLogo = document.getElementById('logoImg');
    imgLogo.src =  URL.createObjectURL(file);
    itemCat.imgCat = file.name;
  }
  const uploadFile = () => {
    const formData = new FormData();
    formData.append('file', file); // appending file
    axios.post(process.env.REACT_APP_BASE_URL + 'upload-file', formData, {
      onUploadProgress: (ProgressEvent) => {
          //let progressValue = Math.round(
          //ProgressEvent.loaded / ProgressEvent.total * 100) + '%';
          //setProgess(progressValue);
      }
    }).then(res => {
      if (res.status === 200)
      {
        history.push('impostazioni?tab=categorie');
      }
      else
      {
        console.log(res);
      }
    }).catch(err => console.log(err))}

    const handleSubmit = event => {
    event.preventDefault();
    const form = event.target;
    const file = event.target.immagine.files[0];
    const data = {};
    for (let i=0; i < form.elements.length; i++) {
      const elem = form.elements[i];
      data[elem.name] = elem.value;
    }
    if (event.target.immagine.files.length > 0)
    {
      data['immagine'] = file.name; 
    }
    else
    {
      data['immagine'] = itemCat.immagine;
    }
  
    axios.post(process.env.REACT_APP_BASE_URL + 'modifica-categoria', data )
    .then(function(response) {
      if (response.status === 200) {
        if (event.target.immagine.files.length > 0){
          uploadFile();
        }
        else
        {
            history.push('impostazioni');
        }
      }
      else {throw new Error(response.status);}
    }).catch(function(error){console.log(error)});
  }
 
  return (
    <div>
      <div className="">
        <div className="card">
          <div className="card-header">
          <p className="subtab">Modifica categoria</p>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit} encType='multipart/form-data'>
             
              <input class="form-input" type="hidden" name="id" value={itemCat.id} onChange={handleChange}/>
                <div className="form-row">
                
               <label  class="form-label">NOME</label>
              
                <input class="form-input"  type="text" name="nome" defaultValue = {itemCat.nome} />
                
                </div>

                <div className="form-row">
                <label className="form-label">COLORE</label>
              <input type="color" name="colore" 
                      className="as_colorpicker form-control form-input-color"
                      defaultValue={itemCat.colore} 
                      value={colorChange}
                      onChange={(e) => setColorChange(e.target.value)}
                    />
                    </div>
                <div className="form-row">
                <label class="form-label">Immagine</label>
                <input class="form-input" type="file" name="immagine" onChange={handleChange}/>
                
                  <div className="form-image">
              <img id="logoImg" className="brand-image" src= {process.env.REACT_APP_BASE_URL_CONTENTS + itemCat.immagine} alt ="" />
             </div>
             <div className="form-buttons">
                <Button className="btn btn-outline-primary rounded-1 btn-sm" type="submit">Invia Modifica</Button>
               <Button className="btn btn-outline-primary rounded-1 btn-sm" onClick={() => {history.push('impostazioni');}} type="button">Annulla </Button>
              </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ModificaCategoria;
