import React from "react";
import {useEffect} from "react";
import templateH from "../../images/templateH.png"
import templateV from "../../images/templateV.png"
import { iniziativaData } from "./modifica-iniziativa";
const ModificaIniziativa3= () => {
  const handleChange = (e) => {
    iniziativaData.template = e.target.value;
  }
  useEffect(()=> 
  {
    if (iniziativaData.template === 0)
      {
        document.getElementById('template0').checked = true;
        document.getElementById('template1').checked = false;
      }else if (iniziativaData.template === 1)
      {
        document.getElementById('template0').checked = false;
        document.getElementById('template1').checked = true;
      }
    
  },[]);
  

   return (<>
      <div className="templates "> 
        <p className="subtab">Seleziona il template da associare al iniziativa che stai creando.</p>
        <label className="template">
          <input id="template0" onChange={handleChange} type="radio" name="test" value="0" />
          <img src= {templateH}  alt="templateH"/>
        </label>
      <label className="template">
        <input id="template1" onChange={handleChange} type="radio" name="test" value="1"  />
        <img src= {templateV}  alt="templateV"/>
      </label>
      
    </div>

</>

   );
};

export default ModificaIniziativa3;