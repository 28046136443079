import { React, useState, useEffect, useRef } from "react";
import Select from "react-select";
import Swal from "sweetalert2";
import { Button } from "react-bootstrap";
import axios from "axios";
import { Editor } from "@tinymce/tinymce-react";
import { API_KEY } from "../../CONST";
<script
  src="https://cdn.tiny.cloud/1/dco46zttpjftz9nczj7keskp9vh43pdscra2r8xgqoprc6tf/tinymce/5/tinymce.min.js"
  referrerpolicy="origin"
></script>;
export function MessaggioInvito(props) {
  const editorRef = useRef(null);
  const [message] = useState(props.message);
  const [title] = useState(props.title);
  function onModalClose() {
    props.onCloseModal();
  }
  const [usersEmail, setUsersEmails] = useState([]);
  const emails = [];
  const userData = localStorage.getItem("userDetails", "undefined");
  const user = JSON.parse(userData);
  const refDesOption = useRef();

  const getUsers = () => {
    fetch(process.env.REACT_APP_BASE_URL + "email-utenti-inattivi")
      .then((res) => res.json())
      .then((res) => {
        if (res.length > 0) {
          setUsersEmails(res);
        }
      })
      .catch(function (error) {
        setUsersEmails([]);
      });
  };
  const saveBody = (content) => {
    message.body = content;
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const elements = event.target.elements;
    const dataMail = {};
    const data = {};

    for (let i = 0; i < elements.length; i++) {
      const elem = elements[i];

      if (elem.name !== "users" && elem.name !== "priority") {
        dataMail[elem.name] = elem.value;
      } else if (elem.name === "priority") {
        if (elem.checked) {
          dataMail[elem.name] = 1;
        } else {
          dataMail[elem.name] = 0;
        }
      } else if (elem.name === "users") {
        let userData = usersEmail.find((u) => {
          return u.value === elem.value;
        });
        if (userData) {
          emails.push({ email: elem.value, nominativo: userData.label });
        }
      }
    }
    dataMail["body"] = message.body;
    let currentUser = JSON.parse(userData);
    dataMail["sendername"] = currentUser.nome + " " + currentUser.cognome;
    dataMail["sendermail"] = currentUser.email;

    if (emails.length === 0) {
      Swal.fire({
        title: "",
        text: "Nessun contatto selezionato ",
        icon: "warning",
      });
      return;
    }
    emails.forEach((e) => (e["data"] = dataMail));

    data["emails"] = emails;
    data["issharingcontent"] = message.issharing;
    axios
      .post(process.env.REACT_APP_BASE_URL + "send-email", data)
      .then(function (response) {
        if (response.status === 200) {
          Swal.fire({
            title: "",
            text: "Messaggio inviato ai destinatari selezionati",
            icon: "success",
          }).then((result) => {
            if (result.isConfirmed) {
              onModalClose();
            }
          });
        } else {
          throw new Error(response.status);
        }
      })
      .catch(function (error) {
        Swal.fire({ title: "", text: "Errore!", icon: "error" });
      });
  };
  useEffect(() => {
    getUsers();
  }, []);

  if (!usersEmail) {
    return <div className="App">Loading...</div>;
  }
  return (
    <>
      <div className="dettagli-messaggio">
        <form className="messages" onSubmit={handleSubmit}>
          <h3>{title}</h3>
          <label>da</label>
          <input type="text" value={user.email} disabled name="sender" />
          <br />
          <br />
          <div ref={refDesOption}>
            <Select
              className="opzioni"
              name="users"
              placeholder="Seleziona destinatari"
              isMulti
              options={usersEmail}
              required
            />
            <br />
          </div>
          <label>oggetto</label>
          <input
            type="text"
            name="subject"
            defaultValue={message.subject}
            required
          />
          <br />
          <label>messaggio</label>
          <Editor
            apiKey={API_KEY}
            onInit={(evt, editor) => (editorRef.current = editor)}
            initialValue={message.body}
            init={{
              menubar: false,
              statusbar: false,
              resize: false,
              height: 200,
              min_height: 200,
              language: "it",
              content_style:
                "p { margin: 0; }, body { font-family : Helvetica, Arial, sans-serif, font-size:16px }",
              plugins: [
                "advlist autolink autoresize lists link image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste code help wordcount",
              ],
              toolbar:
                "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link | removeformat | code | help",
            }}
            onEditorChange={saveBody}
          />
          <br />
          <label>priorit&agrave; alta</label>
          <input name="priority" type="checkbox" />
          <br />
          <div style={{ width: "100%", textAlign: "right" }}>
            <Button
              className="btn btn-outline-primary rounded-1 btn-sm buttonmodal"
              type="submit"
            >
              Invia
            </Button>
            <Button
              className="btn btn-outline-primary rounded-1 btn-sm buttonmodal"
              type="button"
              onClick={onModalClose}
            >
              Annulla{" "}
            </Button>
          </div>
        </form>
      </div>
    </>
  );
}
export default MessaggioInvito;
