import axios from 'axios';
import Swal from "sweetalert2";
import {
    loginConfirmedAction,
    logout,
} from '../store/actions/AuthActions';

export function signUp(email, password) {
    //axios call
    const postData = {
        email,
        password,
        returnSecureToken: true,
    };
    return axios.post(
        process.env.REACT_APP_BASE_URL + 'signup',
        postData,
    );
}

export function login(email, password, timeExp) {
    const postData = {
        email,
        password,
        timeExp,
        returnSecureToken: true,
    };
    return axios.post(
        process.env.REACT_APP_BASE_URL + 'login',
        postData,
    );
}
export function loginapp(email, password, timeExp) {
    const postData = {
        email,
        password,
        timeExp,
        returnSecureToken: true,
    };
    return axios.post(
        process.env.REACT_APP_BASE_URL + 'loginapp',
        postData,
    );
}

export function formatError(errorResponse) {
    switch (errorResponse.error.message) {
        case 'EMAIL_EXISTS':
            //return 'Email already exists';
            Swal.fire({title : "Attenzione",text : "Indirizzo email già registrato",icon: "error"});
            break;
        case '401':
            //return 'Email not found';
            Swal.fire({title : "Attenzione",text : "Email o password non riconsciuti",icon: "error"});
           break;
        case 'INVALID_PASSWORD':
            //return 'Invalid Password';
            Swal.fire({title : "Attenzione",text : "Password  non valida",icon: "error"});
            break;
        case 'USER_DISABLED':
            Swal.fire({title : "Attenzione",text : "Email o password non riconsciuti",icon: "error"});
            return 'User Disabled';

        default:
            return '';
    }
}

export function saveTokenInLocalStorage(tokenDetails) {
    tokenDetails.expireDate = new Date(
        new Date().getTime() + tokenDetails.expiresIn * 1000,
    );
    localStorage.setItem('userDetails', JSON.stringify(tokenDetails));
}

export function runLogoutTimer(dispatch, timer, history) {
    setTimeout(() => {
        dispatch(logout(history));
    }, timer);
}

export function checkAutoLogin(dispatch, history) {
    let path = window.location.pathname;
    path = path.split("/");
    path = path[path.length - 1];
    const redirect = '/' + path + window.location.href.replace(window.location.host,'').replace(window.location.protocol,'').replace('//','').replace(window.location.pathname,'')
    if (window.location.search !== ''){
        localStorage.setItem('redirect-to', redirect)
    }else{
        localStorage.removeItem('redirect-to')
    }

    const tokenDetailsString = localStorage.getItem('userDetails');
    let tokenDetails = '';
    if (!tokenDetailsString) {
        dispatch(logout(history));
        return;
    }

    tokenDetails = JSON.parse(tokenDetailsString);
    let expireDate = new Date(tokenDetails.expireDate);
    let todaysDate = new Date();

    if (todaysDate > expireDate) {
        dispatch(logout(history));
        return;
    }
    dispatch(loginConfirmedAction(tokenDetails));

    const timer = expireDate.getTime() - todaysDate.getTime();
    runLogoutTimer(dispatch, timer, history);
}
