import { Card, CardMedia, CardContent, Typography } from "@material-ui/core";

const CategoryCard = (props) => {
  return (
    <Card elevation={3} style={{ minHeight: "380px" }}>
        <CardMedia
          component="img"
          style={{
            height: "0",
            paddingTop: "89.25%",
            backgroundImage: `url(${process.env.REACT_APP_BASE_URL_CONTENTS + props.item.immagine})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
      />
      <div
        style={{
          backgroundColor: props.item.colore,
          width: "100%",
          height: "4px",
        }}
      />
      <CardContent>
        <Typography gutterBottom variant="h6" component="div">
          {props.item.nome}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default CategoryCard;
