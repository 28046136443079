import React, { useState, useEffect } from 'react'
import { setDefaultLocale } from 'react-datepicker'
import SearchCategoryLayout from 'semantic-ui-react/dist/commonjs/modules/Search/SearchCategoryLayout'
import logo from '../../images/euracom-logo.png'

const PalinsestoPreview = (props) => {
    const [widthPlayer, setWidthPlayer] = useState('640px')
    const [heightPlayer, setHeightPlayer] = useState('360px')
    const [hiddenVideo, setHiddenVideo] = useState(true)
    const [hiddenImage, setHiddenImage] = useState(false)
    const [currentItemPlay, setCurrentItemPlay] = useState({})
    let indexContent = -1
    const player = document.getElementById('player')
    const contentImage = document.getElementById('image')
    const [data, setData] = useState([])
    const palinsesto = props.palinsesto
    function onModalClose() {
        player.pause()
        props.onCloseModal()
    }
    useEffect(() => {
        try {
            if (palinsesto.template === 0) {
                setWidthPlayer('640px')
                setHeightPlayer('360px')
            } else if (palinsesto.template === 1) {
                setWidthPlayer('360px')
                setHeightPlayer('640px')
            }
            start()
        } catch (Error) {}
    }, [])

    const playNextContent = () => {
        indexContent += 1
        try {
            if (indexContent >= data.length) {
                indexContent = 0
            }
        } catch (error) {}
        if (data[indexContent].filename) {
            playItem(data[indexContent])
        }
    }

    const getCurrentMidnightSeconds = () => {
        var now = new Date(),
            then = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0),
            diff = now.getTime() - then.getTime()
        return Math.round(diff / 1000)
    }
    useEffect(() => {
        if (data.length > 0) {
            let midnightTime = getCurrentMidnightSeconds()
            let ci = false

            data.forEach((planned) => {
                indexContent += 1
                if (planned.start_seconds > midnightTime) {
                    return
                }
            })
            playNextContent()
        }
    }, [data])
    const startPalinsesto = (dataPlan) => {
        if (dataPlan && dataPlan.json_plan) {
            setData(dataPlan.json_plan)
            indexContent = 0
            setTimeout(() => {
                if (data.length > 0) {
                    setCurrentItemPlay(data[indexContent])
                } else {
                    setCurrentItemPlay({})
                }
                playItem(currentItemPlay)
            }, 1000)
        } else {
            alert('Palinsesto vuoto')
        }
    }

    const playItem = (item) => {
        if (!item) {
            return
        }
        setCurrentItemPlay(item)
        if (player)
        {
            player.mute = true;
            player.onerror = function (e) {}
        }
        if (item.type === 0 || item.type === 1) {
            setHiddenImage(true)
            setHiddenVideo(false)
            player.src = process.env.REACT_APP_BASE_URL_CONTENTS + item.filename
            player.load()
            let startPlayPromise = player.play()
            if (startPlayPromise !== undefined) {
                startPlayPromise
                    .then(() => {
                        setTimeout((player.volume = 1.0), 2000)
                    })
                    .catch((error) => {
                        if (error.name === 'NotAllowedError') {
                            console.log(error)
                        } else {
                            console.log(error)
                        }
                        setTimeout(() => {
                            playNextContent()
                        }, 5000)
                    })
            }
        } else if (item.type === 2) {
            setHiddenImage(false)
            setHiddenVideo(true)
            contentImage.src = process.env.REACT_APP_BASE_URL_CONTENTS + item.filename
            setTimeout(() => {
                playNextContent()
            }, item.duration * 1000)
        }
    }
    const start = () => {
        let playlist = palinsesto.uuid
        let dayweek = new Date().getDay()
        fetch(process.env.REACT_APP_BASE_URL + 'pianificazione/' + playlist + '/' + dayweek)
            .then((res) => res.json())
            .then((res) => {
                if (res.length === 0) {
                    return
                }
                startPalinsesto(res.result[0])
            })
    }

    return (
        <>
            <p className="subtab">Anteprima palinsesto</p>
            <div
                id="contenitore"
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: '#FFF',
                    marginTop: '60px;',
                    width: widthPlayer,
                    height: heightPlayer,
                    margin: '0 auto',
                    padding: '4px',
                    border: 'solid 10px black',
                    borderRadius: 10,
                    overflow: 'hidden',
                    padding: 15
                }}
            >
                <video hidden={hiddenVideo} width="100%" id="player"></video>
                <img
                    hidden={hiddenImage}
                    id="image"
                    style={{
                        height: '100%',
                        width: '100%',
                        objectFit: 'contain'
                    }}
                />
            </div>
        </>
    )
}
export default PalinsestoPreview
