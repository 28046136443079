import { MDBDataTableV5 } from "mdbreact";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
//import { Modal, Button } from "react-bootstrap";
import dateFormat from "../functions/dateFormat";
import Swal from "sweetalert2";
import SectionSubtitle from "../components/Subtitles";
import CircleButtonComponent from "../components/Button/CircleButton";
import { Add, Delete, Edit } from "@mui/icons-material";
import { actions } from "react-table";
export let itemUtenti = [];

function Utenti() {
  const [currentUser] = useState(
    JSON.parse(localStorage.getItem("userDetails", "undefined"))
  );
  const [items, setItems] = useState([]);
  const [usersForRender, setUsersForRender] = useState([]);

  const deleteUser = (item) => {
    const dataDel = {};
    dataDel["id"] = item.id;
    fetch(process.env.REACT_APP_BASE_URL + "elimina-utente/", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataDel),
    })
      .then(function (response) {
        if (response.status === 200) {
          Swal.fire({
            title: "",
            text: "Operazione eseguita correttamente",
            icon: "success",
          }).then(function () {
            // window.location.href ='/impostazioni?tab=categorie'
            fetchUtenti();
          });
        }
      })
      .catch(function (error) {
        Swal.fire({
          title: "",
          text: "Impossibile eliminare l'utente selezionato",
          type: "error",
        });
      });
  };

  // const history = useHistory();
  function switchState(item) {
    if (item.id === 1 || item.id === currentUser.userid) {
      return;
    }

    if (item.status === 1) {
      item.status = 2;
    } else if (item.status === 2) {
      item.status = 1;
    } else if (item.status === 0) {
      Swal.fire({
        title: "",
        html: "Impossibile cambiare lo stato di un utente che non si è ancora attivato!<br><br>Per attivare l'account è necessario che l'utente esegua il login almeno una volta!",
        icon: "warning",
      });
      return;
    }
    let data = { status: item.status, id: item.id };
    fetch(process.env.REACT_APP_BASE_URL + "modifica-stato-utente/", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then(function (response) {
        if (response.status === 200) {
          fetchUtenti();
        } else {
          throw new Error(response.status);
        }
      })
      .catch(function (error) {});
  }
  setTimeout(() => {
    var buttons = document.getElementsByClassName("page-link");
    if (buttons.length > 0) {
      for (var i = 0; i < buttons.length; i++) {
        buttons[i].onclick = function () {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        };
      }
    }
  }, 1000);
  const fetchUtenti = () => {
    fetch(process.env.REACT_APP_BASE_URL + "utenti/")
      .then((res) => res.json())
      .then((res) => {
        if (res.length === 0) {
          setItems([{ id: -1, nome: "Nessun utente presente!" }]);
          return;
        }
        setItems(res.filter((u) => u.tipo >= currentUser.tipo));
      });
  };
  /* eslint-disable */
  useEffect(() => {
    fetchUtenti();
  }, []);
  const deleteRequest = (item) => {
    Swal.fire({
      title: "Confermi?",
      html:
        "Attenzione, l'utente <b> " +
        item.nome +
        " " +
        item.cognome +
        "</b> sarà eliminato!",
      allowOutsideClick: false,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: "Elimina",
      cancelButtonText: "Annulla",
      reverseButtons: true,
      icon: "question",
    }).then((result) => {
      if (result.value) {
        deleteUser(item);
      } else {
        Swal.fire("Operazione annullata");
      }
    });
  };
  /* eslint-disable */
  useEffect(() => {
    let itemsArray = JSON.parse(JSON.stringify(items));
    let userData = [];
    let TipoUtente = function (tipo) {
      if (tipo === 1) {
        return "Amministratore";
      } else if (tipo === 2) {
        return "Operatore";
      } else if (tipo === 3) {
        return "Cliente";
      }
    };
    itemsArray.map((item, index) => {
      if (item.id > 0) {
        item.valtipo = item.tipo;
        item.nometipo = TipoUtente(item.tipo);
        item.dataora = dateFormat(new Date(item.ultimo_aggiornamento));
        item.action = [];
        item.action.push(
          <CircleButtonComponent>
            <Link
              className="c-pointer button-action"
              onClick={() => {
                itemUtenti = item;
              }}
              to="modifica-utente"
            >
              <Edit sx={{ color: "white" }} />
            </Link>
          </CircleButtonComponent>
        );
        if (item.tipo !== 1 && item.id !== currentUser.id) {
          item.action.push(
            <CircleButtonComponent
              icon={<Delete />}
              onClick={() => deleteRequest(item)}
            />
          );
        }

        if (item.status === 1) {
          item.tagz = (
            <span
              className="text-center c-status rounded-circle bg-success"
              onClick={() => switchState(item)}
            ></span>
          );
        } else if (item.status === 2) {
          item.tagz = (
            <span
              className="text-center c-status rounded-circle bg-danger"
              onClick={() => switchState(item)}
            ></span>
          );
        } else if (item.status === 0) {
          item.tagz = (
            <span
              className="text-center c-status rounded-circle bg-light"
              onClick={() => switchState(item)}
            ></span>
          );
        }
      }
      userData.push(item);
    });
    setUsersForRender(userData);
  }, [items]);

  const data = {
    columns: [
      {
        label: "",
        field: "",
        sort: "disabled",
        width: "10",
      },

      {
        label: "Nome",
        field: "nome",
        sort: "asc",
        width: "250",
      },

      {
        label: "Cognome",
        field: "cognome",
        sort: "asc",
        width: "250",
      },
      {
        label: "Email",
        field: "email",
        sort: "asc",
        width: "250",
      },
      {
        label: "tipo",
        field: "nometipo",
        sort: "asc",
        width: "250",
      },
      {
        label: "Ultimo Aggiornamento",
        field: "dataora",
        sort: "asc",
        width: "250",
      },

      {
        label: "Stato",
        field: "tagz",
        sort: "disabled",
        width: "30",
      },
      {
        label: "Azioni",
        field: "action",
        sort: "disabled",
        width: "250",
      },
    ],

    rows: usersForRender,
  };

  return (
    <div className="tabella_gestioneutenti">
      <h2 className="tab-menu">Gestione Utenti</h2>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "60px",
        }}
      >
        <SectionSubtitle text="Visualizza le informazioni anagrafiche dei tuoi dipendenti e dei tuoi punti vendita." />
        <div style={{ display: "flex", gap: 12 }}>
          <CircleButtonComponent
            text="Nuovo collaboratore"
            icon={<Add />}
            onClick={() => (window.location.href = "nuovo-utente")}
          />
          <CircleButtonComponent
            text="Nuovo cliente"
            icon={<Add />}
            onClick={() =>
              (window.location.href =
                "/impostazioni?tab=anagrafiche&action=aggiunta")
            }
          />
        </div>
      </div>

      <div className="tabella_utenti">
        <MDBDataTableV5
          hover
          data={data}
          disableRetreatAfterSorting={true}
          proSelect
          searchTop
          searchBottom={false}
          fullPagination
          searchLabel={"Cerca"}
          responsive
        />
      </div>
    </div>
  );
}
export { Utenti as default, itemUtenti as array };
