import { useEffect, useState, useRef} from "react";
import { Button} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import axios from 'axios';
import {itemContenuti} from "./contenuti";

function ContenutoBrands() {
  const chkRef = useRef();
  const history = useHistory();
  const [itemsBrands, setItemsBrands] = useState([]);
  const [itemsAggregations, setItemsAggregations] = useState([]);
  const fetchBrands= () => {
    fetch(process.env.REACT_APP_BASE_URL + "contenuti-brands/" + itemContenuti.id)
    .then((res) => res.json())
    .then((res) => {
        setItemsAggregations(res);
        fetch(process.env.REACT_APP_BASE_URL + "brands")
        .then((res) => res.json())
        .then((res) => {
            setItemsBrands(res);
        });
    });
   
  }
  const toggleItem = (i,e) => 
  {
    const data = {};
    data['id_brand'] = i.id;
    data['id_contenuto'] = itemContenuti.id;

    if (e.checked) {
      axios.post(process.env.REACT_APP_BASE_URL + "inserisci-brand-contenuto", data)
      .then(function(response) {
        if (response.status === 200) {
          itemContenuti.brands.forEach((b) =>
          {
            document.getElementById("brand"+b.id).checked = false;
          });
          itemContenuti.brands.splice(0,itemContenuti.brands.length);  
          itemContenuti.brands.push(i)
        }
        else {throw new Error(response);}}).catch(function(error){console.log(error);});
    }
    else {
      axios.post(process.env.REACT_APP_BASE_URL + "rimuovi-brand-contenuto", data)
        .then(function(response) {
        if (response.status === 200) {
          if (itemContenuti.brands.find((item) => { return item.id === i.id; }))
          {
            itemContenuti.brands.splice(itemContenuti.brands.indexOf(i),1);      
          }
        }
        else {throw new Error(response);}}).catch(function(error){console.log(error);});
    }    
}
const getChecked = (itemId) => 
{
  try
  {
    return itemsAggregations[0].brands.find((item) => { return item.id === itemId; });
  }
  catch(error)
  {
    return false;
  }
}


useEffect(() => { fetchBrands(); }, []); 
if (itemsBrands.length === 0)
{
  return (<div className="App">Caricamento in corso...</div>);
}
 
  
return(
  <>
    <div className="nuovo-palinsesto">  
      <div className="buttons-top-right">
        <Button className="btn btn-outline-primary rounded-1 btn-sm" onClick={() => {history.push('contenuti?tab=cartelle');}} type="button">Indietro</Button>
      </div>
      <p className="subtab">Gestisci l'associazione del contenuto <b>{itemContenuti.nome} </b>ai brands</p>
      <ul>
        {itemsBrands.map(item => (
        <li> 
        <input type="checkbox" id={"brand" + item.id} ref={chkRef} defaultChecked={getChecked(item.id)} onChange={(e) => { toggleItem(item,e.currentTarget); } }/>
        <label for={"brand" + item.id}>
          <img className="" src= {process.env.REACT_APP_BASE_URL_CONTENTS + item.logo} alt="" />
        </label>
        </li>   
        ))}
      </ul> 
    </div>
  </>
  ) 
} 							

export default ContenutoBrands
