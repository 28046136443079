import {useState} from "react";
import {useHistory} from "react-router-dom";
import {Tabs, Tab} from "react-bootstrap";
import {default as DatiAziendali} from "./dati-aziendali";
import {default as Brands} from "./brands";
import {default as ModificaBrand} from "./modifica-brand";
import {default as Categorie} from "./categorie";
import {default as Anagrafiche} from "./anagrafiche";
import {default as NuovaAnagrafica} from "./nuova-anagrafica";
import {default as ModificaAnagrafica} from "./modifica-anagrafica";
import {default as GestioneLicenze} from "./gestione-licenze";
import {default as NuovoBrand} from "./nuovo-brand";
import {default as NuovaCategoria} from "./nuova-categoria";
import {default as ModificaCategoria} from "./modifica-categoria";
import {default as Tags} from "./tags";
import {default as NuovoTag} from "./nuovo-tag";
import {default as ModificaTag} from "./modifica-tag";
import {default as Zone} from "./zone";
import {default as NuovaZona} from "./nuova-zona";
import {default as ModificaZona} from "./modifica-zona";
import {default as CategoriaBrands} from "./categoria-brands";
import getQueryVariable from "../functions/getQueryVariable";


  function Impostazioni() {
    const tab = getQueryVariable('tab');
    const action = getQueryVariable('action');

    console.log('tab: ', tab)
    console.log('action: ', action)
    let titolo = "Impostazioni"
    if (action === "modifica"){
      titolo = "Impostazioni > Modifica"
    }
    if (action === "aggiunta"){
      titolo = "Impostazioni > Aggiunta"
    }
    if (action === "associazione-brands"){
      titolo = "Impostazioni > Associazione brands"
    }
    let mbrand= <Brands />
    let mcategorie = <Categorie />
    let mtag =<Tags />
    let managrafiche = <Anagrafiche />
    let mzona =<Zone />
    if (tab ==="brands" && action === "modifica"){
   
      mbrand= <ModificaBrand />
      
    }
    if (tab ==="brands" && action === "aggiunta"){
   
      mbrand= <NuovoBrand />
      
    }

    if (tab ==="zone" && action === "modifica"){
   
      mzona= <ModificaZona />
      
    }
    if (tab ==="zone" && action === "aggiunta"){
   
      mzona= <NuovaZona />
      
    }



    if (tab ==="categorie" && action === "aggiunta"){
   
      mcategorie= <NuovaCategoria />
      
    }
    if (tab ==="categorie" && action === "modifica"){
   
      mcategorie= <ModificaCategoria />
      
    }
    if (tab ==="categorie" && action === "associazione-brands"){
   
      mcategorie= <CategoriaBrands />
      
    }

    if (tab ==="tags" && action === "aggiunta"){
   
      mtag= <NuovoTag />
      
    }
    if (tab ==="tags" && action === "modifica"){
   
      mtag= <ModificaTag />
      
    }
    if (tab ==="anagrafiche" && action === "aggiunta"){
   
      managrafiche= <NuovaAnagrafica />
      
    }
    if (tab ==="anagrafiche" && action === "modifica"){
   
      managrafiche= <ModificaAnagrafica />
      
    }
    const [key, setKey] = useState(tab);
    const history = useHistory();

    return (
      <><h2 className="tab-menu">{titolo}</h2>
      <Tabs
        id="controlled-impostazioni"
        activeKey={key}
        onSelect={(k) => { history.push('/impostazioni?tab=' + k);  setKey(k); }}
        className="mb-3 h4 custom-tab-1 settings-nav"
      >
        <Tab eventKey="dati-aziendali" title="Dati Aziendali">
          <DatiAziendali />
        </Tab>
        <Tab eventKey={"anagrafiche"} title="Anagrafiche clienti">
          {managrafiche}
        </Tab>
        <Tab eventKey="gestione-licenze" title="Gestione licenze">
          <GestioneLicenze />
        </Tab> 
        <Tab eventKey="brands" title="Brands">
       {mbrand}
          
        </Tab>
        <Tab eventKey="categorie" title="Categorie">
          {mcategorie}
        </Tab>
        <Tab eventKey="tags" title="Tags">
          {mtag}
        </Tab>
        <Tab eventKey="zone" title="Zone di vendita">
          {mzona}
        </Tab>
      </Tabs></>
    );
  }
 
  export default Impostazioni;