import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Tabs, Tab } from "react-bootstrap";

import { default as NuovoContenuto } from "./nuovo-contenuto";
import { default as ModificaContenuto } from "./modifica-contenuto";
import { default as ContenutoBrands } from "./contenuto-brands";
import { default as Contenuto } from "./contenuto";
import { default as Cartelle } from "./cartelle";
import { default as ContenutoCategorie } from "./contenuto-categorie";
import { default as ContenutoTags } from "./contenuto-tags";
import { default as InserimentoContenuti } from "./inserimento-contenuti";
import { default as ElementiModal } from "./elementi-modal";
//import { mt } from "date-fns/locale";
function Elementi() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [buttonPressed, setButtonPressed] = useState(false);
  const [currentFolder, setCurrentFolder] = useState([]);

  const [tab, setTab] = useState();
  const [action, setAction] = useState();
  const [item, setItem] = useState();

  const [key, setKey] = useState(tab);
  const history = useHistory();
  const [moduloContenuti, setModuloContenuti] = useState(<></>);
  const [titolo, setTitolo] = useState("Cartelle");

  const queryParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    setTab(queryParams.get("tab"));
    setAction(queryParams.get("action"));
    setItem(queryParams.get("item"));
  }, [queryParams]);

  useEffect(() => {
    handleNavigation();
  }, [tab, action, item]);

  const handleFolder = (folder) => {
    if (folder.id) {
      setCurrentFolder(folder);
    }
  };
  const handleNavigation = () => {
    if (action === "modifica") {
      setTitolo("Contenuti > Modifica");
    }
    if (action === "aggiunta") {
      setTitolo("Contenuti > Aggiunta");
    }
    if (action === "contenuto" && item > 0) {
      setTitolo("Mostra Contenuto");
    }
    if (action === "associazione-brands") {
      setTitolo("Impostazioni > Associazione brands");
    }
    if (action === "associazione-categorie") {
      setTitolo("Impostazioni > Associazione categorie");
    }
    if (action === "wizard") {
      setTitolo("Contenuti > Aggiunta > Wizard");
    }
    if (action === "associazione-tags") {
      setTitolo("Impostazioni > Associazione tags");
    }
    if (tab === "contenuti" && action === "aggiunta") {
      setModuloContenuti(<NuovoContenuto />);
    }
    if (tab === "contenuti" && action === "modifica") {
      setModuloContenuti(<ModificaContenuto />);
    }
    if (tab === "contenuti" && action === "wizard") {
      setModuloContenuti(<InserimentoContenuti />);
    }
    if (tab === "contenuti" && action === "associazione-brands") {
      setModuloContenuti(<ContenutoBrands />);
    }
    if (tab === "contenuti" && action === "associazione-categorie") {
      setModuloContenuti(<ContenutoCategorie />);
    }
    if (tab === "contenuti" && action === "associazione-tags") {
      setModuloContenuti(<ContenutoTags />);
    }
    if (tab === "contenuti" && action === "contenuto") {
      setModuloContenuti(<Contenuto />);
    }
    if (tab === "cartelle" && action == null) {
      setTitolo("Cartelle");
      setModuloContenuti(
        <Cartelle
          tab={tab}
          action={action}
          handleFolder={handleFolder}
          currentFolder={currentFolder}
        />
      );
    }
    if (tab === "contenuti" && !action) {
      setTitolo("Contenuti > Cartella " + currentFolder.nome);
      setModuloContenuti(
        <Cartelle
          tab={tab}
          handleFolder={handleFolder}
          currentFolder={currentFolder}
        />
      );
    }
  };

  return (
    <>
      <ElementiModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
      {moduloContenuti}
    </>
  );
}

export default Elementi;
