import { MDBDataTableV5 } from "mdbreact";
import { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import dateFormat from "../functions/dateFormat";
import MessaggioInvito from "./messaggio-invito";
import axios from "axios";
import SectionSubtitle from "../components/Subtitles";
import CircleButtonComponent from "../components/Button/CircleButton";
import { Add } from "@mui/icons-material";
function UtentiNonAttivi(props) {
  const [currentUser] = useState(props.currentUser);
  const [messageData, setMessageData] = useState({});
  const [modelsMessages] = useState([]);
  const [titleComponent, setTitleComponent] = useState("Nuovo Messaggio");
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    fetchUtenti();
  };
  const handleShow = () => setShow(true);
  const [items, setItems] = useState([]);
  const [usersForRender, setUsersForRender] = useState([]);
  const getModels = () => {
    try {
      modelsMessages.push({
        id: 0,
        messaggio: "",
        categoria: 0,
        id_utente: currentUser.userid,
      });
      modelsMessages.push({
        id: 0,
        messaggio: "",
        categoria: 1,
        id_utente: currentUser.userid,
      });
      modelsMessages.push({
        id: 0,
        messaggio: "",
        categoria: 2,
        id_utente: currentUser.userid,
      });
      modelsMessages.push({
        id: 0,
        messaggio: "",
        categoria: 3,
        id_utente: currentUser.userid,
      });

      var data = { id_user: currentUser.userid, token: currentUser.idToken };
      axios
        .post(process.env.REACT_APP_BASE_URL + "get-models", data)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.length > 0) {
              var data = res.data;
              data.forEach((i) => {
                modelsMessages.forEach((m) => {
                  if (m.categoria === i.categoria) {
                    m.messaggio = i.messaggio;
                    m.id = i.id;
                  }
                });
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUtenti = () => {
    try {
      fetch(process.env.REACT_APP_BASE_URL + "clienti")
        .then((res) => res.json())
        .then((res) => {
          if (res.length === 0) {
            setItems([{ id: -1, nome: "Nessun utente non attivo presente!" }]);
            return;
          }
          setItems(res.filter((u) => u.status === 0 && u.tipo === 2));
        });
    } catch (error) {}
  };

  useEffect(() => {
    fetchUtenti();
    getModels();
  }, []);

  setTimeout(() => {
    var buttons = document.getElementsByClassName("page-link");
    if (buttons.length > 0) {
      for (var i = 0; i < buttons.length; i++) {
        buttons[i].onclick = function () {
          fetchUtenti();
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        };
      }
    }
  }, 1000);

  const sendInvitation = () => {
    setTitleComponent("Invia inviti");

    setMessageData({
      to: "",
      toaddress: "",
      body: modelsMessages[2].messaggio + modelsMessages[0].messaggio,
      subject: "Invito a provare la piattaforma",
      isresponse: false,
    });
    handleShow();
  };

  useEffect(() => {
    let itemsArray = JSON.parse(JSON.stringify(items));
    let userData = [];
    let TipoUtente = function (tipo) {
      if (tipo === 1) {
        return "Amministratore";
      } else if (tipo === 2) {
        return "Operatore";
      } else if (tipo === 3) {
        return "Cliente";
      }
    };
    itemsArray.map((item, index) => {
      if (item.id > 0) {
        item.nometipo = TipoUtente(item.tipo);
        if (items[index].status === 0) {
          item.dataora = dateFormat(new Date(item.data_creazione));
          item.action = (
            <div
              style={{ display: "flex", justifyContent: "space-between" }}
            ></div>
          );
        }
        userData.push(item);
      }
    });
    if (userData.length > 0) {
      setUsersForRender(userData);
    } else {
      setUsersForRender([{ id: -1, nome: "Nessun utente non attivo!" }]);
    }
  }, [items]);

  const data = {
    columns: [
      {
        label: "",
        field: "",
        sort: "disabled",
        width: "10",
      },
      {
        label: "Nome",
        field: "nome",
        sort: "asc",
        width: 270,
      },

      {
        label: "Cognome",
        field: "cognome",
        sort: "asc",
        width: 200,
      },
      {
        label: "Email",
        field: "email",
        sort: "asc",
        width: 200,
      },
      {
        label: "Ruolo",
        field: "nometipo",
        sort: "asc",
        width: 200,
      },

      {
        label: "Registrato il",
        field: "dataora",
        sort: "asc",
        width: 150,
      },
    ],

    rows: usersForRender,
  };

  return (
    <div className="tabella_utenti">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "32px",
        }}
      >
        <SectionSubtitle text="Visualizza gli utenti registrati che non si sono mai connessi." />
        <CircleButtonComponent
          icon={<Add />}
          onClick={() => sendInvitation()}
        />
      </div>
      <MDBDataTableV5
        hover
        data={data}
        proSelect
        searchTop
        searchBottom={false}
        fullPagination
        searchLabel={"Cerca"}
        responsive
        disableRetreatAfterSorting={true}
      />
      <Modal
        dialogClassName="modal-messages"
        enforceFocus={false}
        show={show}
        onRequestClose={handleClose}
      >
        <Modal.Body>
          <Button
            style={{ float: "right" }}
            variant="link"
            onClick={handleClose}
          >
            <i style={{ fontSize: "120%" }} className="bi bi-x"></i>
          </Button>
          <MessaggioInvito
            id="messaggio"
            onCloseModal={handleClose}
            message={messageData}
            title={titleComponent}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default UtentiNonAttivi;
