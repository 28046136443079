import { useEffect, useState, useRef} from "react";
import { Button} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import axios from 'axios';
import {itemContenuti} from "./contenuti";
function ContenutoTags() {
  const chkRef = useRef();
  const history = useHistory();
  const [itemsTags, setItemsTags] = useState([]);
  const [itemsAggregations, setItemsAggregations] = useState([]);

  const fetchTags= () => {
    fetch(process.env.REACT_APP_BASE_URL + "contenuti-tags/" + itemContenuti.id)
    .then((res) => res.json())
    .then((res) => {
        setItemsAggregations(res);
        fetch(process.env.REACT_APP_BASE_URL + "tags")
          .then((res) => res.json())
          .then((res) => {
              setItemsTags(res);
          });
    });
    
  }
  const toggleItem = (i,e) => 
  {
    const data = {};
    data['id_tag'] = i.id;
    data['id_contenuto'] = itemContenuti.id;

    if (e.checked) {
      axios.post(process.env.REACT_APP_BASE_URL + "inserisci-tag-contenuto", data)
      .then(function(response) {
        if (response.status === 200) {
          if (!itemContenuti.tags.find((item) => { return item.id === i.id; })) {itemContenuti.tags.push(i);}
        }
        else {throw new Error(response);}}).catch(function(error){});
    }
    else {
      axios.post(process.env.REACT_APP_BASE_URL + "rimuovi-tag-contenuto", data)
        .then(function(response) {
        if (response.status === 200) {
          if (itemContenuti.tags.find((item) => { return item.id === i.id; }))
          {
            itemContenuti.tags.splice(itemContenuti.tags.indexOf(i),1);      
          }
        }
        else {throw new Error(response);}}).catch(function(error){});
      
    }    
}
const getChecked = (itemId) => 
{
  try
  {
    return itemsAggregations[0].tags.find((item) => { return item.id === itemId; });
  }
  catch(error)
  {
    return false;
  }
}


useEffect(() => { fetchTags(); }, []); 
if (itemsAggregations.length === 0)
{
  return (<div className="App">Caricamento in corso...</div>);
}
 
  
return(
  <>
    <div className="nuovo-palinsesto">  
      <div className="buttons-top-right">
        <Button className="btn btn-outline-primary rounded-1 btn-sm" onClick={() => {history.push('contenuti?tab=cartelle');}} type="button">Indietro</Button>
      </div>
      <p className="subtab">Gestisci l'associazione del contenuto <b>{itemContenuti.nome} </b>ai tags</p>
      <ul>
        {itemsTags.map(item => (
           <li> 
           <input type="checkbox" id={"brand" + item.id} ref={chkRef} defaultChecked={getChecked(item.id)} onChange={(e) => { toggleItem(item,e.currentTarget); } }/>
           <label for={"brand" + item.id}>
             
             <div className="" style={ { background : item.colore, height : '100px', color : '#fff', borderRadius:'8px', fontSize:'1.2rem', lineHeight:'100px',textShadow:'1px 1px 1px #000'} } alt="" >{item.nome}</div>
           </label>
           </li>   
        ))}
      </ul> 
    </div>
  </>
  ) 
} 							

export default ContenutoTags
