import React, { Component } from 'react'
import { Col, Row, Card } from 'react-bootstrap'
import FullCalendar from '@fullcalendar/react'
//import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from '@fullcalendar/timegrid'
import momentPlugin from '@fullcalendar/moment'
import moment from 'moment'
import itLocale from '@fullcalendar/core/locales/it'
import { v4 as uuidv4 } from 'uuid'
import interactionPlugin, { Draggable } from '@fullcalendar/interaction'
import Alert from 'sweetalert2'
import { palinsestoData } from './nuovo-palinsesto'
import { Modal, Button } from 'react-bootstrap'
import SlotPalinsestoModal from './slot-palinsesto-modal'

class NuovoPalinsesto2 extends Component {
   constructor(props) {
      super(props)

      this.state = {
          eventClick: null,
      }
  }
    componentDidMount() {
        let draggableEl = document.getElementById('external-events')
        new Draggable(draggableEl, {
            itemSelector: '.fc-event',
            eventData: function (eventEl) {
                let title = eventEl.getAttribute('title')
                let id = eventEl.getAttribute('data')
                return {
                    title: title,
                    id: uuidv4(),
                    duration: '60',
                    durationEditable: true,
                    startEditable: true,
                    extendedProps: {
                        palinsesto: palinsestoData.uuid,
                        idItem: id,
                        idContent: 0,
                        isNew: true,
                        startTime: '00:00',
                        endTime: '00:00',
                        duration: '60',
                        dayOfWeek: '0',
                        elementsToHide: '[]',
                        orderedList: '[]',
                    },
                }
            },
        })
    }
    eventReceive = (e) => {
        palinsestoData.eventi.push(e.event)
    }
    eventAdd = (e) => {
        palinsestoData.eventi.push(e.event)
    }
    addEvent = (e) => {
        palinsestoData.eventi.push(e.event)
    }
    updateEvent = (e) => {
        let duration =
            e.event.end.getHours() * 60 +
            e.event.end.getMinutes() -
            (e.event.start.getHours() * 60 + e.event.start.getMinutes())
        if (duration < 0) {
            duration = 1440 + duration
        }
        let changedEvent = {
            title: e.event.title,
            id: e.event.id,
            durationEditable: true,
            startEditable: true,
            extendedProps: {
                palinsesto: palinsestoData.uuid,
                idItem: e.event.extendedProps.idItem,
                idContent: 0,
                isNew: e.event.extendedProps.isNew,
                startTime:
                    (e.event.start.getHours() < 10 ? '0' + e.event.start.getHours() : e.event.start.getHours()) +
                    ':' +
                    (e.event.start.getMinutes() < 10 ? '0' + e.event.start.getMinutes() : e.event.start.getMinutes()),
                endTime:
                    (e.event.end.getHours() < 10 ? '0' + e.event.end.getHours() : e.event.end.getHours()) +
                    ':' +
                    (e.event.end.getMinutes() < 10 ? '0' + e.event.end.getMinutes() : e.event.end.getMinutes()),
                duration: duration,
                dayOfWeek: e.event.start.getDay(),
                elementsToHide: e.event.extendedProps.elementsToHide,
                orderedList: e.event.extendedProps.orderedList,
            },
        }
        const index = palinsestoData.eventi.findIndex((evt) => evt.id === changedEvent.id)
        palinsestoData.eventi[index] = changedEvent
    }

    eventRemove = (e) => {
        const index = palinsestoData.eventi.findIndex((evt) => evt.id === e.event.id)
        palinsestoData.eventi.splice(index, 1)
    }
    eventChange = (e) => {
        this.updateEvent(e)
    }
    eventDrop = (e) => {
        let evento = palinsestoData.eventi.find((ee) => {
            return e.oldEvent.id === ee.id
        })
        if (evento) {
            this.updateEvent(e)
        }
    }
    eventSet = (e) => {
        console.log(e)
    }
    /**
     * when we click on event we are displaying event details
     */
    eventClick = (eventClick) => {
      this.setState({ eventClick })
    }

    render() {
        return (
            <div className="animated fadeIn">
                <Row>
                    <Col lg={2}>
                        <p className="add-brand-list">Brand disponibili:</p>
                        <div id="external-events">
                            {palinsestoData.items.map((item) => (
                                <div
                                    className="fc-event btn-block btnEvent"
                                    title={item.nome}
                                    data={item.id}
                                    key={item.id}
                                >
                                    <img
                                        src={process.env.REACT_APP_BASE_URL_CONTENTS + item.logo}
                                        className="brandEvent"
                                        alt={item.nome}
                                    />
                                </div>
                            ))}
                        </div>
                    </Col>
                    <Col lg={10}>
                        <Card>
                            <Card.Body>
                                <div className="demo-app-calendar" id="mycalendartest">
                                    <FullCalendar
                                        // scrollTime=''
                                        //scrollToTime='06:00:00'
                                        slotMinTime="06:00:00"
                                        slotMaxTime="24:00:00"
                                        // slotDuration="01:00:00"
                                        height="930px"
                                        view={{
                                            schedule: {
                                                type: 'week',
                                            },
                                        }}
                                        plugins={[timeGridPlugin, interactionPlugin, momentPlugin]}
                                        headerToolbar={{
                                            left: '',
                                            center: '',
                                            right: '',
                                        }}
                                        eventColor="gray"
                                        defaultEventMinutes="60"
                                        locale={itLocale}
                                        initialView="timeGridWeek"
                                        slotLabelFormat={{
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            omitZeroMinute: false,
                                            meridiem: '',
                                            hour12: false,
                                        }}
                                        editable={true}
                                        selectable={false}
                                        selectMirror={true}
                                        eventOverlap={false}
                                        forceEventDuration={true}
                                        //dayMaxEvents={true}
                                        // dayMaxEventRow ={1}
                                        displayEventTime={false}
                                        droppable={true}
                                        weekends={true}
                                        allDaySlot={false}
                                        firstDay={1}
                                        // contentHeight=
                                        dayHeaderFormat={{
                                            weekday: 'short',
                                        }}
                                        //initialEvents={this.state} // alternatively, use the `events` setting to fetch from a feed
                                        select={this.handleDateSelect}
                                        ref={this.calendarComponentRef}
                                        // weekends={this.state.calendarWeekends}
                                        events={palinsestoData.eventi}
                                        eventReceive={this.eventReceive}
                                        eventClick={this.eventClick}
                                        eventAdd={this.eventAdd}
                                        eventDrop={this.eventDrop}
                                        eventChange={this.eventChange}
                                        eventRemove={this.eventRemove}
                                        eventsSet={this.eventsSet}
                                        // eventContent={renderEventContent} // custom render function
                                    />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Modal
                    dialogClassName="modal-messages"
                    show={this.state.eventClick != null}
                    onRequestClose={() => this.setState({ eventClick: null })}
                >
                    <Modal.Body>
                        <Button
                            style={{ float: 'right' }}
                            variant="link"
                            onClick={() => this.setState({ eventClick: null })}
                        >
                            <i style={{ fontSize: '120%' }} className="bi bi-x"></i>
                        </Button>
                        {this.state.eventClick != null ? (
                            <SlotPalinsestoModal
                                eventClick={this.state.eventClick}
                                onCloseModal={() => this.setState({ eventClick: null })}
                            />
                        ) : null}
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}

export default NuovoPalinsesto2
