import React, {useState} from "react";
import DatiLicenzeTabella from "./dati-licenze-tabella"
import ChartGestioneLicenze from "./chart-gestione-licenze"
const GestioneLicenze = () => {
	const [refresh, setRefresh] = useState(true);
	const updateChart =() => {
		setRefresh(true);
	}
	return(
		<>
			<div style={{marginBottom:"60px"}}>
				<ChartGestioneLicenze refresh={refresh} setRefresh={setRefresh}/>
 			</div>
			<div className="tabelladati">
				<DatiLicenzeTabella onUpdateChart={updateChart}/>
			</div>
		</>
	)
}
export default GestioneLicenze

