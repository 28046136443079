import React, { useState, useMemo, useEffect } from 'react'
import Swal from 'sweetalert2'
import 'antd/dist/antd.css'
import { Steps, Button } from 'antd'
import InserimentoContenuti1 from './inserimento-contenuti-1'
import InserimentoContenuti2 from './inserimento-contenuti-2'
import InserimentoContenuti3 from './inserimento-contenuti-3'

const { Step } = Steps
export const InserimentoContenuti = () => {
    const [current, setCurrent] = useState(0)
    const [folder, setFolder] = useState()
    const [brand, setBrand] = useState()
    const [categories, setCategories] = useState([])
    const [fileItems, setFileItems] = useState([])
    const [buttonEndDisabled, setButtonEndDisabled] = useState(true)

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search)

        fetchBrands(queryParams)
        fetchFolders(queryParams)
    }, [])

    const fetchBrands = (queryParams) => {
        fetch(process.env.REACT_APP_BASE_URL + 'brands')
            .then((res) => res.json())
            .then((res) => {
                let foundBrand = res.find((b) => b.id === parseInt(queryParams.get('brand'), 10))

                if (foundBrand === undefined) {
                    foundBrand = res[0]
                }

                setBrand(foundBrand)
            })
    }

    const fetchFolders = (queryParams) => {
        fetch(process.env.REACT_APP_BASE_URL + 'cartelle')
            .then((res) => res.json())
            .then((res) => {
                let foundFolder = res.find((b) => b.id === queryParams.get('folder'))

                setFolder(foundFolder)
            })
    }

    const verifyStepFiles = () => {
        if (fileItems.length === 0) {
            Swal.fire({ title: '', text: 'Selezionare la cartella o i files da caricare', icon: 'warning' })
            return true
        }
        return false
    }

    const scrollTop = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }

    const onChange = (current) => {
        if (current === 1) {
            if (verifyStepFiles()) {
                return
            }
        }
        setCurrent(current)
        scrollTop()
    }

    const next = () => {
        if (current === 1) {
            if (verifyStepFiles()) {
                return
            }
        }
        setCurrent(current + 1)
        scrollTop()
    }

    const prev = () => {
        setCurrent(current - 1)
    }

    const lastStepEnabler = () => {
        setButtonEndDisabled(false)
    }

    const steps = useMemo(
        () => [
            {
                title: 'Step 1',
                content: (
                    <InserimentoContenuti1
                        onScrollTop={scrollTop}
                        selectedCategories={categories}
                        brand={brand}
                        folder={folder}
                        changeSelectedCategories={(newCategories) => setCategories(newCategories)}
                    />
                ),
                disabled: false,
                brand: brand
            },
            {
                title: 'Step 2',
                content: <InserimentoContenuti2 onScrollTop={scrollTop} fileItems={fileItems} changeFiles={(fileItems) => setFileItems(fileItems)} />,
                disabled: false
            },
            {
                title: 'Step 3',
                content: (
                    <InserimentoContenuti3
                        onScrollTop={scrollTop}
                        brand={brand}
                        folder={folder}
                        categories={categories}
                        fileItems={fileItems}
                        loader={lastStepEnabler}
                    />
                ),
                disabled: false
            }
        ],
        [brand, folder, fileItems]
    )

    return (
        <>
            <Steps style={{margin: '0 auto', width: '90%'}} type="navigation" className="nav-steps" current={current} onChange={onChange}>
                <Step disabled={current === 2} title="Step 1" description="Scelta del brand e assegnazione delle categorie" />
                <Step disabled={current === 2} title="Step 2" description="Selezione dei contenuti." />
                <Step title="Step 3" description="Caricamento." />
            </Steps>
            <div className="nuovo-inserimento">
                <div className="steps-actions">
                    {current > 0 && (
                        <Button className="step-previous" hidden={current === 2} onClick={prev}>
                            Indietro
                        </Button>
                    )}
                    {current < steps.length - 1 && (
                        <Button type="primary" className="step-next" onClick={next}>
                            Avanti
                        </Button>
                    )}
                </div>
                {steps[current].content}
            </div>
        </>
    )
}
export default InserimentoContenuti
