import React from "react";
import templateH from "../../images/templateH.png"
import templateV from "../../images/templateV.png"

import { palinsestoData } from "./nuovo-palinsesto";
const NuovoPalinsesto3= () => {
  const handleChange = (e) => {
    palinsestoData.template = e.target.value;
  }


   return (<>
      <div className="templates "> 
        <p className="subtab">Seleziona il template da associare al palinsesto che stai creando.</p>
        <label className="template" >
          <input defaultChecked={palinsestoData.template === 1} onChange={handleChange} type="radio" name="test" value="1"  />
          <img src= {templateV}  alt="templateV"/>
        </label>
      <label className="template" >
        <input defaultChecked={palinsestoData.template === 0}  onChange={handleChange} type="radio" name="test" value="0" checked/>
        <img src= {templateH}  alt="templateH"/>
      </label>
      
    </div>
</>

   );
};

export default NuovoPalinsesto3;