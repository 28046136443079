import { THEME_RED } from "../../CONST";

const Divider = (props) => {
  return (
    <div
      style={{
        width: "100%",
        height: 2,
        backgroundColor: THEME_RED,
        margin: "24px 12px",
      }}
    />
  );
};
export default Divider;
