import {  useState} from "react";
import { Modal, Button } from "react-bootstrap";
import Messaggio from "./messaggio";

export default function Test() {


    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


  return (
    <>
    <Button variant="primary" onClick={handleShow}>
        scrivi
      </Button>
      <Modal dialogClassName="modal-messages" show={show} onRequestClose={handleClose}>
        <Modal.Body>
            <Button style={{float:'right'}} variant="link" onClick={handleClose}><i style={{fontSize:'120%'}} className="bi bi-x"></i></Button>
            <Messaggio onCloseModal={handleClose} />
        </Modal.Body>
      </Modal>
    </>
  );
}
