import { useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { default as PlayerSub } from "./player-sub";
import { default as AssociaPlayer } from "./player-associa";
import { default as PlayerLicenze } from "./player-licenze";
import getQueryVariable from "../functions/getQueryVariable";
import CustomHeader from "../components/Header/CustomHeader";
import CircleButtonComponent from "../components/Button/CircleButton";
import { Help } from "@mui/icons-material";

function Player() {
  const [currentUser] = useState(
    JSON.parse(localStorage.getItem("userDetails", "undefined"))
  );
  const [key, setKey] = useState("player-sub");
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const tab = getQueryVariable("tab");
  const action = getQueryVariable("action");
  let titolo = "Player";
  let mPlayer = <PlayerSub currentUser={currentUser} />;
  if (tab === "player-sub" && action === "aggiunta") {
    mPlayer = <AssociaPlayer currentUser={currentUser} />;
  }

  return (
    <>
      <CustomHeader title="Player">
        <CircleButtonComponent
          text="Guida"
          icon={<Help />}
          onClick={() => setIsModalOpen(true)}
        />
      </CustomHeader>
      {isModalOpen && (
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="favoritesModalLabel">
                Guida Euracom Player
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                style={{ fontSize: "0.8rem" }}
                aria-label="Close"
                onClick={() => setIsModalOpen(false)}
              ></button>
            </div>
            <div className="modal-body" id="summaryModalBody">
              <iframe
                title="Guida applicazione web"
                src={"GuidaEuracomPlayer.pdf"}
                width="96%"
                height={window.innerHeight * 0.7}
                style={{ display: "block", margin: "auto" }}
              ></iframe>
            </div>
          </div>
        </div>
      )}

      <Tabs
        id="controlled-player"
        activeKey={key}
        onSelect={(k) => {
          history.push("/player?tab=" + k);
          setKey(k);
        }}
        className="mb-3 h4 custom-tab-1"
      >
        <Tab eventKey="player-sub" title="Player">
          {mPlayer}
        </Tab>
        <Tab eventKey="player-licenze" title="Licenze">
          <PlayerLicenze currentUser={currentUser} />
        </Tab>
      </Tabs>
    </>
  );
}

export default Player;
