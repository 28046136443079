import React, { useState } from 'react';
import { Button} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import axios from 'axios';
import {itemBrand} from "./brands";
function ModificaBrand() {

  const [file, setFile] = useState(''); // storing the uploaded file
  //const [progress, setProgess] = useState(0); // progess bar  
  const history = useHistory();
  setTimeout(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, 100);
  const handleChange = (e) => {
    //setProgess(0)
    const file = e.target.files[0]; // accessing file
    console.log(file);
    setFile(file); // storing file
    var imgLogo = document.getElementById('logoImg');
    imgLogo.src =  URL.createObjectURL(file);
    itemBrand.logoBrand = file.name;
  }
  const uploadFile = () => {
    const formData = new FormData();
    formData.append('file', file); // appending file
    axios.post(process.env.REACT_APP_BASE_URL + 'upload-file', formData, {
        onUploadProgress: (ProgressEvent) => {
            //let progressValue = Math.round(
            //ProgressEvent.loaded / ProgressEvent.total * 100) + '%';
            //setProgess(progressValue);
        }
    }).then(res => {
        if (res.status === 200)
        {
          history.push('/impostazioni');
        }
        else
        {
          console.log(res);
        }
        
    }).catch(err => console.log(err))
  }
  const handleSubmit = event => {
    event.preventDefault();
    const form = event.target;
    const file = event.target.brandlogo.files[0];
    const data = {};
    for (let i=0; i < form.elements.length; i++) {
      const elem = form.elements[i];
      data[elem.name] = elem.value;
    }
    if (event.target.brandlogo.files.length > 0)
    {
        data['logo'] = file.name; 
    }
    else
    {
      data['logo'] = itemBrand.logo;
    }
    axios.post(process.env.REACT_APP_BASE_URL + 'modifica-brand', data )
    .then(function(response) {
      if (response.status === 200) {
        if (event.target.brandlogo.files.length > 0){
            uploadFile();
        }
        else
        {
            history.push('impostazioni');
        }
      }
      else {throw new Error(response.status);}
      }).catch(function(error)
      {
    });
  }
 
  return (
    <div>
      <div className="">
        <div className="card">
          <div className="card-header">
            <p className="subtab">Modifica brand</p>
          </div>
          <div className="card-body">
            <form id="formBrand" controlId="modificaBrand" onSubmit={handleSubmit} encType='multipart/form-data'>
              <input class="form-input" type="hidden" name="brandid" value={itemBrand.id} onChange={handleChange}/>
                <div className="form-row">
                <label  class="form-label">NOME</label>
                <input class="form-input"  type="text" name="brandnome" defaultValue = {itemBrand.nome} />
                </div>
                <div className="form-row">
                <label class="form-label">LOGO</label>
                <input class="form-input" type="file" name="brandlogo" onChange={handleChange}/>
                </div>
                <div className="form-row">
                  <div className="form-image">
                  <img id="logoImg" className="brand-image" src= {process.env.REACT_APP_BASE_URL_CONTENTS + itemBrand.logo} alt ="" />
                </div>
                <div className="form-buttons">
                  <Button className="btn btn-outline-primary rounded-1 btn-sm" type="submit">Invia Modifica</Button>
                  <Button className="btn btn-outline-primary rounded-1 btn-sm" onClick={() => {history.push('impostazioni');}} type="button">Annulla </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ModificaBrand;
