import { Chip } from "material-ui";
import { MDBDataTableV5 } from "mdbreact";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import SectionSubtitle from "../components/Subtitles";
import CircleButtonComponent from "../components/Button/CircleButton";
import { AccountTree, Add, Delete, Edit } from "@mui/icons-material";
export let itemCat = [];

function Categorie() {
  const [items, setItems] = useState([]);
  const [usersForRender, setUsersForRender] = useState([]);

  const fetchcategorie = () => {
    fetch(process.env.REACT_APP_BASE_URL + "categorie")
      .then((res) => res.json())
      .then((res) => {
        if (res.length === 0) {
          setItems([{ id: -1, nome: "Nessun tag presente!" }]);
          return;
        }
        setItems(res);
      });
  };
  useEffect(() => {
    fetchcategorie();
  }, []);
  setTimeout(() => {
    var buttons = document.getElementsByClassName("page-link");
    if (buttons.length > 0) {
      for (var i = 0; i < buttons.length; i++) {
        buttons[i].onclick = function () {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        };
      }
    }
  }, 1000);

  const deleteCat = (item) => {
    const dataDel = {};
    dataDel["id"] = item.id;
    dataDel["immagine"] = item.immagine;
    fetch(process.env.REACT_APP_BASE_URL + "elimina-categoria/", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataDel),
    })
      .then(function (response) {
        if (response.status === 200) {
          Swal.fire({
            title: "",
            text: "Operazione eseguita correttamente",
            icon: "success",
          }).then(function () {
            // window.location.href ='/impostazioni?tab=categorie'
            fetchcategorie();
          });
        }
      })
      .catch(function (error) {
        Swal.fire({
          title: "",
          text: "Impossibile eliminare la categoria selezionata",
          icon: "error",
        });
      });
  };
  /* eslint-disable */
  useEffect(() => {
    let itemsArray = items;
    let userData = [];
    itemsArray.map((item, index) => {
      if (item.id > 0) {
        //item.dataora = ( DateFormat(new Date(item.ultimo_aggiornamento)) );
        item.img = (
          <img
            style={{ borderRadius: "100%" }}
            className="tabellaimg"
            src={process.env.REACT_APP_BASE_URL_CONTENTS + item.immagine}
            alt=""
          />
        );
        item.tagcolor = (
          <span
            style={{ backgroundColor: item.colore }}
            className="c-color rounded-circle"
          ></span>
        );
        item.action = (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <CircleButtonComponent>
              <Link
                onClick={() => {
                  itemCat = item;
                }}
                to={() => {
                  return "impostazioni?tab=categorie&action=associazione-brands";
                }}
              >
                <AccountTree sx={{ color: "white" }} />
              </Link>
            </CircleButtonComponent>
            <CircleButtonComponent>
              <Link
                className="c-pointer button-action"
                onClick={() => {
                  itemCat = item;
                }}
                to={() => {
                  return "impostazioni?tab=categorie&action=modifica";
                }}
              >
                <Edit sx={{ color: "white" }} />
              </Link>
            </CircleButtonComponent>
            <CircleButtonComponent
              icon={<Delete />}
              onClick={() => {
                if (item.brands.length > 0 && item.brands[0]) {
                  Swal.fire({
                    title: "Impossibile eliminare!",
                    html:
                      "Attenzione, non è possibile eliminare la categoria <b>" +
                      item.nome +
                      "</b> se ha brands associati.",
                    imageUrl:
                      process.env.REACT_APP_BASE_URL_CONTENTS + item.immagine,
                    imageHeight: 100,
                    showCancelButton: false,
                    confirmButtonText: "OK",
                    reverseButtons: true,
                    icon: "warning",
                  });
                  return;
                }
                Swal.fire({
                  title: "Confermi?",
                  html:
                    "Attenzione, la categoria <b>" +
                    item.nome +
                    "</b> sarà eliminata",
                  imageUrl:
                    process.env.REACT_APP_BASE_URL_CONTENTS + item.immagine,
                  imageHeight: 100,
                  showCancelButton: true,
                  confirmButtonText: "Elimina Categoria",
                  cancelButtonText: "Annulla",
                  reverseButtons: true,
                  icon: "question",
                }).then((result) => {
                  if (result.isConfirmed) {
                    deleteCat(item);
                  } else if (
                    /* Read more about handling dismissals below */
                    result.dismiss === Swal.DismissReason.cancel
                  ) {
                    Swal.fire("Operazione annullata");
                  }
                });
              }}
            />
          </div>
        );

        const brandsCopy = JSON.parse(JSON.stringify(item.brandlist));
        item.brandIncludes = (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "8px",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            {brandsCopy
              .filter((b) => b !== null)
              .map((b) => (
                <p
                  style={{
                    border: "1px solid #000",
                    borderRadius: "4em",
                    padding: "4px 12px",
                    display: "inline",
                    margin: 0,
                    whiteSpace: "nowrap",
                  }}
                >
                  {b}
                </p>
              ))}
          </div>
        );
      }
      userData.push(item);
    });
    setUsersForRender(userData);
  }, [items]);

  const data = {
    columns: [
      {
        label: "",
        field: "",
        sort: "disabled",
        width: 10,
      },
      {
        label: "Nome",
        field: "nome",
        sort: "asc",
        width: 10,
      },
      {
        label: "Immagine",
        field: "img",
        width: 70,
        sort: "disabled",
      },
      {
        label: "Colore",
        field: "tagcolor",
        sort: "disabled",
        width: 100,
      },
      {
        label: "Brand associati",
        field: "brandIncludes",
        sort: "disabled",
        width: 100,
      },
      {
        label: "Azione",
        field: "action",
        sort: "disabled",
        width: 150,
      },
    ],
    rows: usersForRender,
  };
  return (
    <div className="tabella_categorie">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "32px",
        }}
      >
        <SectionSubtitle text="Aggiungi, elimina o modifica le categorie per organizzare al meglio i tuoi contenuti." />
        <CircleButtonComponent
          icon={<Add />}
          onClick={() =>
            (window.location.href =
              "impostazioni?tab=categorie&action=aggiunta")
          }
        />
      </div>
      <MDBDataTableV5
        data={data}
        proSelect
        searchTop
        searchBottom={false}
        fullPagination
        autoWidth={false}
        searchLabel={"Cerca"}
        responsive
        disableRetreatAfterSorting={true}
      />{" "}
    </div>
  );
}
export { Categorie as default };
