import {
  login,
  loginapp,
  runLogoutTimer,
  saveTokenInLocalStorage,
  signUp,
} from "../../services/AuthService";
import Swal from "sweetalert2";

export const SIGNUP_CONFIRMED_ACTION = "[signup action] confirmed signup";
export const SIGNUP_FAILED_ACTION = "[signup action] failed signup";
export const LOGIN_CONFIRMED_ACTION = "[login action] confirmed login";
export const LOGIN_FAILED_ACTION = "[login action] failed login";
export const LOADING_TOGGLE_ACTION = "[Loading action] toggle loading";
export const LOGOUT_ACTION = "[Logout action] logout action";
export const PLAYER_ACTION = "[Player action] player action";

export function signupAction(email, password, history) {
  return (dispatch) => {
    signUp(email, password)
      .then((response) => {
        Swal.fire({
          title: "Verifica richiesta.",
          html: "<p style=\"color:red;\"><b>Registrazione avvenuta con successo.</b></p><p>Attendere l'attivazione dell'account.</p>",
          icon: "success",
        });
        history.push("/login");
      })
      .catch((error) => {
        Swal.fire({
          title: "Attenzione",
          html: '<p style="color:red;"><b>Email gi&agrave; registrata!</b></p><p>In caso di password dimenticata, usare la funzione di reset della password!</p>',
          icon: "warning",
        });
      });
  };
}

export function logout(history) {
  localStorage.removeItem("userDetails");
  if (history.location.search.includes("token")) {
    return {
      type: LOGOUT_ACTION,
    };
  }
  if (
    history.location.pathname === "/get-schedule" ||
    history.location.pathname === "/register-player"
  ) {
    return {
      type: PLAYER_ACTION,
    };
  } else {
    history.push("/login");
    return {
      type: LOGOUT_ACTION,
    };
  }
}

export function loginAction(email, password, timeExp, history) {
  return (dispatch) => {
    login(email, password, timeExp)
      .then((response) => {
        console.log({ response });
        saveTokenInLocalStorage(response.data);
        runLogoutTimer(
          dispatch,
          (response.data.expiresIn + timeExp) * 1000,
          history
        );
        dispatch(loginConfirmedAction(response.data));
        let redirect = localStorage.getItem("redirect-to");

        if (redirect) {
          history.push(redirect);
        } else {
          if (response.data.tipo < 3) {
            history.push("/dashboard");
          } else {
            history.push("/contenuti?tab=cartelle");
          }
        }
      })
      .catch((error) => {
        console.error("Login error:", error);
        Swal.fire({
          title: "Attenzione",
          html: '<p style="color:red;"><b>Autenticazione fallita!</b></p><p>Verificare email e password e riprovare!</p>',
          icon: "warning",
        });
        //const errorMessage = formatError("Autenticazione Fallita");
        dispatch(loginFailedAction("Autenticazione Fallita"));
      });
  };
}

export function loginFailedAction(data) {
  return {
    type: LOGIN_FAILED_ACTION,
    payload: data,
  };
}

export function loginConfirmedAction(data) {
  return {
    type: LOGIN_CONFIRMED_ACTION,
    payload: data,
  };
}

export function confirmedSignupAction(payload) {
  return {
    type: SIGNUP_CONFIRMED_ACTION,
    payload,
  };
}

export function signupFailedAction(message) {
  return {
    type: SIGNUP_FAILED_ACTION,
    payload: message,
  };
}

export function loadingToggleAction(status) {
  return {
    type: LOADING_TOGGLE_ACTION,
    payload: status,
  };
}
