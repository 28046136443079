
import React, { useState, Fragment } from "react";
import { Button} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Swal from 'sweetalert2'
import {itemTipologie} from "./tipologie-contenuti";
export function ModificaTipologiaContenuti() {
  const [colorChange, setColorChange] = useState(null);
  const history = useHistory();
  const handleSubmit = event => {
    event.preventDefault();
    const elements = event.target.elements;
    const data = {};
    for (let i=0; i < elements.length; i++) {
        const elem = elements[i];
        data[elem.name] = elem.value
    }
   
    fetch(process.env.REACT_APP_BASE_URL + 'modifica-tipo-contenuto', { method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(data)
    }).then(function(response) {
      if (response.status === 200) {
          //let responseText = JSON.stringify(response.text());
          Swal.fire("", "Operazione eseguita correttamente", "success")
          history.push('contenuti')
      }
      else {throw new Error(response.status);}
      }).catch(function(error)
      {
        Swal.fire({ title:"", text:"Impossibile salvare",type:"error"})
      });
  }
  
  return(
   <>
<div className="card">
  
    <p className="pform">Modifica tipologia contenuti</p>
   
  <div className="card-bodyx">
      
      <form onSubmit={handleSubmit}>
        <input type="hidden" name="id" value={itemTipologie.idTipologia} />
        <div className="form-row">
              <label className="form-label">NOME</label>
              <input className="form-input" type="text" name="nome" defaultValue={itemTipologie.nomeTipologia} required={true} />
        </div>
        <div className="form-row">
          <label className="form-label">COLORE</label>
              <input type="color" name="colore" 
                      className="as_colorpicker form-control form-input-color"
                      defaultValue={itemTipologie.coloreTipologia} 
                      value={colorChange}
                      onChange={(e) => setColorChange(e.target.value)}
                    />
            <div className="form-buttons-right">
                <Button className="btn btn-outline-primary rounded-1 btn-sm" type="submit">Invia</Button>
                <Button className="btn btn-outline-primary rounded-1 btn-sm" type="button" onClick={() => {history.push('contenuti');}}>Annulla </Button>
            </div>
            </div>
          </form>
    </div>
</div>
     </>
    
    
    )};


export default ModificaTipologiaContenuti