import React, { useState } from "react";
import CircleButtonComponent from "../components/Button/CircleButton";
import { Help } from "@mui/icons-material";
import CustomHeader from "../components/Header/CustomHeader";

function ElementiModal() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <>
      <CustomHeader title="Contenuti">
        <CircleButtonComponent
          text="Guida"
          icon={<Help />}
          onClick={() => setIsModalOpen(true)}
        />
      </CustomHeader>

      {isModalOpen && (
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="favoritesModalLabel">
                Guida Euracom Contenuti
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                style={{ fontSize: "0.8rem" }}
                aria-label="Close"
                onClick={() => setIsModalOpen(false)}
              ></button>
            </div>
            <div className="modal-body" id="summaryModalBody">
              <iframe
                title="Guida applicazione web"
                src={"GuidaEuracomContenuti.pdf"}
                width="96%"
                height={window.innerHeight * 0.7}
                style={{ display: "block", margin: "auto" }}
              ></iframe>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ElementiModal;
